import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { FileDownload } from "../../assets/svg/AllSvg";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import { Badge } from "../../components/micro";
import CustomTable from "../../commons/CustomTable";
import ReactDatePicker from "react-datepicker";
import CreateSalesButton from "../../commons/CreateSalesButton";
import Remarks from "./Remarks";
import Action from "../../commons/Action";
import { useNavigate } from "react-router-dom";
import ConfirmationModel from "../../commons/ConfirmationModel";

const Sales = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [selectedTypes, setSelectedTypes] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmRefund, setConfirmRefund] = useState(false);
  const [showRemarks, setShowRemarks] = useState(false);
  const [salesData, setSalesData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const typesDropdown = [{ label: "hello", value: "hello" }];
  const statusDropdown = [{ label: "how", value: "how" }];

  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // Table Data
  const salesList = {
    data: [
      {
        id: "S0001",
        company: "Name 1",
        request: "Corporate Secretary",
        price: 1000,
        date: "12/12/2023 14:00 pm",
        status: "pending",
      },
      {
        id: "S0002",
        company: "Name 2",
        request: "Allotment of Shares",
        price: 1000,
        date: "12/12/2023 14:00 pm",
        status: "paid",
      },
      {
        id: "S0003",
        company: "Name 3",
        request: "Allotment of Shares",
        price: 1000,
        date: "12/12/2023 14:00 pm",
        status: "refunded",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 3,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Company
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Request
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Price
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Date
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Remarks
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(salesList?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">
              {(salesList?.current_page - 1) * salesList?.per_page + index + 1}
            </td>
            <td className="px-5 py-3 truncate">{element?.company}</td>
            <td className="px-5 py-3 truncate">{element?.request}</td>
            <td className="px-5 py-3 truncate">${element?.price}</td>
            <td className="px-5 py-3 truncate">{element?.date}</td>
            <td className="px-5 py-3 truncate">
              <button
                className="text-theme border-b border-theme"
                onClick={() => {
                  setShowRemarks(true);
                  setSalesData(element);
                }}
              >
                View
              </button>
            </td>
            <td className="px-5 py-3 truncate">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`capitalize ${
                      element?.status === "paid"
                        ? "text-white border-success bg-success"
                        : element?.status === "pending"
                        ? " border-secondary_light_shades bg-secondary_light_shades"
                        : element?.status === "refunded"
                        ? "border-red-600 bg-red-600 text-white"
                        : ""
                    }`}
                    value={element?.status}
                  />
                }
              </div>
            </td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/sales_view", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setSalesData(element);
                        setConfirmRefund(true);
                      }}
                    >
                      Refund
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setSalesData(element);
                        setConfirmDelete(true);
                      }}
                    >
                      Delete
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div className="space-y-5">
      <div className="flex justify-between items-center">
        <div>
          <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
            Sales
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Sales"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <CreateSalesButton />
      </div>
      <div className="flex justify-end">
        <button className="btn-outline flex items-center uppercase">
          <FileDownload width="14" height="17" className="mr-3" />
          Export CSV
        </button>
      </div>
      <div className="flex lg:flex-row flex-col  justify-between gap-2">
        <div className="flex sm:flex-row flex-col lg:items-center gap-2 ">
          <DebounceSearch
            search={searchValue}
            handleChange={setSearchValue}
            placeholder={"Search..."}
          />
          <ReactSelect
            className={"w-full sm:max-w-xs text-black responsive_base_text p-0"}
            id="typesDropdown"
            placeholder="Select Types"
            options={typesDropdown}
            isLoading={false}
            getOptionValue={(selectedTypes) => selectedTypes.value}
            value={selectedTypes}
            onChange={(e) => {
              setSelectedTypes(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                marginLeft: "0px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
          <ReactSelect
            className={"w-full sm:max-w-xs text-black responsive_base_text p-0"}
            id="status"
            placeholder="Select Status"
            options={statusDropdown}
            isLoading={false}
            getOptionValue={(selectedStatus) => selectedStatus.value}
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e);
            }}
            styles={{
              control: (base) => ({
                ...base,
                border: `1px solid #cdddeb`,
                borderRadius: "0.3rem",
                paddingTop: "2px",
                paddingBottom: "2px",
                boxShadow: "none",
                "&:hover": {
                  border: "1px solid #cdddeb",
                },
              }),
            }}
          />
        </div>
        <div className="w-full sm:max-w-xs text-black ">
          <ReactDatePicker
            className={`border border-1 rounded-lg w-full px-4 py-2`}
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            placeholderText="Select Date Range"
          />
        </div>
      </div>
      <div className="w-full bg-white border rounded">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={salesList}
          overflowVisibleLg={true}
          // loader
          loading={false}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={salesList?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
      {/* Confirm Refund */}
      {confirmRefund && (
        <div
          className="model"
          onClick={() => {
            setConfirmRefund(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-2xl font-ebGaramond mb-4">Confirm Refund ?</h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setConfirmRefund(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  setConfirmRefund(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Confirm Delete */}
      {confirmDelete && (
        <ConfirmationModel
          confirmText={"Delete"}
          isLoading={false}
          onClose={() => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            setConfirmDelete(false);
          }}
        />
      )}
      {showRemarks && (
        <Remarks setShowRemarks={setShowRemarks} salesData={salesData} />
      )}
    </div>
  );
};

export default Sales;
