import React from "react";
import { Logo, authBgImage } from "../../assets/image";
import { useNavigate } from "react-router-dom";

const PasswordSuccessful = () => {
    const navigate = useNavigate();
  return (
    <>
      <div className={`h-screen overflow-hidden relative`}>
        {/* {logingDataLoading && <RippleLoader />} */}
        <div
          className={`flex items-center justify-center h-full  bg-no-repeat bg-cover bg-center`}
          style={{
            backgroundImage: `url(${authBgImage})`,
          }}
        >
          <div className="absolute top-10 left-10 w-20 h-20">
            <img src={Logo} alt="logo" className="w-full h-full object-cover" />
          </div>
          <section className="w-full">
            <div className="max-w-xl w-full mx-auto">
              <div className="rounded-lg drop-shadow-lg bg-white/80 px-6 py-8 mx-4">
                <div className={"mb-8"}>
                  <h1 className="sm:text-2xl text-lg font-ebGaramond font-semibold mb-1">
                    Password Successful Updated!
                  </h1>
                  <p className="text-xs opacity-65">Login now</p>
                </div>
                <button
                  type="submit"
                  className="w-full block uppercase text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4"
                  onClick={()=>{
                    navigate("/login")
                  }}
                >
                  Go to login
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PasswordSuccessful;
