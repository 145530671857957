import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  InputWithLabel,
  TextareaWithLabel,
} from "../../../../components/micro";
import { updateMasterSetting } from "../../../../redux/masterSetting/slice";
import ConfirmationModel from "../../../../commons/ConfirmationModel";

const CorporateSecretary = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: {
        tiered_pricing: {
          gov_annual_filling: value?.gov_annual_filling,
          shareholder_1: value?.shareholder_1,
          shareholders_2: value?.shareholders_2,
          shareholders_3_5: value?.shareholders_3_5,
          shareholders_6_9: value?.shareholders_6_9,
          shareholders_10_20: value?.shareholders_10_20,
          shareholders_21_30: value?.shareholders_21_30,
          greater_than_30_shareholders: value?.greater_than_30_shareholders,
        },
        tiered_discount: {
          years_3: value.years_3,
          years_5: value.years_5,
        },
      },
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/general-settings");
      }
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          gov_annual_filling:
            masterSettingData?.detail?.tiered_pricing?.gov_annual_filling ?? "",
          shareholder_1:
            masterSettingData?.detail?.tiered_pricing?.shareholder_1 ?? "",
          shareholders_2:
            masterSettingData?.detail?.tiered_pricing?.shareholders_2 ?? "",
          shareholders_3_5:
            masterSettingData?.detail?.tiered_pricing?.shareholders_3_5 ?? "",
          shareholders_6_9:
            masterSettingData?.detail?.tiered_pricing?.shareholders_6_9 ?? "",
          shareholders_10_20:
            masterSettingData?.detail?.tiered_pricing?.shareholders_10_20 ?? "",
          shareholders_21_30:
            masterSettingData?.detail?.tiered_pricing?.shareholders_21_30 ?? "",
          greater_than_30_shareholders:
            masterSettingData?.detail?.tiered_pricing
              ?.greater_than_30_shareholders ?? "",
          years_3: masterSettingData?.detail?.tiered_discount?.years_3 ?? "",
          years_5: masterSettingData?.detail?.tiered_discount?.years_5 ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          gov_annual_filling: Yup.string().required(
            "Please Enter GST Annual Filing Pricing"
          ),
          shareholder_1: Yup.string().required(
            "Please Enter 1 shareholder Pricing"
          ),
          shareholders_2: Yup.string().required(
            "Please Enter 2 Shareholders Pricing"
          ),
          shareholders_3_5: Yup.string().required(
            "Please Enter 3 - 5 Shareholders Pricing"
          ),
          shareholders_6_9: Yup.string().required(
            "Please Enter 6 - 9 Shareholders Pricing"
          ),
          shareholders_10_20: Yup.string().required(
            "Please Enter 10 - 20 Shareholders Pricing"
          ),
          shareholders_21_30: Yup.string().required(
            "Please Enter 21 - 30 Shareholders Pricing"
          ),
          greater_than_30_shareholders: Yup.string().required(
            "Please Enter > 30 Shareholders Pricing"
          ),
          years_3: Yup.number()
            .required("Please Enter 3 years Discount (%)")
            .max(100, "Discount must be less than or equal to 100%")
            .min(0, "Discount must be greater than or equal to 100%"),
          years_5: Yup.number()
            .required("Please Enter 5 years Discount (%)")
            .max(100, "Discount must be less than or equal to 100%")
            .min(0, "Discount must be greater than or equal to 100%"),
          remarks: Yup.string().required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="space-y-5">
            <p
              className={`font-ebGaramond text-2xl mb-5 ${
                !isEdit && "text-light_grey"
              }`}
            >
              Fixed Cost
            </p>
            {/* Gov Annual Filling */}
            <div className="grid grid-cols-4 items-center gap-5 border-b border-secondary_grey pb-5">
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="gov_annual_filling"
              >
                Gov Annual Filing
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"gov_annual_filling"}
                  className={
                    errors.gov_annual_filling && touched.gov_annual_filling
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gov_annual_filling}
                  errors={
                    errors.gov_annual_filling && touched.gov_annual_filling
                  }
                  errorsText={errors.gov_annual_filling}
                />
              </div>
            </div>
            <p
              className={`font-ebGaramond text-2xl mb-5 ${
                !isEdit && "text-light_grey"
              }`}
            >
              Tiered pricing
            </p>
            <div className="grid grid-cols-4 items-center gap-5 border-b border-secondary_grey pb-5">
              {/* 1 Shareholder */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholder_1"
              >
                1 Shareholder
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholder_1"}
                  className={
                    errors.shareholder_1 && touched.shareholder_1
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholder_1}
                  errors={errors.shareholder_1 && touched.shareholder_1}
                  errorsText={errors.shareholder_1}
                />
              </div>
              {/* 2 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholders_2"
              >
                2 Shareholders
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholders_2"}
                  className={
                    errors.shareholders_2 && touched.shareholders_2
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholders_2}
                  errors={errors.shareholders_2 && touched.shareholders_2}
                  errorsText={errors.shareholders_2}
                />
              </div>
              {/* 3 - 5 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholders_3_5"
              >
                3 - 5 Shareholders
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholders_3_5"}
                  className={
                    errors.shareholders_3_5 && touched.shareholders_3_5
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholders_3_5}
                  errors={errors.shareholders_3_5 && touched.shareholders_3_5}
                  errorsText={errors.shareholders_3_5}
                />
              </div>
              {/* 6 - 9 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholders_6_9"
              >
                6 - 9 Shareholders
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholders_6_9"}
                  className={
                    errors.shareholders_6_9 && touched.shareholders_6_9
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholders_6_9}
                  errors={errors.shareholders_6_9 && touched.shareholders_6_9}
                  errorsText={errors.shareholders_6_9}
                />
              </div>
              {/* 10 - 20 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholders_10_20"
              >
                10 - 20 Shareholders
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholders_10_20"}
                  className={
                    errors.shareholders_10_20 && touched.shareholders_10_20
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholders_10_20}
                  errors={
                    errors.shareholders_10_20 && touched.shareholders_10_20
                  }
                  errorsText={errors.shareholders_10_20}
                />
              </div>
              {/* 21 - 30 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="shareholders_21_30"
              >
                21 - 30 Shareholders
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"shareholders_21_30"}
                  className={
                    errors.shareholders_21_30 && touched.shareholders_21_30
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shareholders_21_30}
                  errors={
                    errors.shareholders_21_30 && touched.shareholders_21_30
                  }
                  errorsText={errors.shareholders_21_30}
                />
              </div>
              {/* > 30 Shareholders */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="greater_than_30_shareholders"
              >
                {`> 30 Shareholders`}
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"greater_than_30_shareholders"}
                  className={
                    errors.greater_than_30_shareholders &&
                    touched.greater_than_30_shareholders
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.greater_than_30_shareholders}
                  errors={
                    errors.greater_than_30_shareholders &&
                    touched.greater_than_30_shareholders
                  }
                  errorsText={errors.greater_than_30_shareholders}
                />
              </div>
            </div>
            {/* Tiered discount */}
            <p
              className={`font-ebGaramond text-2xl mb-5 ${
                !isEdit && "text-light_grey"
              }`}
            >
              Tiered discount
            </p>
            <div className="grid grid-cols-4 items-center gap-5 border-b border-secondary_grey pb-5">
              {/* 3 Years */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="years_3"
              >
                3 Years
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Discount (%)"}
                  type={"number"}
                  idFromName={"years_3"}
                  className={
                    errors.years_3 && touched.years_3
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.years_3}
                  errors={errors.years_3 && touched.years_3}
                  errorsText={errors.years_3}
                />
              </div>
              {/* 5 Years */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="years_5"
              >
                5 Years
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Discount (%)"}
                  type={"number"}
                  idFromName={"years_5"}
                  className={
                    errors.years_5 && touched.years_5
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-light_grey" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.years_5}
                  errors={errors.years_5 && touched.years_5}
                  errorsText={errors.years_5}
                />
              </div>
            </div>
            {/* Remarks */}
            <div>
              <TextareaWithLabel
                className={
                  errors.remarks && touched.remarks
                    ? ` input-error `
                    : ` border `
                }
                idFromName="remarks"
                label="Remarks"
                disabled={!isEdit}
                inputClass={!isEdit ? "text-light_grey" : ""}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.remarks}
                errors={errors.remarks && touched.remarks}
                errorsText={errors.remarks}
              />
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn-outline uppercase"
                onClick={() => {
                  navigate("/general-settings");
                }}
              >
                Back
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default CorporateSecretary;
