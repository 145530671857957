import React, { useEffect, useState } from "react";
import BackButton from "../../../commons/BackButton";
import { Folder } from "../../../assets/svg/AllSvg";
import PdfDocument from "../../../commons/PdfDocument";
import FileUploadChoose from "../../../commons/FileUploadChoose";
import { useDispatch, useSelector } from "react-redux";
import {
  createDocument,
  getFileDetail,
} from "../../../redux/folder&documents/slice";
import moment from "moment";
import { toast } from "react-toastify";
import RippleLoader from "../../../commons/RippleLoader";

const FolderDocuments = ({
  folderDetails,
  setShowPDFDocuments,
  setFolderDetails,
  companyByIdData,
}) => {
  const [loading, setLoading] = useState(true);
  const [uploadLoading, setUploadLoading] = useState(false);
  const dispatch = useDispatch();

  // Selector
  const { fileDetail } = useSelector((store) => ({
    // files
    fileDetail: store?.fileData?.getFileDetailStatus?.data,
  }));

  const Id = folderDetails?.id;

  // get files
  const fetchDocuments = async () => {
    if (Id) {
      setLoading(true);
      try {
        await dispatch(getFileDetail({ id: Id })).unwrap();
      } catch (error) {
        console.error("Error fetching documents:", error);
        toast.error("Failed to refresh documents.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [dispatch, Id]);

  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

  const handleFileChange = async (e) => {
    const fileData = e.target.files[0];

    if (!fileData) return;

    const formData = new FormData();
    formData.append("folder_id", Id);

    const chunks = [];
    const totalChunks = Math.ceil(fileData.size / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileData.size);
      const fileChunk = fileData.slice(start, end);
      chunks.push(fileChunk);
    }
    chunks.forEach((chunk, index) => {
      formData.append("documents[]", chunk, fileData.name);
    });
    try {
      setUploadLoading(true);
      await dispatch(createDocument(formData));
      toast.success("Document uploaded successfully!");

      await dispatch(getFileDetail({ id: Id }));
    } catch (error) {
      toast.error("Error uploading document.");
    } finally {
      setUploadLoading(false);
    }
  };

  return (
    <>
      {loading || uploadLoading ? (
        <div className="flex justify-center items-center h-full">
          <RippleLoader />
        </div>
      ) : (
        <>
          <div className="bg-light_secondary rounded-2xl px-6 py-4 mb-5">
            <BackButton
              className="cursor-pointer mb-5"
              onClick={() => {
                setFolderDetails({});
                setShowPDFDocuments(false);
              }}
              icon={<Folder width="20" height="20" />}
              text={folderDetails?.name}
            />
            <div className="grid grid-cols-4 gap-4">
              {fileDetail?.data?.map((element, index) => {
                return (
                  <PdfDocument
                    documentName={element?.document}
                    documentId={element?.id}
                    companyByIdData={companyByIdData}
                    fetchDocuments={fetchDocuments}
                    uploadTime={moment(element?.updated_at).format(
                      "DD/MM/YYYY hh:mm A"
                    )}
                    key={index}
                    cardClasses="bg-white"
                  />
                );
              })}
            </div>
          </div>
          <FileUploadChoose handleFileChange={handleFileChange} />
        </>
      )}
    </>
  );
};

export default FolderDocuments;
