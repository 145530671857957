import React from "react";
import { Badge } from "../../../../components/micro";
import DirectorField from "./DirectorField";

const AddEditDirectors = ({
  setShowDirectorsPopup,
  getDirectorData,
  companyByIdData,
  currentUserId,
  setLoading,
  directorsData,
  editType,
  companyId,
}) => {
  const selectedDirector = directorsData?.find(
    (data) => data.id === currentUserId
  );

  const selectedShareholder = companyByIdData?.shareholders?.find(
    (data) => data.id === currentUserId
  );

  const selectedCorporate = companyByIdData?.corporate_secretaries?.find(
    (data) => data.id === currentUserId
  );

  return (
    <>
      <div
        className="model"
        onClick={(e) => {
          setShowDirectorsPopup(false);
        }}
      >
        <div
          className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
            {editType === "IndividualShareholders" ||
            editType === "CorporateShareholders"
              ? "Shareholder"
              : "Director"}
          </h2>
          <div className="flex items-center justify-start w-full gap-3 mb-3">
            <p className="text-xl font-ebGaramond font-semibold">
              {companyByIdData?.name}
            </p>
            <Badge
              className={`${
                companyByIdData?.status === "active"
                  ? "text-white border-success bg-success"
                  : "text-black bg-secondary_light_shades"
              } font-medium text-sm capitalize !py-1`}
              value={
                companyByIdData?.status === "active"
                  ? "Live"
                  : companyByIdData?.status
              }
            />
          </div>
          {/* field */}
          <div className="w-full">
            <DirectorField
              element={selectedDirector}
              getDirectorData={getDirectorData}
              companyByIdData={companyByIdData}
              setShowDirectorsPopup={setShowDirectorsPopup}
              currentUserId={currentUserId}
              setLoading={setLoading}
              selectedShareholder={selectedShareholder}
              selectedCorporate={selectedCorporate}
              editType={editType}
              companyId={companyId}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditDirectors;
