import React, { useState } from "react";
import { DownArrow, UpArrow } from "../assets/svg/AllSvg";
import { CheckBox } from "../components/micro";

const DropdownCheckbox = ({
  label,
  children,
  buttonClass,
  form,
  isOpen,
  indexOnChange,
  inputChecked,
  inputNameId,
}) => {
  const [open, setOpen] = useState(isOpen ?? false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="relative">
        <div className="flex justify-between items-center">
          <button
            type="button"
            onClick={toggleDropdown}
            className={buttonClass}
          >
            <CheckBox
              idFrom={inputNameId}
              label={label}
              name={inputNameId}
              checked={inputChecked}
              onChange={indexOnChange}
              className={"w-full"}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
            />
          </button>
          {open ? <UpArrow /> : <DownArrow />}
        </div>
        {open && <div className="">{children}</div>}
      </div>
    </>
  );
};

export default DropdownCheckbox;
