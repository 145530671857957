import React, { useState } from "react";
import { InputWithLabel } from "../../../../components/micro";
import ReactSelect from "react-select";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../../commons/MicroComponents";
import {
  addDirectorShareholder,
  updateUser,
} from "../../../../redux/organisation/slice";
import { getCompanyById } from "../../../../redux/companies/slice";

const AddEditUser = ({
  setShowUserPopup,
  companyByIdData,
  currentUserId,
  companyId,
}) => {
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();

  const userOptions =
    companyByIdData?.system_users?.map((user) => ({
      value: user?.id,
      label: user?.user?.full_name,
    })) || [];

  const handleSubmit = async (values) => {
    setIsLoad(true);
    try {
      if (currentUserId) {
        const payload = {
          type: values.type.value,
          id: values?.id,
        };
        const res = await dispatch(updateUser(payload));
        if (res.type === "updateUser/fulfilled") {
          dispatch(getCompanyById(companyId));
        }
      } else {
        const payload = {
          company_id: companyId,
          full_name: values.full_name,
          email: values.email,
          type: values.type.value,
        };
        const res = await dispatch(addDirectorShareholder(payload));
        if (res.type === "addDirectorShareholder/fulfilled") {
          dispatch(getCompanyById(companyId));
        }
      }
    } catch (error) {
      console.error("Error during user operation:", error);
    } finally {
      setIsLoad(false);
      setShowUserPopup(false);
    }
  };

  return (
    <>
      <div className="backdrop-blur-sm bg-black/40 flex items-center justify-center w-full fixed top-0 left-0 right-0 z-[99] mx-auto h-screen">
        <div className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative">
          <div>
            <h2>{currentUserId ? "Edit User" : "Add User"}</h2>
            <p className="font-poppins text-sm font-light">
              {currentUserId
                ? "Edit the user and their role here"
                : "Create User and set their role here"}
            </p>
          </div>
          <Formik
            initialValues={{
              company_id: companyId,
              full_name: currentUserId?.user?.full_name || "",
              email: currentUserId?.user?.email || "",
              type: currentUserId
                ? {
                    value: currentUserId?.user?.role || "",
                    label:
                      currentUserId.user.role === "preparer"
                        ? "Preparer"
                        : "Approver",
                  }
                : null,
              id: currentUserId?.id || "",
            }}
            validationSchema={Yup.object({
              full_name: Yup.string().required("Please Enter Full Name"),
              email: Yup.string()
                .email("Invalid email address")
                .required("Please Enter Email"),
              type: Yup.object().required("Please Select User Role").nullable(),
              id: currentUserId
                ? Yup.string().required("Please select a user.")
                : Yup.string().nullable(),
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit} className="mt-4 space-y-5">
                {currentUserId ? (
                  <>
                    <div>
                      <ReactSelect
                        className={"p-0 capitalize"}
                        placeholder="Select User"
                        options={userOptions}
                        onChange={(selectedOption) => {
                          setFieldValue("full_name", selectedOption.label);
                          setFieldValue("id", selectedOption.value);
                        }}
                        value={
                          userOptions.find(
                            (option) => option.value === values.id
                          ) || null
                        }
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.id && touched.id ? "red" : "#cdddeb"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #cdddeb",
                            },
                          }),
                        }}
                      />
                      {errors.id && touched.id ? (
                        <InputError errorTitle={errors.id} />
                      ) : null}
                    </div>
                    <div>
                      <ReactSelect
                        className={"p-0 capitalize"}
                        id="type"
                        placeholder="System Role"
                        options={[
                          { value: "preparer", label: "Preparer" },
                          { value: "approver", label: "Approver" },
                        ]}
                        onChange={(selectedOption) => {
                          setFieldValue("type", selectedOption);
                        }}
                        value={values.type}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.type && touched.type ? "red" : "#cdddeb"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #cdddeb",
                            },
                          }),
                        }}
                      />
                      {errors.type && touched.type ? (
                        <InputError errorTitle={errors.type} />
                      ) : null}
                    </div>
                  </>
                ) : (
                  <>
                    <InputWithLabel
                      className={
                        errors.full_name && touched.full_name
                          ? `input-error`
                          : `border`
                      }
                      idFromName="full_name"
                      label="Full Name"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.full_name}
                      errors={errors.full_name && touched.full_name}
                      errorsText={errors.full_name}
                    />
                    <InputWithLabel
                      className={
                        errors.email && touched.email ? `input-error` : `border`
                      }
                      idFromName="email"
                      label="Email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      errors={errors.email && touched.email}
                      errorsText={errors.email}
                    />
                    <div>
                      <ReactSelect
                        className={"p-0 capitalize"}
                        id="type"
                        placeholder="System Role"
                        options={[
                          { value: "preparer", label: "Preparer" },
                          { value: "approver", label: "Approver" },
                        ]}
                        onChange={(selectedOption) => {
                          setFieldValue("type", selectedOption);
                        }}
                        value={values.type}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.type && touched.type ? "red" : "#cdddeb"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #cdddeb",
                            },
                          }),
                        }}
                      />
                      {errors.type && touched.type ? (
                        <InputError errorTitle={errors.type} />
                      ) : null}
                    </div>
                  </>
                )}

                <div className="mt-6 flex justify-end">
                  <button
                    onClick={() => setShowUserPopup(false)}
                    className="mr-2 px-4 py-2 text-sm font-medium text-gray-800 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-400"
                  >
                    CANCEL
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 text-sm font-medium text-white bg-theme rounded-md hover:bg-theme focus:outline-none focus:ring focus:ring-blue-400"
                    disabled={isLoad}
                  >
                    {isLoad ? (
                      <span>Loading...</span>
                    ) : (
                      <span>{currentUserId ? "EDIT" : "CREATE"}</span>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddEditUser;
