import React from "react";
import AppRoutes from "./routes";
import { BrowserRouter } from "react-router-dom";
// date picker
import "react-datepicker/dist/react-datepicker.css";
// eact-lazy-load-image-component
import "react-lazy-load-image-component/src/effects/blur.css";
// css
import "./assets/css/base.css";
//toas.css
import "react-toastify/dist/ReactToastify.css";
// Phone Number Input
import "react-phone-number-input/style.css";
import usePushNotifications from "./commons/usePushNotifications";

const App = () => {
  usePushNotifications();

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
};

export default App;
