import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMasterSettingById } from "../../../redux/masterSetting/slice";
import Breadcrumb from "../../../commons/Breadcrumb";
import RippleLoader from "../../../commons/RippleLoader";
import CorporateSecretary from "./corporateSecretary/CorporateSecretary";
import BusinessRegisteredAddress from "./businessRegisteredAddress/BusinessRegisteredAddress";

const EditSubscription = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accountingData = location?.state?.element;

  // Selector
  const masterSettingData = useSelector(
    (store) => store?.masterSettingData?.getMasterSettingByIdStatus?.data
  );
  const masterSettingDataLoading = useSelector(
    (store) => store?.masterSettingData?.loading
  );

  useEffect(() => {
    dispatch(getMasterSettingById(accountingData?.id));
  }, [dispatch, accountingData]);

  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
          General Setting
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={accountingData?.service_name}
          breadCrumbParent2={"General Setting"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      {masterSettingDataLoading ? (
        <RippleLoader />
      ) : (
        <div className="w-full bg-white border rounded py-5 px-6">
          <h1 className="font-ebGaramond text-3xl mb-5">
            {accountingData?.service_name}
          </h1>
          {accountingData?.slug === "business_registered_address" ? (
            <BusinessRegisteredAddress masterSettingData={masterSettingData} />
          ) : (
            <CorporateSecretary masterSettingData={masterSettingData} />
          )}
        </div>
      )}
    </div>
  );
};

export default EditSubscription;
