import React, { useEffect, useState } from "react";
import { Badge, InputWithLabel } from "../../../../components/micro";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../../commons/MicroComponents";
import ReactSelect from "react-select";
import {
  businessActivities,
  employmentSupport,
  getCompanyById,
} from "../../../../redux/companies/slice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditCompanyActivities = ({
  setEditCompanyActivitiesPopup,
  companyByIdData,
  companyId,
}) => {
  const [businessActivitiesArray, setBusinessActivitiesArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // Selector
  const {
    businessActivitiesData,
    businessActivitiesLoading,
    Loading,
    CompanyData,
  } = useSelector((store) => ({
    // Loading
    Loading: store?.companiesData?.loading,
    // Business Activities Data
    businessActivitiesData:
      store?.companiesData?.businessActivitiesStatus?.data,
    businessActivitiesLoading:
      store?.companiesData?.businessActivitiesStatus?.loading,
    // Company Data
    CompanyData: store?.companiesData?.companyDataStatus?.data?.data,
  }));

  useEffect(() => {
    //   dispatch(companyData());
    dispatch(businessActivities());
  }, []);

  useEffect(() => {
    if (businessActivitiesData?.data) {
      let data = businessActivitiesData?.data?.map((x) => ({
        ...x,
        label: x.full_activity,
        // value: x.id,
      }));
      setBusinessActivitiesArray(data);
    }
  }, [businessActivitiesData, CompanyData]);

  const initialPrimaryActivity =
    businessActivitiesArray.find((activity) => {
      const trimmedLabel = activity.label;
      const trimmedDescription =
        companyByIdData?.primary_activity?.full_activity;

      return trimmedLabel === trimmedDescription;
    }) || null;

  const initialSecondaryActivity =
    businessActivitiesArray.find((activity) => {
      const trimmedLabel = activity.label;
      const trimmedDescription =
        companyByIdData?.secondary_activity?.full_activity;

      return trimmedLabel === trimmedDescription;
    }) || null;

  return (
    <div
      className="model"
      onClick={(e) => {
        setEditCompanyActivitiesPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Company Activities
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            primary_activity: initialPrimaryActivity,
            primary_business_activity:
              companyByIdData?.primary_business_activity ?? "",
            secondary_activity: initialSecondaryActivity,
            secondary_business_activity:
              companyByIdData?.secondary_business_activity ?? "",
          }}
          validationSchema={Yup.object().shape({
            primary_activity: Yup.object().required(
              "Please Select Primary Activity"
            ),
            primary_business_activity: Yup.string().required(
              "Please Describe your Business Activity"
            ),
          })}
          onSubmit={(values) => {
            setIsLoading(true);
            const payload = {
              primary_activity: values?.primary_activity?.id,
              secondary_activity: values?.secondary_activity?.id,
              primary_business_activity: values?.primary_business_activity,
              secondary_business_activity: values?.secondary_business_activity,
            };
            dispatch(employmentSupport({ id: companyId, data: payload })).then(
              (res) => {
                setIsLoading(true);
                if (res.type === "employmentSupport/fulfilled") {
                  toast.success(res.payload.message);
                  dispatch(getCompanyById(companyId));
                  setEditCompanyActivitiesPopup(false);
                }
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">
                  {companyByIdData?.name}
                </p>
                <Badge
                  className={`${
                    companyByIdData?.status === "active"
                      ? "text-white border-success bg-success"
                      : "text-black bg-secondary_light_shades"
                  } font-medium text-sm capitalize !py-1`}
                  value={
                    companyByIdData?.status === "active"
                      ? "Live"
                      : companyByIdData?.status
                  }
                />
              </div>
              <div className="space-y-5 pb-5 border-b border-secondary_grey">
                {/* SSIC - Company Primary Activity */}
                <div>
                  <ReactSelect
                    className="text-black p-0"
                    id="primary_activity"
                    placeholder="SSIC - Company Primary Activity"
                    options={businessActivitiesArray}
                    isLoading={businessActivitiesLoading}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    value={values.primary_activity}
                    onChange={(e) => setFieldValue("primary_activity", e)}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.primary_activity && touched.primary_activity
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {errors.primary_activity && touched.primary_activity ? (
                    <InputError errorTitle={errors.primary_activity} />
                  ) : null}
                </div>
                {/* Please describe your Primary business activity */}
                <div>
                  <InputWithLabel
                    className={
                      errors.primary_business_activity &&
                      touched.primary_business_activity
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="primary_business_activity"
                    label="Please describe your business activity"
                    type="text"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={values?.primary_business_activity}
                    errors={
                      errors.primary_business_activity &&
                      touched.primary_business_activity
                    }
                    errorsText={errors.primary_business_activity}
                  />
                  <p className="text-light_grey text-sm mt-1">
                    A minimum 100 Characters
                  </p>
                </div>
                {/* SSIC - Company Secondary Activity */}
                <div>
                  <ReactSelect
                    className={"text-black p-0"}
                    id="secondary_activity"
                    placeholder="SSIC - Company Secondary Activity"
                    options={businessActivitiesArray}
                    isLoading={false}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    value={values.secondary_activity}
                    onChange={(e) => {
                      setFieldValue("secondary_activity", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.secondary_activity &&
                          touched.secondary_activity
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  {errors.secondary_activity && touched.secondary_activity ? (
                    <InputError errorTitle={errors.secondary_activity} />
                  ) : null}
                </div>
                {/*  Please describe your Secondary business activity */}
                {values.secondary_activity && (
                  <div>
                    <InputWithLabel
                      className={
                        errors.secondary_business_activity &&
                        touched.secondary_business_activity
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="secondary_business_activity"
                      label="Please describe your business activity"
                      type="text"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={values.secondary_business_activity}
                      errors={
                        errors.secondary_business_activity &&
                        touched.secondary_business_activity
                      }
                      errorsText={errors.secondary_business_activity}
                    />
                    <p className="text-light_grey text-sm mt-1">
                      A minimum 100 Characters
                    </p>
                  </div>
                )}
              </div>

              <div className="flex gap-4 w-full pt-5">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setEditCompanyActivitiesPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme w-full uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditCompanyActivities;
