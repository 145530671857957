import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Badge } from "../../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../commons/ButtonLoader";
import {
  getCompanyById,
  updateCompany,
} from "../../../../redux/companies/slice";
import { toast } from "react-toastify";
import { InputError } from "../../../../commons/MicroComponents";
import CompanyAttractionsFunds from "../../createEditCompany/components/CompanyAttractionsFunds";
import CompanyFundResources from "../../createEditCompany/components/CompanyFundResources";
import ReactSelect from "react-select";

const CompanyFund = ({ setCompanyFundPopup, CompanyData, CompanyInfoData }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [attractionsError, setAttractionsError] = useState("");
  const [attractionsSelectedArray, setAttractionsSelectedArray] = useState({});
  const [companyFundResourcesError, setCompanyFundResourcesError] =
    useState("");
  const [
    companyFundResourcesSelectedArray,
    setCompanyFundResourcesSelectedArray,
  ] = useState([]);
  const [countryArray, setCountryArray] = useState([]);

  // Selector
  const { createCompanyData, countryData } = useSelector((store) => ({
    // Create Company
    createCompanyData: store?.companiesData?.CreateCompanyStatus?.data?.data,
    countryData: store?.addressData?.getCountryDataStatus?.data,
  }));

  // set create Company Data
  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
  }, [createCompanyData, CompanyData, countryData]);

  // Get Initial Values
  const getInitialValues = () => {
    let initial = {
      countries: defaultCountries ?? "",
    };
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const payload = {
        attractions: attractionsSelectedArray?.attractionsSelectedArray,
        attractions_other: attractionsSelectedArray?.attractions_other,
        company_funds:
          companyFundResourcesSelectedArray?.companyFundResourcesSelectedArray,
        company_funds_other:
          companyFundResourcesSelectedArray?.company_funds_other,
        countries: values.countries.map((e) => e.id),
      };
      setLoading(true);
      dispatch(updateCompany({ id: CompanyData?.id, data: payload }))
        .then((res) => {
          if (res.type === "updateCompany/fulfilled") {
            dispatch(getCompanyById(CompanyData?.id));
            toast.success(res?.payload?.message);
            setCompanyFundPopup(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div
      className="model"
      onClick={() => {
        setCompanyFundPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-6 px-6 mx-auto relative scroll-auto h-4/5 overflow-y-auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Sources for their company’s fund
        </h2>
        <div className="flex items-center justify-start w-full gap-3 mb-3">
          <p className="text-xl font-ebGaramond font-semibold">
            Company Pte Ltd
          </p>
          <Badge
            className="text-white border-success bg-success font-medium text-sm capitalize inline-block !py-1"
            value="Live"
          />
        </div>
        <div className="w-full h-full flex-grow">
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p>Source of your company funds</p>
              <p className="text-sm">
                Your inputs are crucial for compliance checks.
              </p>
              <div className="space-y-2 px-3">
                <p>
                  What attracts you to incorporate your business in Singapore?
                </p>
                {attractionsError && (
                  <InputError errorTitle={attractionsError} />
                )}
                <CompanyAttractionsFunds
                  AttractionsData={CompanyInfoData?.attractions}
                  attractionsFundsVales={createCompanyData?.attractions}
                  attractions_other={createCompanyData?.attractions_other}
                  setAttractionsSelectedArray={setAttractionsSelectedArray}
                  attractionsError={attractionsError}
                  setAttractionsError={setAttractionsError}
                />
              </div>
              <div className="space-y-2 px-3">
                <p>Please select all sources for your company's fund</p>
                {companyFundResourcesError && (
                  <InputError errorTitle={companyFundResourcesError} />
                )}

                <CompanyFundResources
                  companyFundResourcesData={CompanyInfoData?.fund_resources}
                  companyFundResourcesVales={createCompanyData?.company_funds}
                  company_funds_other={createCompanyData?.company_funds_other}
                  setCompanyFundResourcesSelectedArray={
                    setCompanyFundResourcesSelectedArray
                  }
                  setCompanyFundResourcesError={setCompanyFundResourcesError}
                />
                {formik.errors.company_funds && formik.touched.company_funds ? (
                  <InputError errorTitle={formik.errors.company_funds} />
                ) : null}
              </div>
              <p>
                Please list up to 3 countries where your business will take
                place.{" "}
              </p>
              {/* Country */}
              <div>
                <div
                  className={`${
                    formik.errors.countries && formik.touched.countries
                      ? ` input-error `
                      : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="countries"
                    placeholder="Countries"
                    options={countryArray}
                    // isLoading={countryLoading}
                    getOptionValue={(countryArray) => countryArray.name}
                    value={formik.values.countries}
                    isMulti
                    onChange={(e) => {
                      formik.setFieldValue("countries", e);
                      formik.setFieldValue("countryId", e?.id);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          formik.errors.countries && formik.touched.countries
                            ? "red"
                            : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="countries"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Countries
                  </label>
                </div>
                {formik.errors.countries && formik.touched.countries ? (
                  <InputError errorTitle={formik.errors.countries} />
                ) : null}
              </div>
            </div>
            <div className="flex gap-4 py-3 w-full">
              <button
                type="button"
                className="btn-outline flex items-center space-x-3 uppercase w-full justify-center"
                onClick={() => setCompanyFundPopup(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn-theme flex items-center space-x-3 uppercase w-full justify-center"
                disabled={loading}
              >
                {loading ? <ButtonLoader /> : <span>Save</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyFund;
