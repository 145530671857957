import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { ArrowDown, FileDownload, Plus } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import DebounceSearch from "../../commons/DebounceSearch";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import Toggles from "../../commons/Toggles";
import { Badge } from "../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerList } from "../../redux/customer/slice";
import { updateCustomer } from "../../redux/companies/slice";
import ConfirmationModel from "../../commons/ConfirmationModel";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Customers = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [customersData, setCustomersData] = useState({});
  const [showConfirmArchivePopup, setShowConfirmArchivePopup] = useState(false);
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // selector
  const customerList = useSelector(
    (store) => store?.customerData?.getCustomerListStatus?.data
  );
  const customerListLoading = useSelector(
    (store) => store?.customerData?.loading
  );
  const archiveLoading = useSelector(
    (store) => store?.customerData?.updateCustomerStatus?.loading
  );

  useEffect(() => {
    dispatch(
      getCustomerList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        kyc: selectedKyc?.value ?? "",
        tag_status:
          selectedTagStatus?.value === "tagged"
            ? 1
            : selectedTagStatus?.value === "untagged"
            ? 0
            : "",
        status: activeTab === "archive" ? "inactive" : activeTab,
        sort_by: addSort,
        sort_direction: addSortDirection === "asc" ? "asc" : "desc",
      })
    );
  }, [
    dispatch,
    searchValue,
    pageCount,
    pageLimit,
    activeTab,
    selectedKyc,
    selectedTagStatus,
    addSort,
    addSortDirection,
  ]);

  // Active and Archived Tab
  const tabs = ["active", "archive"];
  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "tagged", label: "Tagged" },
    { value: "untagged", label: "Untagged" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "high_risk-reject", label: "High Risk - Reject" },
    { value: "pending", label: "Pending" },
  ];

  // Archive Customer
  const handleArchive = ({ element, checked }) => {
    dispatch(
      updateCustomer({
        id: element?.id,
        data: {
          status: checked
            ? element?.status === "active"
              ? "inactive"
              : "active"
            : element?.status === "active"
            ? "archive"
            : "active", //active/inactive/archive,
        },
      })
    ).then((res) => {
      if (res.type === "updateCustomer/fulfilled") {
        setShowConfirmArchivePopup(false);
        dispatch(
          getCustomerList({
            search: searchValue,
            pageCount: pageCount,
            pageLimit: pageLimit,
            kyc: selectedKyc?.value ?? "",
            tag_status:
              selectedTagStatus?.value === "tagged"
                ? 1
                : selectedTagStatus?.value === "untagged"
                ? 0
                : "",
            status: activeTab === "archive" ? "inactive" : activeTab,
            sort_by: addSort,
            sort_direction: addSortDirection === "asc" ? "asc" : "desc",
          })
        );
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          <div className="flex items-center">
            <p> id </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "user_id"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("user_id");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Name </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "full_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("full_name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Email </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "email"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("email");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Mobile </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "phone"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("phone");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> KYC </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "kyc_status"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("kyc_status");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Tag Status </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "tagged"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("tagged");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        {!(activeTab === "archive") && (
          <th
            scope="col"
            className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
          >
            Status
          </th>
        )}
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(customerList?.data?.data || [])?.map((element) => {
          return (
            <tr
              className={
                "border-b table_responsive_text hover:bg-secondary_light_shades"
              }
              key={element?.id}
            >
              <td className="px-5 py-3 truncate">{element?.user_id}</td>
              <td className="px-5 py-3 truncate capitalize">
                {element?.full_name}
              </td>
              <td className="px-5 py-3 truncate">{element?.email}</td>
              <td className="px-5 py-3 truncate">{element?.phone || "-"}</td>
              <td className="px-5 py-3 truncate">
                {
                  <div className="flex justify-start">
                    <Badge
                      className={`capitalize ${
                        element?.kyc_status === "low_risk"
                          ? "border-green-700 text-green-700"
                          : element?.kyc_status === "mid_risk"
                          ? "border-orange-700 text-orange-700"
                          : element?.kyc_status === "high_risk"
                          ? "border-red-700 text-red-700"
                          : element?.kyc_status === "pending"
                          ? "text-black"
                          : ""
                      }`}
                      value={
                        element?.kyc_status === "low_risk"
                          ? "Low Risk"
                          : element?.kyc_status === "mid_risk"
                          ? "Mid Risk"
                          : element?.kyc_status === "high_risk"
                          ? "High Risk"
                          : element?.kyc_status === "pending"
                          ? "Pending"
                          : "-"
                      }
                    />
                  </div>
                }
              </td>
              <td className="px-5 py-3 truncate">
                <div className="flex justify-start">
                  <Badge
                    className={`text-white capitalize ${
                      element?.tagged === 1
                        ? "border-green-700 bg-green-700"
                        : "border-red-700 bg-red-700"
                    }`}
                    value={element?.tagged === 1 ? "Tagged" : "Untagged"}
                  />
                </div>
              </td>
              {!(activeTab === "archive") && (
                <td className="px-5 py-3 truncate">
                  <Toggles
                    toggleFor={element?.id}
                    checked={element?.status === "active"}
                    onChange={() =>
                      handleArchive({ element: element, checked: true })
                    }
                  />
                </td>
              )}
              <td className="flex justify-start px-5 py-3">
                <Action
                  optionList={
                    <>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                        onClick={() =>
                          navigate("/create-edit-customers", {
                            state: {
                              element: element,
                            },
                          })
                        }
                      >
                        View/Edit
                      </li>
                      <li
                        className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                        onClick={() => {
                          setCustomersData(element);
                          setShowConfirmArchivePopup(true);
                        }}
                      >
                        Archive
                      </li>
                    </>
                  }
                />
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="space-y-5">
        {/* Page Header */}
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
              Customers
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Customers"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-customers");
            }}
          >
            <Plus className="mr-2" /> Add Customers
          </button>
        </div>
        {/* Active | Archive Tab */}
        <div className="flex justify-end">
          {tabs?.map((tab, index) => {
            return (
              <button
                key={tab}
                className={`transition uppercase duration-300 ${
                  index === 0 ? "!rounded-l !rounded-r-none" : ""
                }
            ${index === tabs.length - 1 ? "!rounded-r !rounded-l-none" : ""} ${
                  activeTab === tab ? "btn-theme" : "btn-light_primary"
                }`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </div>
        {/* Search | filter for List */}
        <div className="flex lg:flex-row flex-col lg:justify-between lg:items-start items-end lg:space-y-0 space-y-2">
          <div className="sm:flex items-center sm:space-x-3 space-x-0 md:w-auto w-full sm:space-y-0 space-y-2">
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
            <ReactSelect
              className={
                "w-full sm:max-w-xs text-black responsive_base_text whitespace-nowrap p-0"
              }
              id="tagStatusDropdown"
              placeholder="Select Tag Status"
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={
                "w-full sm:max-w-xs text-black responsive_base_text p-0"
              }
              id="kyc"
              placeholder="Select KYC"
              options={kycDropdown}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={`Customer`}
            sheet={`Customer`}
            buttonText={
              <>
                <button className="btn-outline flex items-center uppercase">
                  <FileDownload width="14" height="17" className="mr-3" />
                  Export CSV
                </button>
              </>
            }
          />
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={customerList?.data}
            overflowVisibleLg={true}
            // loader
            loading={customerListLoading || archiveLoading}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={customerList?.data?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {showConfirmArchivePopup && (
        <ConfirmationModel
          isLoading={archiveLoading}
          onClose={() => setShowConfirmArchivePopup(false)}
          onConfirm={() =>
            handleArchive({ element: customersData, checked: false })
          }
          confirmText={"Archive"}
        />
      )}
      <table id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Mobile</th>
            <th scope="col">KYS</th>
            <th scope="col">Tag Status</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {customerList?.data?.data?.map((element) => (
            <tr>
              <td>{element?.user_id ?? "-"}</td>
              <td>{element?.full_name ?? "-"}</td>
              <td>{element?.email ?? "-"}</td>
              <td>{element?.phone ?? "-"}</td>
              <td>
                {element?.kyc_status === "low_risk"
                  ? "Low Risk"
                  : element?.kyc_status === "mid_risk"
                  ? "Mid Risk"
                  : element?.kyc_status === "high_risk"
                  ? "High Risk"
                  : element?.kyc_status === "pending"
                  ? "Pending"
                  : ""}
              </td>
              <td>{element?.tagged === 1 ? "Tagged" : "Untagged"}</td>
              <td>{element?.status === "active" ? "Active" : "Inactive"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Customers;
