import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { Form, Formik } from "formik";
import { InputWithLabel, SelectBox } from "../../components/micro";
import { InputError } from "../../commons/MicroComponents";
import * as Yup from "yup";
import Toggles from "../../commons/Toggles";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModel from "../../commons/ConfirmationModel";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CreateBroadcasts } from "../../redux/broadcast/slice";
import moment from "moment";

const user_typeList = [
  {
    label: "1 year Subscription",
    value: "1",
  },
  {
    label: "3 year Subscription",
    value: "3",
  },
  {
    label: "5 year Subscription",
    value: "5",
  },
];
const CreateEditBroadcast = () => {
  const [scheduled, setScheduled] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [broadcastData, setBroadcastData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const broadcastSData = location?.state?.element;

  useEffect(() => {
    if (broadcastSData) {
      setScheduled(broadcastSData?.scheduled === 0 ? false : true);
    }
  }, [broadcastSData]);

  // Submit on Conform
  const handleSubmit = (value) => {
    const payload = {
      subject: value?.subject,
      content: value?.content,
      scheduled: scheduled,
      notified_at: scheduled
        ? moment(value?.date_time).format("YYYY-MM-DD hh:mm:ss")
        : "",
      subscription_type: value?.user_type?.value,
    };
    setIsLoading(true);
    if (broadcastSData?.id) {
      dispatch(CreateBroadcasts({ id: broadcastSData?.id, ...payload })).then(
        (res) => {
          if (res.type === "CreateBroadcasts/fulfilled") {
            setIsLoading(false);

            setIsConfirm(false);
            navigate("/broadcast");
          }
        }
      );
    } else {
      dispatch(CreateBroadcasts(payload)).then((res) => {
        if (res.type === "CreateBroadcasts/fulfilled") {
          setIsLoading(false);

          setIsConfirm(false);
          navigate("/broadcast");
        }
      });
    }
  };

  const subscription_type = {
    label: `${
      broadcastSData?.subscription_type === "1"
        ? "1"
        : broadcastSData?.subscription_type === "3"
        ? "3"
        : "5"
    } Year Subscription`,
    value: broadcastSData?.subscription_type,
  };
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
          Create Broadcast
        </h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbActive={"Create Broadcast"}
          breadCrumbParent2={"Broadcast"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <div className="w-full bg-white border rounded py-5 px-6">
        <Formik
          enableReinitialize={true}
          initialValues={{
            subject: broadcastSData?.subject ?? "",
            content: broadcastSData?.content ?? "",
            user_type: broadcastSData?.subscription_type
              ? subscription_type
              : "",
            date_time: broadcastSData?.notified_at
              ? new Date(broadcastSData?.notified_at)
              : null,
          }}
          validationSchema={Yup.object().shape({
            subject: Yup.string().min(3).required("Please Enter Subject"),
            content: Yup.string().min(3).required("Please Enter Content"),
            user_type: Yup.object().required("Please Select User Type"),
            date_time: scheduled
              ? Yup.string().nullable().required("Please Select Date & Time")
              : "",
          })}
          onSubmit={(values) => {
            setBroadcastData(values);
            setIsConfirm(true);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="space-y-5 mb-5">
                {/* Subject */}
                <InputWithLabel
                  className={
                    errors.subject && touched.subject
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="subject"
                  label="Subject"
                  type="subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                  errors={errors.subject && touched.subject}
                  errorsText={errors.subject}
                />
                {/* Content */}
                <InputWithLabel
                  className={
                    errors.content && touched.content
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="content"
                  label="Content"
                  type="content"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.content}
                  errors={errors.content && touched.content}
                  errorsText={errors.content}
                />
                {/* User Type (Subscription Tier) */}
                <div>
                  <div
                    className={`${
                      errors.user_type && touched.user_type
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <SelectBox
                      id="user_type"
                      placeholder="Select User Type (Subscription Type)"
                      options={user_typeList}
                      isLoading={false}
                      getOptionValue={(user_typeList) => user_typeList.value}
                      value={values.user_type}
                      onChange={(e) => {
                        setFieldValue("user_type", e);
                      }}
                      errors={errors.user_type && touched.user_type}
                      errorsText={errors.user_type}
                    />
                    <label
                      htmlFor="user_type"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      User Type (Subscription Type)
                    </label>
                  </div>
                </div>
                {/* Scheduled */}
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3">
                    <p className="text-light_grey sm:text-base text-sm">
                      Scheduled?
                    </p>
                    <Toggles
                      checked={scheduled}
                      toggleFor={"scheduled"}
                      onChange={() => {
                        setScheduled(!scheduled);
                      }}
                    />
                  </div>
                  {/* Date & Time */}
                  {scheduled && (
                    <div className="max-w-sm w-full">
                      <div
                        className={`${
                          errors.date_time && touched.date_time
                            ? ` input-error `
                            : ""
                        } relative bg-white transition duration-300 rounded plus-number-inputs`}
                      >
                        <ReactDatePicker
                          selected={values.date_time}
                          onChange={(date) => setFieldValue("date_time", date)}
                          dateFormat="dd/MM/yyyy HH:mm"
                          showTimeSelect
                          timeFormat="HH:mm"
                          selectsStart
                          timeIntervals={15}
                          timeCaption="time"
                          id="date_time"
                          placeholderText={"Select Date & Time"}
                          className={`border error-forms ${
                            errors.date_time && touched.date_time
                              ? "border-red"
                              : "border-secondary_grey"
                          } rounded-lg w-full px-4 py-3`}
                        />
                        <label
                          htmlFor="date_time"
                          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                        >
                          Date & Time
                        </label>
                      </div>
                      {errors.date_time && touched.date_time ? (
                        <InputError errorTitle={errors.date_time} />
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  className="btn-outline uppercase"
                  onClick={() => navigate("/broadcast")}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme uppercase">
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {isConfirm && (
        <ConfirmationModel
          confirmText={"Create Broadcast"}
          how
          delete
          isLoading={isLoading}
          onClose={() => {
            setIsConfirm(false);
          }}
          onConfirm={() => {
            handleSubmit(broadcastData);
          }}
        />
      )}
    </div>
  );
};

export default CreateEditBroadcast;
