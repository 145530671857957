import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ReactSelect from "react-select";
import { FileDownload, SalesRevenueIcon } from "../../../assets/svg/AllSvg";
import { Badge } from "../../../components/micro";

const SalesRevenueGraph = () => {
  const [year, setYear] = useState({ value: "2013", label: "Year 2023" });

  const barData = [
    1000, 1500, 1400, 1600, 1800, 1700, 1400, 1800, 1900, 1800, 1200, 1500,
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false, // hides the toolbar
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
        borderRadius: 4, // rounded corners
      },
    },
    stroke: {
      show: true,
      width: 0, // 0 for bars, 2 for area chart
      colors: ["#00E396"], // Border color for the top "stroke"
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#B0E0E6"], // end color for gradient
        inverseColors: false,
        opacityFrom: 0.9,
        opacityTo: 0.4,
        stops: [0, 100],
      },
    },
    colors: [
      function ({ dataPointIndex }) {
        return dataPointIndex === 4 ? "#3FE0C5" : "#007BFF"; // highlight specific bar
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "#555",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => `$${value}`, // format y-axis labels with dollar sign
        style: {
          colors: "#555",
          fontSize: "12px",
        },
      },
      min: 0,
      max: 2000,
      tickAmount: 4, // controls number of grid lines
    },
    grid: {
      borderColor: "#e0e0e0",
      strokeDashArray: 4, // dotted grid lines
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val}`, // format tooltip with dollar sign
      },
    },
  };

  const series = [
    {
      name: "Sales Revenue",
      data: barData,
    },
  ];

  // Function to export data as CSV
  const exportCSV = () => {
    // Convert the data to CSV format
    const csvData = [
      ["Month", "Sales Revenue"], // header row
      ...series[0].data.map((value, index) => [
        options.xaxis.categories[index],
        value,
      ]),
    ];

    const csvString = csvData.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "sales_revenue.csv";
    a.click();

    URL.revokeObjectURL(url);
  };
  return (
    <>
      <div className="card space-y-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <SalesRevenueIcon />
            <p className="font-ebGaramond text-lg">Sales Revenue</p>
          </div>
          <div className="flex items-center gap-3">
            <ReactSelect
              className="text-xs text-secondary remove-line"
              id="year"
              placeholder="Select Year"
              options={[
                { value: "2013", label: "Year 2023" },
                { value: "2022", label: "Year 2022" },
                { value: "2021", label: "Year 2021" },
                { value: "2020", label: "Year 2020" },
              ]}
              value={year}
              onChange={(e) => {
                setYear(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #e5e7eb`,
                  borderRadius: "0.25rem",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #065699",
                  },
                }),
              }}
            />
            <button
              className="btn-outline flex items-center uppercase"
              onClick={exportCSV}
            >
              <FileDownload width="14" height="17" className="mr-3" />
              Export CSV
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-end gap-2">
            <p className="text-2xl font-ebGaramond">$23,200</p>
            <p className="text-light_grey text-sm">Earned</p>
          </div>
          <div className="flex items-center space-x-2">
            <Badge value={"+ 12%"} className={"bg-success text-white !py-1"} />
            <p className="text-light_grey">vs</p>
            <p className="text-theme">Last Year</p>
          </div>
        </div>
        <div>
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
          <div id="html-dist"></div>
        </div>
      </div>
    </>
  );
};

export default SalesRevenueGraph;
