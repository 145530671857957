import { useEffect } from "react";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

const usePushNotifications = () => {
  const instanceId = process.env.REACT_APP_PUSHER_INSTANCE_ID;

  useEffect(() => {
    // Check for Notification and Service Worker support
    if (!("Notification" in window) || !("serviceWorker" in navigator)) {
      console.warn(
        "Browser does not support notifications or service workers."
      );
      return;
    }

    // Request Notification permission if not already granted
    if (Notification.permission !== "granted") {
      Notification.requestPermission().catch((error) => {
        console.error("Error requesting notification permission:", error);
      });
    }

    const beamsClient = new PusherPushNotifications.Client({
      instanceId: instanceId,
    });

    const userId = sessionStorage.getItem("userId");
    if (!userId) {
      console.warn("No userId found in session storage.");
      return;
    }

    // Start Pusher Beams and subscribe to user-specific notifications
    beamsClient
      .start()
      .then(() => beamsClient.addDeviceInterest(userId))
      .catch((error) => console.error("Beams error:", error));

    // Handle incoming push notifications
    const handlePushEvent = (event) => {
      const notificationData = event.data?.json();
      if (notificationData) {
        console.log("Notification payload received:", notificationData);
      } else {
        console.warn("Received push event without data.");
      }
    };

    // Add push event listener if service worker is available
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("push", handlePushEvent);
    }

    // Cleanup function to remove event listener
    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener("push", handlePushEvent);
      }
    };
  }, [instanceId]);
};

export default usePushNotifications;
