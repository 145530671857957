import React, { useEffect, useRef, useState } from "react";
import {
  Close,
  ImageIcon,
  PDFDoc,
  Redo,
  RippleLoaderSVG,
  Undo,
  User,
} from "../../../assets/svg/AllSvg";
import ReactSelect from "react-select";
import { formats, modules } from "../../../commons/Editor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile } from "../../../redux/uploadFile/slice";
import {
  getTickets,
  getTicketsList,
  replayTicket,
} from "../../../redux/ticket/slice";
import moment from "moment";
import { Form, Formik } from "formik";
import Action from "../../../commons/Action";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import RippleLoader from "../../../commons/RippleLoader";

const ReplyTicket = ({
  setReplyTicketModel,
  ticketData,
  searchValue,
  selectedPriority,
  selectedPeriod,
  pageCount,
  pageLimit,
  status,
}) => {
  const [confirmReplayTicket, setConfirmReplayTicket] = useState(false);
  const [documentArray, setDocumentArray] = useState([]);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [replayTicketData, setReplayTicketData] = useState({});
  const quillRef = useRef(null);
  const dispatch = useDispatch();

  // selector
  const getTicketsData = useSelector(
    (store) => store?.ticketData?.getTicketsStatus?.data?.data
  );
  const ticketLoading = useSelector(
    (store) => store?.ticketData?.getTicketsStatus?.loading
  );

  // Get Customer and Staff APIs
  useEffect(() => {
    if (ticketData?.id) {
      dispatch(getTickets(ticketData?.id));
    }
  }, [dispatch, ticketData]);

  // Submit Reply Ticket
  const handleConfirmReplayTicket = () => {
    const payload = {
      status: replayTicketData?.ticket_status,
      priority: replayTicketData?.priority_status,
      message: replayTicketData?.message,
      attachments: documentArray?.map((e) => e.id),
    };
    setIsLoading(true);
    dispatch(replayTicket({ id: getTicketsData?.id, data: payload })).then(
      (res) => {
        setIsLoading(false);
        setConfirmReplayTicket(false);
        if (res.type === "replayTicket/fulfilled") {
          dispatch(
            getTicketsList({
              search: searchValue,
              priority: selectedPriority?.value ?? "",
              period: selectedPeriod?.value ?? "",
              pageCount: pageCount,
              pageLimit: pageLimit,
              status: status,
            })
          );
          setReplyTicketModel(false);
        }
      }
    );
  };

  // Get File URL Upload Editor
  const handleUpload = (e) => {
    const file = e.target.files[0];
    const type = file?.type;
    const formData = new FormData();
    formData.append("type", "ticket_reply");
    formData.append("file", file);
    formData.append(
      "document_type",
      type === "application/pdf" ? "doc" : "img"
    );
    setIsFileUpload(true);
    dispatch(uploadFile(formData)).then((res) => {
      setIsFileUpload(false);
      if (res.type === "uploadFile/fulfilled") {
        setDocumentArray((prevArray) => [...prevArray, res.payload.data]);
        quillRef.current?.getEditor();
      }
    });
  };

  // Add Undo and Redo buttons to the toolbar
  const CustomToolbar = () => (
    <div
      id="toolbar"
      className="flex justify-between items-center absolute max-w-[calc(100%-75px)] w-full left-20 right-0 pl-3 pr-5 h-10"
    >
      <div className="flex items-center space-x-2">
        <div className="relative mt-1">
          <ImageIcon width="15" height="15" className="ml-2" />
          <input
            type="file"
            accept=".jpeg,.jpg,.png,.pdf"
            className="absolute top-0 left-0 w-full h-full opacity-0"
            onChange={handleUpload}
          />
        </div>
      </div>
      <div className="flex items-center gap-3">
        <button
          className="ql-undo"
          type="button"
          onClick={() => quillRef.current.getEditor().history.undo()}
        >
          <Undo />
        </button>
        <button
          className="ql-redo"
          type="button"
          onClick={() => quillRef.current.getEditor().history.redo()}
        >
          <Redo />
        </button>
      </div>
    </div>
  );

  // ticket Status Options
  const ticketOptions = [
    { label: "On-Going", value: "on_going" },
    { label: "Resolved", value: "resolved" },
  ];

  // priority Status Options
  const priorityStatusOptions = [
    { label: "Low Priority", value: "low" },
    { label: "Mid Priority", value: "mid" },
    { label: "High Priority", value: "high" },
  ];

  return (
    <div
      className="model"
      onClick={() => {
        setReplyTicketModel(false);
      }}
    >
      <div
        className="bg-white drop-shadow-lg rounded-lg w-full max-w-3xl max-h-[700px] overflow-y-auto min-h-36 py-5 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Close
          className="cursor-pointer absolute top-5 right-5"
          onClick={() => {
            setReplyTicketModel(false);
          }}
        />
        {ticketLoading ? (
          <RippleLoader />
        ) : (
          <div className="space-y-4">
            <div className="text-dark_purple space-y-1">
              <p className="w-full font-medium lg:text-lg sm:text-base text-sm">
                {getTicketsData?.user?.full_name}
              </p>
              <p>Customer Email: {getTicketsData?.user?.email}</p>
              {/* <p>Company: -</p> */}
            </div>
            <div className="sm:flex justify-between items-center sm:space-y-0 space-y-1">
              <div className="flex items-center sm:justify-normal justify-between space-x-2">
                <span
                  className={`${
                    getTicketsData?.status === "new"
                      ? "bg-light_primary_50p text-white"
                      : getTicketsData?.status === "on_going"
                      ? "bg-primary_shades_light text-theme"
                      : getTicketsData?.status === "resolved"
                      ? "bg-light_secondary_green text-theme"
                      : "bg-light_action_disabled text-theme"
                  } rounded-t-md lg:px-4 sm:px-3 px-2 responsive_base_text py-1`}
                >
                  {getTicketsData?.status === "new"
                    ? "New"
                    : getTicketsData?.status === "on_going"
                    ? "On Going"
                    : getTicketsData?.status === "resolved"
                    ? "Resolved"
                    : getTicketsData?.status === "archived"
                    ? "Archived"
                    : "Deleted"}
                </span>
                <p className="text-theme responsive_base_text">
                  Ticket# {getTicketsData?.unique_id}
                </p>
              </div>
              <p className="text-light_grey responsive_base_text">
                Posted on{" "}
                {moment(getTicketsData?.created_at).format(
                  "DD/MM/YYYY | HH:MM"
                )}
              </p>
            </div>
            <p className="text-secondary responsive_base_text">
              {getTicketsData?.title}:
              <div
                dangerouslySetInnerHTML={{ __html: getTicketsData?.message }}
              />
            </p>
            <div className="max-h-60 space-y-3 overflow-y-auto">
              {getTicketsData?.replies?.map((e, i) => {
                return (
                  <div
                    className={`${
                      i % 2 === 0 ? "bg-light_extra_green" : "bg-light_theme"
                    } text-secondary rounded-xl space-y-2 p-4`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="font-ebGaramond capitalize text-xl">
                        {e?.user?.full_name === "admin"
                          ? "EI BIZ"
                          : e?.user?.full_name}
                      </p>
                      <p className="text-light_grey text-sm">
                        {moment(e?.created_at).format("DD/MM/YYYY | HH:MM")}
                      </p>
                    </div>
                    <div>
                      <p className="sm:text-sm text-xs mb-1">Message:</p>
                      <div
                        className="sm:text-sm text-xs"
                        dangerouslySetInnerHTML={{ __html: e?.message }}
                      />
                    </div>
                    {e?.attachments?.length ? (
                      <div className="flex items-start flex-wrap gap-3">
                        {e?.attachments?.map((element) => {
                          return (
                            <>
                              {element?.document?.document_type === "doc" ? (
                                <div
                                  className={
                                    "card bg-light_secondary flex justify-between items-center max-w-60 w-full relative"
                                  }
                                >
                                  <div className="flex items-center w-full">
                                    <PDFDoc className="mr-3" />
                                    <p className="text-black font-normal max-w-full sm:text-sm text-xs truncate">
                                      {element?.document?.name}
                                    </p>
                                  </div>
                                  <button type="button">
                                    <Action
                                      optionList={
                                        <>
                                          <a
                                            href={element?.document?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                          >
                                            View
                                          </a>
                                          <a
                                            href={element?.document?.url}
                                            rel="noreferrer"
                                            target="_blank"
                                            className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                          >
                                            Download
                                          </a>
                                        </>
                                      }
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className="relative max-w-24 h-24 w-full rounded-md border-2">
                                  <button
                                    type="button"
                                    className="absolute bg-white rounded-md top-1 right-1"
                                  >
                                    <Action
                                      className="!px-2"
                                      optionList={
                                        <>
                                          <a
                                            href={element?.document?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                          >
                                            View
                                          </a>
                                          <a
                                            href={element?.document?.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            className="flex gap-2 items-center lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                          >
                                            Download
                                          </a>
                                        </>
                                      }
                                    />
                                  </button>
                                  <img
                                    src={element?.document?.url}
                                    alt="img"
                                    className="w-full h-full rounded-md object-cover"
                                  />
                                </div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            {/* Replay Add */}
            <Formik
              enableReinitialize={true}
              initialValues={{
                priority_status: getTicketsData?.priority ?? "",
                ticket_status: getTicketsData?.status ?? "",
                message: "",
              }}
              onSubmit={(values) => {
                setReplayTicketData(values);
                setConfirmReplayTicket(true);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <Form className="w-full space-y-3" onSubmit={handleSubmit}>
                  <div className="border-t border-secondary_grey space-y-3 pt-2">
                    <div className="flex sm:justify-end w-full gap-3">
                      {/* Priority Status */}
                      <ReactSelect
                        className={
                          "text-black whitespace-nowrap responsive_base_text sm:w-auto w-full p-0"
                        }
                        id="priority_status"
                        placeholder="Priority Status"
                        options={priorityStatusOptions}
                        isLoading={false}
                        getOptionValue={(options) => options.value}
                        getOptionLabel={(options) => options.label}
                        value={
                          priorityStatusOptions?.length > 0 &&
                          priorityStatusOptions?.find((op) => {
                            return op.value === values.priority_status;
                          })
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("priority_status", e.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.priority_status && touched.priority_status
                                ? "red"
                                : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />

                      {/* Ticket Status */}
                      <ReactSelect
                        className={
                          "text-black whitespace-nowrap responsive_base_text sm:w-auto w-full p-0"
                        }
                        id="ticket_status"
                        placeholder="Ticket Status"
                        options={ticketOptions}
                        isLoading={false}
                        getOptionValue={(options) => options.value}
                        getOptionLabel={(options) => options.label}
                        value={
                          ticketOptions?.length > 0 &&
                          ticketOptions?.find((op) => {
                            return op.value === values.ticket_status;
                          })
                        }
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("ticket_status", e.value);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.ticket_status && touched.ticket_status
                                ? "red"
                                : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />
                    </div>
                    {/* Message Editor */}
                    <div className="relative">
                      <CustomToolbar />
                      <ReactQuill
                        id="message"
                        name="message"
                        onChange={handleChange("message")}
                        className="responsive_base_text"
                        theme="snow"
                        value={values.message}
                        modules={modules}
                        formats={formats}
                        bounds={"#root"}
                        ref={quillRef}
                        placeholder={"Message"}
                      />
                    </div>
                    {/* uploaded Fill */}
                    {isFileUpload ? (
                      <RippleLoaderSVG />
                    ) : (
                      <>
                        {documentArray?.length ? (
                          <div className="col-span-2 flex items-start border border-secondary_grey flex-wrap rounded px-4 py-3 gap-3 relative">
                            <label className="text-light_grey bg-white text-base px-2 absolute pointer-events-none scale-75 -translate-y-6 left-1">
                              Media
                            </label>
                            {documentArray?.map((element) => {
                              return (
                                <>
                                  {element?.document_type === "doc" ? (
                                    <div
                                      className={
                                        "card bg-light_secondary flex justify-between items-center max-w-60 w-full relative"
                                      }
                                    >
                                      <div className="flex items-center w-full">
                                        <PDFDoc className="mr-3" />
                                        <p className="text-black font-normal max-w-full text-sm truncate">
                                          {element?.name}
                                        </p>
                                      </div>
                                      <button type="button">
                                        <Action
                                          optionList={
                                            <>
                                              <a
                                                href={element?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                              >
                                                View
                                              </a>
                                              <a
                                                href={element?.url}
                                                rel="noreferrer"
                                                target="_blank"
                                                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                              >
                                                Download
                                              </a>
                                            </>
                                          }
                                        />
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="relative max-w-24 h-24 w-full rounded-md border-2">
                                      <button
                                        type="button"
                                        className="absolute bg-white rounded-md top-1 right-1"
                                      >
                                        <Action
                                          className="!px-2"
                                          optionList={
                                            <>
                                              <a
                                                href={element?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                              >
                                                View
                                              </a>
                                              <a
                                                href={element?.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                                              >
                                                Download
                                              </a>
                                            </>
                                          }
                                        />
                                      </button>
                                      <img
                                        src={element?.url}
                                        alt="img"
                                        className="w-full h-full rounded-md object-cover"
                                      />
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </div>
                        ) : null}
                      </>
                    )}
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2 w-full">
                        <div className="max-w-8 w-full h-8 flex justify-center items-center bg-grey rounded-full">
                          {getTicketsData?.staff?.profile_path ? (
                            <img
                              src={getTicketsData?.staff?.profile_path}
                              alt="profile"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <User pathClass="fill-white" fillOpacity={1} />
                          )}
                        </div>
                        <p className="text-theme border-b border-theme">
                          {getTicketsData?.staff?.full_name}
                        </p>
                      </div>
                      <button
                        type="submit"
                        className="btn-theme uppercase whitespace-nowrap"
                      >
                        Submit Reply
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
      {/* Confirm Reply Ticket */}
      {confirmReplayTicket && (
        <ConfirmationModel
          isLoading={isLoading}
          onClose={() => {
            setConfirmReplayTicket(false);
          }}
          onConfirm={() => handleConfirmReplayTicket()}
          confirmText={"Reply Ticket"}
        />
      )}
    </div>
  );
};

export default ReplyTicket;
