import React, { useState } from "react";
import { Badge } from "../../../components/micro";
import RegisteredOfficeAddress from "../createEditCompany/companyAddress/RegisteredOfficeAddress";
import NewRegisteredOfficeAddress from "../createEditCompany/companyAddress/NewRegisteredOfficeAddress";
import { BusinessBuilding } from "../../../assets/svg/AllSvg";
import { Link } from "react-router-dom";

const EditAddressCompanyActivities = ({
  setAddressCompanyActivitiesPopup,
  addressCompanyActivitiesPopup,
  companyId,
  companyByIdData,
  relevantAddress,
}) => {
  const [activeTab, setActiveTab] = useState(relevantAddress ? 1 : 0);

  const tabs = [
    {
      text: "Use EiBiz's registered address & Digital Mailroom Service",
      id: 0,
      content: (
        <RegisteredOfficeAddress
          companyId={companyId}
          addressCompanyActivitiesPopup={addressCompanyActivitiesPopup}
          setAddressCompanyActivitiesPopup={setAddressCompanyActivitiesPopup}
          companyByIdData={companyByIdData}
        />
      ),
      price: 400,
    },
    {
      text: "Use your own company address",
      link: "Eligibility Criteria",
      linkUrl:
        "https://www.acra.gov.sg/how-to-guides/setting-up-a-local-company/registered-office-address",
      id: 1,
      content: (
        <NewRegisteredOfficeAddress
          companyId={companyId}
          addressCompanyActivitiesPopup={addressCompanyActivitiesPopup}
          setAddressCompanyActivitiesPopup={setAddressCompanyActivitiesPopup}
          companyByIdData={companyByIdData}
        />
      ),
    },
  ];

  return (
    <div
      className="model"
      onClick={(e) => {
        setAddressCompanyActivitiesPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col drop-shadow-lg rounded-lg w-full max-w-2xl min-h-40 py-6 px-6 mx-auto relative scroll-auto h-3/4 overflow-y-auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Registered Office Address
        </h2>
        <div className="flex items-center space-x-3 mb-5">
          <p className="text-xl font-ebGaramond">{companyByIdData?.name}</p>
          <Badge
            className={`${
              companyByIdData?.status === "active"
                ? "text-white border-success bg-success"
                : "text-black bg-secondary_light_shades"
            } font-medium text-sm capitalize !py-1`}
            value={
              companyByIdData?.status === "active"
                ? "Live"
                : companyByIdData?.status
            }
          />
        </div>
        {/* tab head */}
        <div className="grid grid-cols-2 gap-5 mb-5">
          {tabs?.map((element) => {
            return (
              <div
                className={`p-4 ${
                  activeTab === element?.id ? "bg-light_theme" : "bg-white"
                } rounded-lg border-2 space-y-3`}
                onClick={() => {
                  setActiveTab(element?.id);
                }}
              >
                <div className="flex space-x-5">
                  <BusinessBuilding />
                  <div className="space-y-3 w-full">
                    <p>{element?.text}</p>
                    {element?.price && (
                      <div className="flex justify-between space-x-1 bg-light_theme px-4 py-3">
                        <p>Price</p>
                        <div>
                          <p className="text-sm text-gray-400">Annual Fees</p>
                          <p>$400</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {element?.link && (
                  <Link
                    target="_blank"
                    to={element?.linkUrl}
                    className="text-theme border-b border-theme inline-block"
                  >
                    {element?.link}
                  </Link>
                )}
              </div>
            );
          })}
        </div>
        {/* tab content */}
        <div className="w-full h-full">
          {tabs?.map(
            (element) => activeTab === element?.id && element?.content
          )}
        </div>
      </div>
    </div>
  );
};

export default EditAddressCompanyActivities;
