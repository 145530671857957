import React from "react";

const ViewActivityLog = ({ onclose, details }) => {
  return (
    <div className="model" onClick={onclose}>
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <pre className="bg-[#f4f4f4] p-2 rounded overflow-x-auto text-base">
          <code className="font-[Courier_New,Courier,monospace]">
            {JSON.stringify(details, null, 2)}
          </code>
        </pre>
      </div>
    </div>
  );
};

export default ViewActivityLog;
