import React, { useState } from "react";
import Action from "../../commons/Action";
import { DownArrow } from "../../assets/svg/AllSvg";
import moment from "moment/moment";

const RemarksTableData = ({
  element,
  index,
  setRemarkData,
  setConfirmDelete,
  setShowEditRemarks,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <tr
        className="border-b text-sm cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <td className="px-5 py-3">{index + 1}</td>
        <td className="px-5 py-3">Test Remarks here</td>
        <td className="text-center px-5 py-3">
          {moment(element?.date).format("MM/DD/YYYY")}
        </td>
        <td className="text-center px-5 py-3">
          <div className="flex items-center justify-center space-x-3">
            <Action
              optionList={
                <>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                    onClick={() => {
                      setRemarkData(element);
                      setShowEditRemarks(true);
                    }}
                  >
                    Edit
                  </li>
                  <li
                    className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                    onClick={() => {
                      setRemarkData(element);
                      setConfirmDelete(true);
                    }}
                  >
                    Delete
                  </li>
                </>
              }
            />
            {isOpen ? <DownArrow className="rotate-180" /> : <DownArrow />}
          </div>
        </td>
      </tr>
      {isOpen && (
        <tr className="border-b text-sm">
          <td
            className="px-5 py-3 bg-light_secondary_shades sm:text-base text-sm"
            colSpan={4}
          >
            {element?.remark}
          </td>
        </tr>
      )}
    </>
  );
};

export default RemarksTableData;
