import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Company Exist
export const companyExist = createAsyncThunk(
  "companyExist",
  async ({ id, company_name }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `company-exist?name=${company_name}&id=${id}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Companies List
export const companiesList = createAsyncThunk(
  "companiesList",
  async (
    {
      search,
      kyc,
      status,
      tag_status,
      pageLimit,
      pageCount,
      sort_by,
      sort_direction,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `companies?search=${search}&sort_by=${sort_by}&sort_direction=${sort_direction}&kyc_status=${kyc}&tagged=${tag_status}&status=${status}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Business Activities
export const businessActivities = createAsyncThunk(
  "businessActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`business-activities`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Address
export const Address = createAsyncThunk(
  "Address",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`registered_address`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Create Address
export const createAddress = createAsyncThunk(
  "createAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`address`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// employment pass
export const employmentPass = createAsyncThunk(
  "employmentPass",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`employment-details`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Employment Support
export const employmentSupport = createAsyncThunk(
  "employmentSupport",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`companies/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);

// Company Data
export const companyData = createAsyncThunk(
  "companyData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-data`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Company
export const CreateCompany = createAsyncThunk(
  "CreateCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`companies`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update Company
export const updateCompany = createAsyncThunk(
  "updateCompany",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`companies/${id}`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Director / Shareholder
export const DeleteDirectorShareholder = createAsyncThunk(
  "DeleteDirectorShareholder",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`users/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Existing Users
export const existingUsers = createAsyncThunk(
  "existingUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`existing-users`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Director / Shareholder
export const addDirectorShareholder = createAsyncThunk(
  "addDirectorShareholder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`users`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Nominee Directors Details
export const getNomineeDirectorsDetails = createAsyncThunk(
  "getNomineeDirectorsDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=nominee_director`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Corporate Secretary
export const getCorporateSecretary = createAsyncThunk(
  "corporate_secretary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=corporate_secretary`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get Company By ID
export const getCompanyById = createAsyncThunk(
  "getCompanyById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`companies/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Sales By Company Id
export const getSalesByCompanyId = createAsyncThunk(
  "getSalesByCompanyId",
  async ({ id, sub_type, year, pageLimit, pageCount }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `transactions?company_id=${id}&type=Company&sub_type=${sub_type}&year=${year}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get transactions By ID
export const getTransactionsById = createAsyncThunk(
  "getTransactionsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`transactions/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Archive Customer
export const updateCustomer = createAsyncThunk(
  "updateCustomer",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`customers/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // Company Exist
      .addCase(companyExist.pending, (state) => {
        state.companyExistStatus.loading = true;
      })
      .addCase(companyExist.fulfilled, (state, action) => {
        state.companyExistStatus.loading = false;
        state.companyExistStatus.data = action.payload;
        state.error = false;
      })
      .addCase(companyExist.rejected, (state) => {
        state.companyExistStatus.loading = false;
        state.error = true;
      })
      // companies
      .addCase(companiesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(companiesList.fulfilled, (state, action) => {
        state.loading = false;
        state.companiesListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(companiesList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Business Activities
      .addCase(businessActivities.pending, (state) => {
        state.businessActivitiesStatus.loading = true;
      })
      .addCase(businessActivities.fulfilled, (state, action) => {
        state.businessActivitiesStatus.loading = false;
        state.businessActivitiesStatus.data = action.payload;
        state.error = false;
      })
      .addCase(businessActivities.rejected, (state) => {
        state.businessActivitiesStatus.loading = false;
        state.error = true;
      })
      // Address
      .addCase(Address.pending, (state) => {
        state.loading = true;
      })
      .addCase(Address.fulfilled, (state, action) => {
        state.loading = false;
        state.AddressStatus.data = action.payload;
        state.error = false;
      })
      .addCase(Address.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // employment Pass
      .addCase(employmentPass.pending, (state) => {
        state.loading = true;
      })
      .addCase(employmentPass.fulfilled, (state, action) => {
        state.loading = false;
        state.employmentPassStatus.data = action.payload;
        state.error = false;
      })
      .addCase(employmentPass.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // employment Support
      .addCase(employmentSupport.pending, (state) => {
        state.loading = true;
      })
      .addCase(employmentSupport.fulfilled, (state, action) => {
        state.loading = false;
        state.employmentSupportStatus.data = action.payload;
        state.error = false;
      })
      .addCase(employmentSupport.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Company Data
      .addCase(companyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(companyData.fulfilled, (state, action) => {
        state.loading = false;
        state.companyDataStatus.data = action.payload;
        state.error = false;
      })
      .addCase(companyData.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Company
      .addCase(CreateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.CreateCompanyStatus.data = action.payload;
        state.error = false;
      })
      .addCase(CreateCompany.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update Company
      .addCase(updateCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCompanyStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateCompany.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Director Shareholder
      .addCase(DeleteDirectorShareholder.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteDirectorShareholder.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteDirectorShareholderStatus.data = action.payload;
        state.error = false;
      })
      .addCase(DeleteDirectorShareholder.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Existing Users
      .addCase(existingUsers.pending, (state) => {
        state.existingUsersStatus.loading = true;
      })
      .addCase(existingUsers.fulfilled, (state, action) => {
        state.existingUsersStatus.loading = false;
        state.existingUsersStatus.data = action.payload;
        state.error = false;
      })
      .addCase(existingUsers.rejected, (state) => {
        state.existingUsersStatus.loading = false;
        state.error = true;
      })
      // Add Director Shareholder
      .addCase(addDirectorShareholder.pending, (state) => {
        state.addDirectorShareholderStatus.loading = true;
      })
      .addCase(addDirectorShareholder.fulfilled, (state, action) => {
        state.addDirectorShareholderStatus.loading = false;
        state.addDirectorShareholderStatus.data = action.payload;
        state.error = false;
      })
      .addCase(addDirectorShareholder.rejected, (state) => {
        state.addDirectorShareholderStatus.loading = false;
        state.error = true;
      })
      // Get Nominee Directors Details
      .addCase(getNomineeDirectorsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNomineeDirectorsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.getNomineeDirectorsDetailsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getNomineeDirectorsDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // get Corporate Secretary Details
      .addCase(getCorporateSecretary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCorporateSecretary.fulfilled, (state, action) => {
        state.loading = false;
        state.getCorporateSecretaryStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCorporateSecretary.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // get Company By Id
      .addCase(getCompanyById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyById.fulfilled, (state, action) => {
        state.loading = false;
        state.getCompanyByIdStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCompanyById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Sales By Company Id
      .addCase(getSalesByCompanyId.pending, (state) => {
        state.getSalesByCompanyIdStatus.loading = true;
      })
      .addCase(getSalesByCompanyId.fulfilled, (state, action) => {
        state.getSalesByCompanyIdStatus.loading = false;
        state.getSalesByCompanyIdStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getSalesByCompanyId.rejected, (state) => {
        state.getSalesByCompanyIdStatus.loading = false;
        state.error = true;
      })
      // Update Customer
      .addCase(updateCustomer.pending, (state) => {
        state.updateCustomerStatus.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.updateCustomerStatus.loading = false;
        state.updateCustomerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.updateCustomerStatus.loading = false;
        state.error = true;
      })
      // Get transactions By ID
      .addCase(getTransactionsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTransactionsById.fulfilled, (state, action) => {
        state.loading = false;
        state.getTransactionsByIdStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getTransactionsById.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = companiesSlice;
export default reducer;
