import React, { useContext, useEffect, useState } from "react";
import {
  ArrowForward,
  BackArrow,
  Dash,
  Increment,
  Info,
  RoundInfo,
} from "../../../../assets/svg/AllSvg";
import CompanyContext from "../CompanyContext";
import { useDispatch, useSelector } from "react-redux";
import {
  employmentPass,
  employmentSupport,
} from "../../../../redux/companies/slice";
import ButtonLoader from "../../../../commons/ButtonLoader";
import { Logo } from "../../../../assets/image";

const Accounting = ({ companyByIdData, companyId }) => {
  const [epCount, setEpCount] = useState(1);
  const [locCount, setLocCount] = useState(1);
  const [isSupportNeeded, setIsSupportNeeded] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { prev, next } = useContext(CompanyContext);

  useEffect(() => {
    dispatch(employmentPass());
    dispatch(employmentSupport());
  }, [dispatch]);

  const { EmploymentPass } = useSelector((store) => ({
    EmploymentPass: store?.companiesData?.employmentPassStatus?.data?.data,
  }));

  const handleEpIncrement = () => {
    setEpCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };

  const handleEpDecrement = () => {
    setEpCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  const handleLocIncrement = () => {
    setLocCount((prevCount) => (prevCount < 9 ? prevCount + 1 : prevCount));
  };

  const handleLocDecrement = () => {
    setLocCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  useEffect(() => {
    setIsSupportNeeded(companyByIdData?.employment_support ?? false);
    setEpCount(companyByIdData?.no_of_eps ?? 0);
    setLocCount(companyByIdData?.no_of_locs ?? 0);
  }, [companyByIdData]);

  const handleSaveAndProceed = () => {
    setLoading(true);
    if (isSupportNeeded) {
      dispatch(
        employmentSupport({
          id: companyId,
          data: {
            employment_support: isSupportNeeded,
            no_of_eps: epCount,
            no_of_locs: locCount,
          },
        })
      )
        .then(() => {
          setLoading(false);
          next();
        })
        .catch((error) => {
          console.error("Error dispatching employmentSupport:", error);
        });
    } else {
      dispatch(
        employmentSupport({
          id: companyId,
          data: {
            employment_support: isSupportNeeded,
            no_of_eps: 0,
            no_of_locs: 0,
          },
        })
      )
        .then(() => {
          console.log("API call succeeded, proceeding to next step");
          next();
        })
        .catch((error) => {
          console.error("Error dispatching employmentSupport:", error);
        });
    }
  };

  return (
    <div className=" bg-white h-auto">
      <div className="pb-2 border-b">
        <p className="font-poppins sm:text-sm text-xs">One Last Thing!</p>
        <p className="font-ebGaramond font-normal lg:text-xl sm:text-base text:sm">
          Do you or anyone else in your company need employment visa support
          from us?
        </p>
      </div>
      <div className="w-full grid lg:grid-cols-2 grid-cols-1 sm:gap-2 gap-3 my-4">
        <button
          className={`border px-2 rounded-xl ${
            isSupportNeeded ? "bg-lightPrimaryShades" : ""
          }`}
          onClick={() => setIsSupportNeeded(true)}
        >
          <div className="w-full flex items-center space-x-2 py-2">
            <img className="sm:h-14 sm:w-14 w-10 h-10" src={Logo} alt="logo" />
            <p className="font-poppins sm:text-base text-sm font-normal text-start">
              Yes! I will need EiBIZ support on employment
            </p>
          </div>
        </button>
        <button
          className={`border px-4 py-2 rounded-xl ${
            !isSupportNeeded ? "bg-lightPrimaryShades" : ""
          }`}
          onClick={() => setIsSupportNeeded(false)}
        >
          <p className="font-poppins sm:text-base text-sm font-normal  text-start">
            No! <br />
            Not at the moment
          </p>
        </button>
      </div>

      {isSupportNeeded === true && (
        <>
          <div className="w-full grid sm:grid-cols-2 sm:gap-2 gap-3">
            <div className="flex items-start space-x-2">
              <RoundInfo
                size="24"
                className="fill-lightInfo  sm:max-w-6  w-full max-w-5 text-white"
              />
              <div className="ml-2">
                <p className="font-poppins font-semibold lg:text-base text-xs">
                  Employment Pass (EP)
                </p>
                <p className="font-poppins font-normal lg:text-sm text-xs">
                  EP allows an employer to hire a foreigner in Singapore. It is
                  the most common type of work visa.
                </p>
              </div>
            </div>
            <div className="ml-2">
              <p className="font-poppins font-semibold  lg:text-base text-xs">
                Dependent Pass (DP)
              </p>
              <p className="font-poppins font-normal lg:text-sm text-xs">
                DP allows the spouse and children of an EP holder to stay in
                Singapore.
              </p>
            </div>
          </div>

          <p className="font-poppins sm:text-sm text-xs font-normal text-start my-3">
            What kind of employment visa support will you need?
          </p>

          <div className="lg:grid lg:grid-cols-2 gap-5">
            <div className="my-4 flex">
              <div>
                <label
                  htmlFor="quantity-input"
                  className="block mb-1 sm:text-sm text-xs font-normal text-gray-900"
                >
                  Number of EPs
                </label>
                <div>
                  <div className="flex items-center max-w-[8rem]">
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={handleEpDecrement}
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                    >
                      <Dash />
                    </button>
                    <input
                      type="text"
                      id="quantity-input"
                      aria-describedby="helper-text-explanation"
                      className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                      value={epCount}
                      readOnly
                    />
                    <button
                      type="button"
                      id="increment-button"
                      onClick={handleEpIncrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                    >
                      <Increment />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-end ml-6">
                <p
                  id="helper-text-explanation"
                  className="mt-2 lg:text-base text-sm text-gray-500"
                >
                  / person
                </p>
                <p
                  id="helper-text-explanation"
                  className="text-base text-black"
                >
                  {EmploymentPass && (
                    <p className="font-poppins lg:text-base text-sm font-light">
                      ${EmploymentPass?.employment_pass}
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className="my-4 flex">
              <div>
                <label
                  htmlFor="quantity-input"
                  className="block mb-1 text-sm font-normal text-gray-900"
                >
                  Number of LOC’s
                </label>
                <div>
                  <div className="flex items-center max-w-[8rem]">
                    <button
                      type="button"
                      id="decrement-button"
                      onClick={handleLocDecrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-l-lg px-3 h-11"
                    >
                      <Dash />
                    </button>
                    <input
                      type="text"
                      id="quantity-input"
                      aria-describedby="helper-text-explanation"
                      className="bg-lightPrimary2 h-11 text-center text-theme text-sm block w-full "
                      value={locCount}
                      readOnly
                    />
                    <button
                      type="button"
                      id="increment-button"
                      onClick={handleLocIncrement} // Change this line
                      className="bg-lightPrimary2 hover:bg-bgPrimary rounded-r-lg px-3 h-11"
                    >
                      <Increment />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-end items-end ml-6">
                <p
                  id="helper-text-explanation"
                  className="mt-2 lg:text-base text-sm text-gray-500"
                >
                  / person
                </p>
                <p
                  id="helper-text-explanation"
                  className="text-base text-black"
                >
                  {EmploymentPass && (
                    <p className="font-poppins text-base font-light">
                      ${EmploymentPass?.dependant_pass_loc}
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <p className="border" />

      {/* buttons */}
      <div className="flex mt-4 justify-end gap-3">
        <button
          type="button"
          className="btn-outline flex items-center sm:!px-6 !px-2 space-x-1 uppercase sm:mb-0"
          onClick={() => prev()}
        >
          <BackArrow
            className="sm:w-4 sm:h-4 w-3 h-3 fill-theme"
            fillOpacity={1}
          />
          <span>Back</span>
        </button>
        <button
          onClick={handleSaveAndProceed}
          disabled={loading}
          className="btn-theme flex items-center sm:!px-6 !px-2 whitespace-nowrap space-x-1 uppercase"
        >
          <span>{loading ? "loading.." : "Save & Proceed On"}</span>
          <ArrowForward className="sm:w-4 sm:h-4 w-3 h-3" />
        </button>
      </div>
    </div>
  );
};

export default Accounting;
