import axios from "axios";

const baseURL = process.env.REACT_APP_BASEURL;
const supportBoardBaseURL = process.env.REACT_APP_SUPPORT_BOARD_URL;

export const AxiosApi = axios.create({
  baseURL,
});

export const supportBoardApi = axios.create({
  baseURL: supportBoardBaseURL,
});

if (sessionStorage.getItem("accessToken")) {
  AxiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${sessionStorage.getItem("accessToken")}`;
  // supportBoardApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
