import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Customer List
export const getCustomerList = createAsyncThunk(
  "getCustomerList",
  async (
    {
      search,
      status,
      pageLimit,
      pageCount,
      tag_status,
      sort_by,
      kyc,
      sort_direction,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `customers?search=${search}&tagged=${
          tag_status ?? ""
        }&status=${status}&kyc=${
          kyc ?? ""
        }&page=${pageCount}&limit=${pageLimit}&sort_by=${sort_by}&sort_direction=${sort_direction}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get customer
export const getCustomer = createAsyncThunk(
  "getCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`/customers/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Customer
export const createCustomer = createAsyncThunk(
  "createCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`customers`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create assign company
export const assignCompany = createAsyncThunk(
  "assignCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`assign-company`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update customer Status
export const updateCustomerStatus = createAsyncThunk(
  "updateCustomerStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`customers/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get all Company
export const getAllCompany = createAsyncThunk(
  "getAllCompany",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`companies`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Unassign Company
export const DeleteUnassignCompany = createAsyncThunk(
  "DeleteUnassignCompany",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`unassign-company/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Upload Documents
export const uploadDocuments = createAsyncThunk(
  "uploadDocuments",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`customer/documents`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Documents
export const getDocuments = createAsyncThunk(
  "getDocuments",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `customer/documents?customer_id=${id}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete documents
export const DeleteDocuments = createAsyncThunk(
  "DeleteDocuments",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`customer/documents/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const customerSlice = createSlice({
  name: "customers",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Customer List
      .addCase(getCustomerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomerListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCustomerList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get customer
      .addCase(getCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.getCustomerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getCustomer.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Edit customers
      .addCase(createCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.createCustomerStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createCustomer.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Edit customers
      .addCase(assignCompany.pending, (state) => {
        state.loading = true;
      })
      .addCase(assignCompany.fulfilled, (state, action) => {
        state.loading = false;
        state.assignCompanyStatus.data = action.payload;
        state.error = false;
      })
      .addCase(assignCompany.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update customer Status
      .addCase(updateCustomerStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomerStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateCustomerStatusStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateCustomerStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // get all Company
      .addCase(getAllCompany.pending, (state) => {
        state.getAllCompanyStatus.loading = true;
      })
      .addCase(getAllCompany.fulfilled, (state, action) => {
        state.getAllCompanyStatus.loading = false;
        state.getAllCompanyStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getAllCompany.rejected, (state) => {
        state.getAllCompanyStatus.loading = false;
        state.error = true;
      })
      // Delete Unassign Company
      .addCase(DeleteUnassignCompany.pending, (state) => {
        state.deleteUnassignCompanyStatus.loading = true;
      })
      .addCase(DeleteUnassignCompany.fulfilled, (state, action) => {
        state.deleteUnassignCompanyStatus.loading = false;
        state.deleteUnassignCompanyStatus.data = action.payload;
        state.error = false;
      })
      .addCase(DeleteUnassignCompany.rejected, (state) => {
        state.deleteUnassignCompanyStatus.loading = false;
        state.error = true;
      })
      // Upload Documents
      .addCase(uploadDocuments.pending, (state) => {
        state.uploadDocumentsStatus.loading = true;
      })
      .addCase(uploadDocuments.fulfilled, (state, action) => {
        state.uploadDocumentsStatus.loading = false;
        state.uploadDocumentsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(uploadDocuments.rejected, (state) => {
        state.deleteUnassignCompanyStatus.loading = false;
        state.error = true;
      })
      // Get Documents
      .addCase(getDocuments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.getDocumentsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getDocuments.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Documents
      .addCase(DeleteDocuments.pending, (state) => {
        state.deleteDocumentsStatus.loading = true;
      })
      .addCase(DeleteDocuments.fulfilled, (state, action) => {
        state.deleteDocumentsStatus.loading = false;
        state.deleteDocumentsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(DeleteDocuments.rejected, (state) => {
        state.deleteDocumentsStatus.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = customerSlice;
export default reducer;
