import React from "react";
import { FileUpload } from "../assets/svg/AllSvg";

const FileUploadChoose = ({ handleFileChange, className }) => {
  return (
    <>
      <div
        className={`relative flex flex-col items-center justify-center border-2 border-dashed cursor-pointer rounded-2xl overflow-hidden px-4 sm:py-8 py-5 ${className}`}
      >
        <input
          className="absolute top-0 left-0 w-full h-full opacity-0"
          type="file"
          onChange={handleFileChange}
          accept=".jpeg,.jpg,.png,.pdf"
        />
        <FileUpload className="sm:w-15 sm:h-15 w-13 h-13 mb-4" />
        <p className="responsive_base_text mb-2">
          Drag and Drop or{" "}
          <span className="text-[#0288d1]">choose your file</span> for upload
        </p>
        <p className="text-light_grey responsive_base_text">
          jpeg, jpg, pdf, png (max. 25mb)
        </p>
      </div>
    </>
  );
};

export default FileUploadChoose;
