import React, { useState } from "react";
import { Badge, InputWithLabel } from "../../../components/micro";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createFile } from "../../../redux/folder&documents/slice";
import { useDispatch } from "react-redux";

const AddFolderModal = ({
  isOpen,
  onClose,
  companyByIdData,
  folderName,
  folderId,
  onCreate,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      folderName: folderName || "",
    },
    validationSchema: Yup.object({
      folderName: Yup.string().required("Folder name is required"),
    }),
    onSubmit: async (values) => {
      const payload = {
        name: values.folderName,
        company_id: companyByIdData?.id,
      };

      setLoading(true);

      try {
        if (folderId) {
          await dispatch(
            createFile({
              id: folderId,
              ...payload,
            })
          );
          onSuccess();
        } else {
          await dispatch(createFile(payload));
        }
        formik.resetForm();
        onCreate();
      } catch (error) {
        console.error("Error creating folder:", error);
      } finally {
        setLoading(false);
        onClose();
      }
    },
    enableReinitialize: true,
  });

  if (!isOpen) return null;

  return (
    <div className="model">
      <div className="bg-white flex flex-col justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative">
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          {folderId ? "Edit Folder" : "New Folder"}
        </h2>
        <div className="flex space-x-3 mb-5">
          <p className="text-xl font-ebGaramond text-start">
            {companyByIdData?.name}
          </p>
          <Badge
            className={`text-white border-success bg-success font-medium text-sm !py-1`}
            value={"Live"}
          />
        </div>
        <p className="text-m font-ebGaramond text-start text-theme mb-2">
          {folderId ? "" : "Add New Folder"}
        </p>

        <form onSubmit={formik.handleSubmit}>
          <InputWithLabel
            label={"Folder’s Name"}
            type={"text"}
            className="border"
            idFromName="folderName"
            onChange={formik.handleChange}
            value={formik.values.folderName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.folderName && formik.errors.folderName && (
            <div className="text-red-600 text-sm">
              {formik.errors.folderName}
            </div>
          )}

          <div className="flex w-full mt-5">
            <button
              type="button"
              className="btn-outline mr-2 w-full"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`w-full ${
                !formik.isValid || formik.values.folderName.trim() === ""
                  ? "bg-gray-400"
                  : "btn-theme"
              }`}
              disabled={
                !formik.isValid ||
                formik.values.folderName.trim() === "" ||
                loading
              }
            >
              {loading ? "Loading.." : folderId ? "Save" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddFolderModal;
