import React, { useState } from "react";
import { CheckBox, InputWithLabel } from "../../../../components/micro";
import ReactSelect from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  addDirectorShareholder,
  getCompanyById,
} from "../../../../redux/companies/slice";
import { toast } from "react-toastify";

const DirectorField = ({
  element,
  getDirectorData,
  setShowDirectorsPopup,
  currentUserId,
  selectedShareholder,
  selectedCorporate,
  editType,
  companyId,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const citizenshipOptions = [
    { label: "Singaporean", value: "singaporean" },
    { label: "Permanent Resident", value: "permanent_resident" },
    { label: "Foreigners", value: "foreigners" },
  ];

  const identityVerificationStatusOptions = [
    ...(editType === "IndividualShareholders"
      ? [{ label: "Shareholder", value: "shareholder" }]
      : []),
    ...(editType === "CorporateShareholders"
      ? [{ label: "Shareholder", value: "shareholder" }]
      : []),
    ...(editType === "IndividualShareholders" ||
    editType === "CorporateShareholders"
      ? []
      : [
          { label: "Nominee Directors", value: "nominee_director" },
          { label: "Resident Directors", value: "resident_director" },
        ]),
  ];

  const kycStatusOptions = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  const shareholderType = [
    { label: "Individual", value: "individual" },
    { label: "Corporate", value: "corporate" },
  ];

  const initialValues = {
    full_name:
      selectedShareholder?.user?.full_name ||
      selectedCorporate?.user?.full_name ||
      element?.user?.full_name ||
      "",

    citizenship:
      citizenshipOptions.find(
        (option) =>
          option.value === selectedShareholder?.citizenship ||
          selectedCorporate?.citizenship ||
          element?.citizenship
      ) || null,

    mobile:
      selectedShareholder?.user?.phone ||
      selectedCorporate?.user?.phone ||
      element?.user?.phone ||
      "",

    email:
      selectedShareholder?.user?.email ||
      selectedCorporate?.user?.email ||
      element?.user?.email ||
      "",

    identity_verification_status:
      identityVerificationStatusOptions.find(
        (option) =>
          option.value === selectedShareholder?.type ||
          selectedCorporate?.type ||
          element?.status
      ) || null,

    kyc_status:
      kycStatusOptions.find(
        (option) =>
          option.value === selectedShareholder?.user?.kyc_status ||
          selectedCorporate?.user?.kyc_status ||
          element?.user?.kyc_status
      ) || null,

    main_signee:
      selectedShareholder?.main_signee ||
      selectedCorporate?.main_signee ||
      element?.main_signee ||
      false,

    type: selectedShareholder?.shareholder_type
      ? {
          label: selectedShareholder.shareholder_type,
          value: selectedShareholder.shareholder_type,
        }
      : selectedCorporate?.shareholder_type
      ? {
          label: selectedCorporate.shareholder_type,
          value: selectedCorporate.shareholder_type,
        }
      : element?.type
      ? { label: element.type, value: element.type }
      : null,
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full Name is required"),
    citizenship: Yup.object().required("Citizenship is required"),
    mobile: Yup.string().required("Mobile Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    identity_verification_status: Yup.object().required(
      "Verification status is required"
    ),
    kyc_status: Yup.object().required("KYC status is required"),
    main_signee: Yup.boolean(),
  });

  const handleSubmit = async (values) => {
    const payload = {
      company_id: getDirectorData[0]?.company_id,
      type: values.identity_verification_status.value,
      shareholder_type: values.type.value,
      email: values.email,
      full_name: values.full_name,
      citizenship: values.citizenship.value,
      phone: values.mobile,
      status: values.status,
      kyc_status: values.kyc_status.value,
      main_signee: values.main_signee,
    };
    setLoading(true);
    try {
      const res = currentUserId
        ? await dispatch(
            addDirectorShareholder({ id: currentUserId, ...payload })
          )
        : await dispatch(addDirectorShareholder(payload));

      if (res.type === "addDirectorShareholder/fulfilled") {
        toast.success(res.payload.message);
        if (companyId) {
          dispatch(getCompanyById(companyId));
        }
        setShowDirectorsPopup(false);
      }
    } catch (error) {
      toast.error("Failed to update director.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <div className="space-y-4">
            <InputWithLabel
              className="border border-secondary_grey"
              idFromName="full_name"
              label="Full Name"
              type="text"
              value={values.full_name}
              onChange={(e) => setFieldValue("full_name", e.target.value)}
            />
            <ReactSelect
              className={"text-black p-0"}
              id="citizenship"
              placeholder="Citizenship"
              options={citizenshipOptions}
              isLoading={false}
              getOptionValue={(citizenship) => citizenship.value}
              value={values.citizenship}
              onChange={(e) => setFieldValue("citizenship", e)}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #0000003B`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            {errors.citizenship && touched.citizenship && (
              <div className="text-red-500">{errors.citizenship.label}</div>
            )}

            <InputWithLabel
              className="border border-secondary_grey"
              idFromName="mobile"
              label="Mobile"
              type="text"
              value={values.mobile}
              onChange={(e) => setFieldValue("mobile", e.target.value)}
            />
            <InputWithLabel
              className="border border-secondary_grey"
              idFromName="email"
              label="Email"
              type="email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
            />
            <ReactSelect
              className={"text-black p-0"}
              id="identity_verification_status"
              placeholder="Type"
              options={identityVerificationStatusOptions}
              isLoading={false}
              getOptionValue={(status) => status.value}
              value={values.identity_verification_status}
              onChange={(e) => setFieldValue("identity_verification_status", e)}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #0000003B`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            {errors.identity_verification_status &&
              touched.identity_verification_status && (
                <div className="text-red-500">
                  {errors.identity_verification_status.label}
                </div>
              )}

            <ReactSelect
              className={"text-black p-0"}
              id="kyc_status"
              placeholder="KYC Status"
              options={kycStatusOptions}
              isLoading={false}
              getOptionValue={(kyc_status) => kyc_status.value}
              value={values.kyc_status}
              onChange={(e) => setFieldValue("kyc_status", e)}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #0000003B`,
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #0000003B",
                  },
                }),
              }}
            />
            {errors.kyc_status && touched.kyc_status && (
              <div className="text-red-500">{errors.kyc_status.label}</div>
            )}
            <ReactSelect
              className={"text-black p-0"}
              id="shareholderType"
              placeholder="Shareholder Type"
              options={shareholderType}
              value={values?.type}
              onChange={(e) => setFieldValue("type", e)}
              styles={{
                control: (base) => ({
                  ...base,
                  border: "1px solid #0000003B",
                  borderRadius: "0.25rem",
                  padding: "6px 4px",
                  boxShadow: "none",
                }),
              }}
            />
            {errors.type && touched.type && (
              <div className="text-red-500">{errors.type.label}</div>
            )}

            {!editType === "IndividualShareholders" &&
              editType ===
                "CorporateShareholders"(
                  <CheckBox
                    idFrom={"main_signee"}
                    label={"Main Signee"}
                    name={"main_signee"}
                    value={values.main_signee}
                    checked={values.main_signee}
                    onChange={(e) =>
                      setFieldValue("main_signee", e.target.checked)
                    }
                  />
                )}
            <div className="flex gap-4 w-full pt-3">
              <button
                type="button"
                className="btn-outline w-full uppercase"
                onClick={() => {
                  setShowDirectorsPopup(false);
                }}
              >
                Cancel
              </button>
              <button type="submit" className="btn-theme w-full uppercase">
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DirectorField;
