import React from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import SalesBreakdownList from "./components/SalesBreakdownList";
import SalesRevenueGraph from "./components/SalesRevenueGraph";

const SalesReport = () => {
  return (
    <div className="space-y-5">
      <div>
        <h6 className="font-ebGaramond font-semibold text-2xl mb-3">Reports</h6>
        <Breadcrumb
          breadCrumbParent={"Home"}
          breadCrumbParent2="Reports"
          breadCrumbActive={"Sales Report"}
          breadCrumbTitleKey={"/"}
          classes=""
        />
      </div>
      <SalesRevenueGraph />
      <SalesBreakdownList />
    </div>
  );
};

export default SalesReport;
