import React, { useContext, useEffect, useState } from "react";
import AccountDetails from "./AccountDetails";
import { AddButton } from "../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import {
  assignCompany,
  DeleteUnassignCompany,
  getAllCompany,
  getCustomer,
} from "../../../redux/customer/slice";
import RippleLoader from "../../../commons/RippleLoader";
import CustomerContext from "./CustomerContext";
import { InputError } from "../../../commons/MicroComponents";

const AccountInformation = ({
  getCustomerData,
  isLoading,
  createCustomerId,
}) => {
  const [accountDetailsList, setAccountDetailsList] = useState([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isAddedError, setIsAddedError] = useState(false);
  const { prev, next } = useContext(CustomerContext);

  const dispatch = useDispatch();

  const GetCompany = useSelector(
    (store) => store?.customerData?.getAllCompanyStatus?.data?.data
  );
  const loading = useSelector(
    (store) => store?.customerData?.getAllCompanyStatus?.loading
  );

  // Fetch companies
  useEffect(() => {
    if (!GetCompany) {
      dispatch(getAllCompany());
    }
  }, [dispatch, GetCompany]);

  useEffect(() => {
    if (getCustomerData?.accountInfo?.length) {
      setAccountDetailsList(getCustomerData?.accountInfo);
    }
  }, [getCustomerData]);

  // Remove Account
  const removeAccount = (index, id) => {
    if (id) {
      setIsDeleteLoading(true);
      dispatch(DeleteUnassignCompany(id)).then((res) => {
        setIsDeleteLoading(false);
        if (res.type === "DeleteUnassignCompany/fulfilled") {
          setAccountDetailsList([
            ...accountDetailsList.slice(0, index),
            ...accountDetailsList.slice(index + 1),
          ]);
          dispatch(getCustomer(getCustomerData?.id));
        }
      });
    } else {
      setAccountDetailsList([
        ...accountDetailsList.slice(0, index),
        ...accountDetailsList.slice(index + 1),
      ]);
    }
  };
  // Update Account Details
  const updateAccountDetailsList = (
    index,
    company_name,
    company_role,
    system_role
  ) => {
    const updatedAccount = accountDetailsList.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          company_id: company_name,
          company_role: company_role ?? "",
          system_role: system_role,
        };
      }
      return item;
    });
    setAccountDetailsList(updatedAccount);
  };

  // Handle Next Button Click
  const handleNextClick = () => {
    if (!accountDetailsList?.length) {
      setIsAddedError(true);
      return;
    } else if (
      !accountDetailsList[0]?.company_id &&
      !accountDetailsList[0]?.company_role &&
      !accountDetailsList[0]?.system_role
    ) {
      setIsAddedError(true);
      return;
    }
    const payload = {
      id: getCustomerData?.id ?? createCustomerId,
      companies: accountDetailsList.map((account) => ({
        ...account,
        company_id: account.company_id,
        company_role: account.company_role,
        system_role: account.system_role,
      })),
    };

    dispatch(assignCompany(payload)).then((res) => {
      if (res.type === "assignCompany/fulfilled") {
        if (getCustomerData?.id) {
          dispatch(getCustomer(getCustomerData?.id));
        } else if (createCustomerId) {
          dispatch(getCustomer(createCustomerId));
        }
        next();
      } else {
        console.error("Error assigning company:", res);
      }
    });
  };

  return (
    <>
      {isLoading || loading || isDeleteLoading ? (
        <RippleLoader />
      ) : (
        <>
          {isAddedError && (
            <InputError
              classes={"mb-2"}
              errorTitle={"Please Add Account Details"}
            />
          )}
          <div className="space-y-5 mb-5">
            {accountDetailsList?.map((element, index) => {
              return (
                <AccountDetails
                  element={element}
                  className={"bg-greyBg hover:bg-light_secondary_shades"}
                  index={index}
                  removeAccount={removeAccount}
                  updateAccountDetailsList={updateAccountDetailsList}
                  GetCompany={GetCompany}
                  loading={loading}
                />
              );
            })}
          </div>
          <div className="flex justify-center py-3">
            <AddButton
              value="Company"
              onClick={(e) => {
                setAccountDetailsList([
                  ...accountDetailsList,
                  {
                    company_id: "",
                    company_role: "",
                    system_role: "",
                  },
                ]);
              }}
            />
          </div>
          <div className="flex justify-end gap-4 pt-3">
            <button
              type="button"
              className="btn-outline uppercase"
              onClick={() => prev()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn-theme uppercase"
              onClick={handleNextClick}
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default AccountInformation;
