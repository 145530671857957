import React from "react";
import { Badge, InputWithLabel } from "../../../components/micro";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createDocument,
  updateDocument,
} from "../../../redux/folder&documents/slice";
import { useDispatch } from "react-redux";

const EditDocument = ({
  isOpen,
  onClose,
  companyByIdData,
  documentName,
  documentId,
  onSuccess,
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      documentName: documentName || "",
    },
    validationSchema: Yup.object({
      documentName: Yup.string().required("Document name is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.documentName,
      };
      dispatch(updateDocument({ id: documentId, data: payload })).then(
        (res) => {
          if (res.type === "updateDocument/fulfilled") {
            formik.resetForm();
            onSuccess();
            onClose();
          }
        }
      );
    },
    enableReinitialize: true,
  });

  if (!isOpen) return null;

  return (
    <div className="model">
      <div className="bg-white flex flex-col justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative">
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Rename File
        </h2>
        {companyByIdData?.name ||
          (companyByIdData && (
            <div className="flex space-x-3 mb-5">
              <p className="text-xl font-ebGaramond capitalize text-start">
                {companyByIdData?.name
                  ? companyByIdData?.name
                  : companyByIdData}
              </p>
              <Badge
                className={`text-white border-success bg-success font-medium text-sm !py-1`}
                value={"Live"}
              />
            </div>
          ))}

        <form onSubmit={formik.handleSubmit}>
          <InputWithLabel
            label={"File’s Name"}
            type={"text"}
            className="border"
            idFromName="documentName"
            onChange={formik.handleChange}
            value={formik.values.documentName}
            onBlur={formik.handleBlur}
            errors={formik.touched.documentName && formik.errors.documentName}
            errorsText={formik.errors.documentName}
          />

          <div className="flex w-full mt-5">
            <button
              type="button"
              className="btn-outline mr-2 w-full"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`w-full ${
                !formik.isValid || formik.values.documentName.trim() === ""
                  ? "btn-outline-disabled"
                  : "btn-theme"
              }`}
              disabled={
                !formik.isValid || formik.values.documentName.trim() === ""
              }
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDocument;
