import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// get Activity Log List
export const getActivityLogList = createAsyncThunk(
  "getActivityLogList",
  async (
    { search, pageLimit, pageCount, sort_by, sort_direction },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `activity_logs?search=${search}&sort_by=${sort_by}&sort_direction=${sort_direction}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const activityLogSlice = createSlice({
  name: "activity-log",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Tickets List
      .addCase(getActivityLogList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityLogList.fulfilled, (state, action) => {
        state.loading = false;
        state.getActivityLogListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getActivityLogList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { reducer } = activityLogSlice;
export default reducer;
