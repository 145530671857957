import React, { useState } from "react";
import { Form, Formik } from "formik";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Toggles from "../../../commons/Toggles";
import { InputError } from "../../../commons/MicroComponents";
import { updateMasterSetting } from "../../../redux/masterSetting/slice";
import { useDispatch } from "react-redux";
import ConfirmationModel from "../../../commons/ConfirmationModel";

const OpenBankAccountForm = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: value,
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/service-settings");
      }
    });
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          traditional_bank_1:
            masterSettingData?.detail?.traditional_bank_1 ?? "",
          digital_wallet_1: masterSettingData?.detail?.digital_wallet_1 ?? "",
          duration: masterSettingData?.durations ?? "",
          request_free: masterSettingData?.detail?.free_request ?? "",
          no_of_free_request:
            masterSettingData?.detail?.no_of_free_request ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          traditional_bank_1: Yup.string().required(
            "Please Enter 1 Traditional Bank Price"
          ),
          digital_wallet_1: Yup.string().required(
            "Please Enter 1 Digital Wallet Price"
          ),
          duration: Yup.string().required(
            "Please Enter Duration (No. of Days)"
          ),
          remarks: Yup.string().required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-4 sm:gap-5 responsive_base_text gap-4 items-center">
              {/* 1 Traditional Bank */}
              <label
                className={`sm:col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="gst_annual_filing"
              >
                1 Traditional Bank
              </label>
              <div className="sm:col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"traditional_bank_1"}
                  className={
                    errors.traditional_bank_1 && touched.traditional_bank_1
                      ? ` input-error `
                      : ` border `
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  value={values.traditional_bank_1}
                  errors={
                    errors.traditional_bank_1 && touched.traditional_bank_1
                  }
                  errorsText={errors.traditional_bank_1}
                />
              </div>
              {/* 1 Digital Wallet */}
              <label
                className={`sm:col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="gst_annual_filing"
              >
                1 Digital Wallet
              </label>
              <div className="sm:col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"digital_wallet_1"}
                  className={
                    errors.digital_wallet_1 && touched.digital_wallet_1
                      ? ` input-error `
                      : ` border `
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  value={values.digital_wallet_1}
                  errors={errors.digital_wallet_1 && touched.digital_wallet_1}
                  errorsText={errors.digital_wallet_1}
                />
              </div>
              <div className="sm:col-start-2 sm:col-span-3">
                {/* Duration (No. of Days) */}
                <InputWithLabel
                  className={
                    errors.duration && touched.duration
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="duration"
                  label="Duration (No. of Days)"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  value={values.duration}
                  errors={errors.duration && touched.duration}
                  errorsText={errors.duration}
                />
              </div>
              {/* Provide Free Request (Check Box) */}
              <div className="flex flex-col justify-center">
                <div className="flex items-center space-x-3">
                  <Toggles
                    checked={values.request_free}
                    value={values.request_free}
                    toggleFor={"request_tree"}
                    disabled={!isEdit}
                    onChange={(e) => {
                      setFieldValue("request_free", e.target.checked);
                    }}
                  />
                  <p className="text-light_grey">Provide Free Request</p>
                </div>
                {errors.request_free && (
                  <InputError errorTitle={errors.request_free} />
                )}
              </div>
              {values.request_free && (
                <div>
                  {/* No. of Request Free */}
                  <InputWithLabel
                    label={"No. of Request Free"}
                    type={"number"}
                    idFromName={"no_of_free_request"}
                    className={
                      errors.no_of_free_request && touched.no_of_free_request
                        ? ` input-error `
                        : ` border `
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.no_of_free_request}
                    disabled={!isEdit}
                    inputClass={!isEdit ? "text-gray-400" : ""}
                    errors={
                      errors.no_of_free_request && touched.no_of_free_request
                    }
                    errorsText={errors.no_of_free_request}
                  />
                </div>
              )}
              {/* Remarks */}
              <div className="sm:col-span-4">
                <TextareaWithLabel
                  className={
                    errors.remarks && touched.remarks ? `input-error` : `border`
                  }
                  idFromName="remarks"
                  label="Remarks"
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remarks}
                  errors={errors.remarks && touched.remarks}
                  errorsText={errors.remarks}
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn uppercase"
                onClick={() => {
                  navigate("/service-settings");
                }}
              >
                Cancel
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>

      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default OpenBankAccountForm;
