import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi, supportBoardApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// Create Chat
export const createChat = createAsyncThunk(
  "createChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`chats`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Update Chat
export const updateChat = createAsyncThunk(
  "updateChat",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`chats/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Chat
export const deleteChat = createAsyncThunk(
  "deleteChat",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`chats/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Chat Users
export const getChatUser = createAsyncThunk(
  "getChatUser",
  async (search, { rejectWithValue }) => {
    try {
      const response = await supportBoardApi.get(`chat-users?search=${search}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // Create File
      .addCase(createChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.loading = false;
        state.createChatStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createChat.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Upload File
      .addCase(updateChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateChat.fulfilled, (state, action) => {
        state.loading = false;
        state.updateChatStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateChat.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Chat
      .addCase(deleteChat.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteChat.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteChatStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteChat.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Chat Users
      .addCase(getChatUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getChatUser.fulfilled, (state, action) => {
        state.loading = false;
        state.getChatUserStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getChatUser.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { reducer } = chatSlice;
export default reducer;
