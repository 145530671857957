import React from "react";
import { BackArrow } from "../assets/svg/AllSvg";

const BackButton = ({
  className,
  onClick,
  text,
  icon,
  iconClass,
  fillOpacity,
}) => {
  return (
    <div
      className={`flex items-center space-x-3 ${className}`}
      onClick={onClick}
    >
      <BackArrow className={iconClass} fillOpacity={fillOpacity} />
      <div className="flex items-center space-x-2">
        {icon}
        <p>{text}</p>
      </div>
    </div>
  );
};

export default BackButton;
