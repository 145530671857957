import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Badge,
  RadioButton,
  TextareaWithLabel,
} from "../../../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../commons/ButtonLoader";
import {
  getCompanyById,
  updateCompany,
} from "../../../../redux/companies/slice";
import { toast } from "react-toastify";
import { InputError } from "../../../../commons/MicroComponents";
import Objectives from "../../createEditCompany/components/Objectives";

const AboutCompany = ({
  setCompanyAboutPopup,
  CompanyData,
  CompanyInfoData,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [countryArray, setCountryArray] = useState([]);
  const [objectivesError, setObjectivesError] = useState("");
  const [objectivesSelectedArray, setObjectivesSelectedArray] = useState({});

  // Selector
  const { createCompanyData, countryData } = useSelector((store) => ({
    // Create Company
    createCompanyData: store?.companiesData?.CreateCompanyStatus?.data?.data,
    countryData: store?.addressData?.getCountryDataStatus?.data,
  }));

  // set create Company Data
  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({ ...x, label: x.name }));
      setCountryArray(data);
    }
  }, [createCompanyData, CompanyData, countryData]);

  // Get Initial Values
  const getInitialValues = () => {
    let initial = {
      platforms: createCompanyData?.platform_id ?? "",
      other_platform: createCompanyData?.other_platform ?? "",
      objectives: objectivesSelectedArray ?? "",
      objectives_other: objectivesSelectedArray?.objectives_other ?? "",
    };
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const payload = {
        platform_id: parseInt(values.platforms) || null,
        other_platform: values.other_platform || "",
        objectives: Array.isArray(
          objectivesSelectedArray.objectivesSelectedArray
        )
          ? objectivesSelectedArray.objectivesSelectedArray.map((id) =>
              parseInt(id)
            )
          : [],
        objectives_other: objectivesSelectedArray.objectives_other || "",
      };

      setLoading(true);
      dispatch(updateCompany({ id: CompanyData?.id, data: payload }))
        .then((res) => {
          if (res.type === "updateCompany/fulfilled") {
            dispatch(getCompanyById(CompanyData?.id));
            toast.success(res?.payload?.message);
            setCompanyAboutPopup(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div
      className="model"
      onClick={() => {
        setCompanyAboutPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-6 px-6 mx-auto relative scroll-auto h-4/5 overflow-y-auto"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Sources for their company’s fund
        </h2>
        <div className="flex items-center justify-start w-full gap-3 mb-3">
          <p className="text-xl font-ebGaramond font-semibold">
            Company Pte Ltd
          </p>
          <Badge
            className="text-white border-success bg-success font-medium text-sm capitalize inline-block !py-1"
            value="Live"
          />
        </div>
        <div className="w-full h-full flex-grow">
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-3 border-b border-secondary_grey pb-5 mb-5">
              <p> Let us know more about you</p>
              <p className="text-sm">This helps us serve you better</p>
              <div className="space-y-2 px-3">
                <p>What are your next steps or objectives?</p>
                {objectivesError && <InputError errorTitle={objectivesError} />}
                <Objectives
                  objectivesData={CompanyInfoData?.objectives}
                  setObjectivesSelectedArray={setObjectivesSelectedArray}
                  setObjectivesError={setObjectivesError}
                  objectivesVales={createCompanyData?.objectives}
                  objectives_other={createCompanyData?.objectives_other}
                />
              </div>
              <div className="space-y-2 px-3">
                <p>How did you find out about us?</p>
                {CompanyInfoData?.platforms?.map((element) => {
                  return (
                    <RadioButton
                      label={element.title}
                      idFrom={element.title}
                      value={element.id}
                      checked={formik.values.platforms == element.id}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      name={"platforms"}
                    />
                  );
                })}
                {formik.values.platforms == 1 && (
                  <TextareaWithLabel
                    idFromName={"other_platform"}
                    label={"Other Platform"}
                    className={"border"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.other_platform}
                  />
                )}

                {formik.errors.platforms && formik.touched.platforms ? (
                  <InputError errorTitle={formik.errors.platforms} />
                ) : null}
              </div>
            </div>
            <div className="flex gap-4 py-3 w-full">
              <button
                type="button"
                className="btn-outline flex items-center space-x-3 uppercase w-full justify-center"
                onClick={() => setCompanyAboutPopup(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn-theme flex items-center space-x-3 uppercase w-full justify-center"
                disabled={loading}
              >
                {loading ? <ButtonLoader /> : <span>Save</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
