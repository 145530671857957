import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Badge, RadioButton } from "../../../../components/micro";
import Cryptocurrency from "../../createEditCompany/components/Cryptocurrency";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../../../../commons/ButtonLoader";
import {
  getCompanyById,
  updateCompany,
} from "../../../../redux/companies/slice";
import { toast } from "react-toastify";

const CryptocurrencyDeclaration = ({
  setCryptocurrencyDeclarationPopup,
  CompanyData,
  CompanyInfoData,
}) => {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [businessActivitiesArray, setBusinessActivitiesArray] = useState([]);
  const [
    companyCryptoActivitiesSelectedArray,
    setCompanyCryptoActivitiesSelectedArray,
  ] = useState({});
  const [cryptoCurrenciesSelectedArray, setCryptoCurrenciesSelectedArray] =
    useState([]);
  const [companyTypesArray, setCompanyTypesArray] = useState([]);
  const [cryptoCurrencyRadio, setCryptoCurrencyRadio] = useState(false);
  const [defaultPrimaryActivity, setDefaultPrimaryActivity] = useState("");
  const [defaultSecondaryActivity, setDefaultSecondaryActivity] = useState("");
  const [defaultCompanyType, setDefaultCompanyType] = useState("");
  const [businessManagesError, setBusinessManagesError] = useState("");
  const [currenciesError, setCurrenciesError] = useState("");
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [companyId, setCompanyId] = useState();
  const dispatch = useDispatch();

  // Selector
  const { createCompanyData } = useSelector((store) => ({
    // Create Company
    createCompanyData: store?.companiesData?.CreateCompanyStatus?.data?.data,
  }));

  // set create Company Data
  useEffect(() => {
    if (createCompanyData?.id) {
      setCompanyName(createCompanyData?.name);
      setCryptoCurrencyRadio(createCompanyData?.crypto_currency);
      const ba = businessActivitiesArray.filter(
        (e) => e?.id == createCompanyData?.primary_activity
      );
      ba?.map((e) => {
        setDefaultPrimaryActivity(e);
      });
      const pa = businessActivitiesArray.filter(
        (e) => e?.id == createCompanyData?.secondary_activity
      );
      pa?.map((e) => {
        setDefaultSecondaryActivity(e);
      });
      const ct = companyTypesArray.filter(
        (e) => e?.id == createCompanyData?.company_type_id
      );
      ct?.map((e) => {
        setDefaultCompanyType(e);
      });
      const countriesDefaultArray = createCompanyData?.countries?.map((e) => ({
        label: e?.name,
        ...e,
      }));
      setDefaultCountries(countriesDefaultArray);
    }
  }, [createCompanyData, CompanyData]);

  // Get Initial Values
  const getInitialValues = () => {
    let initial = {
      primary_activity: defaultPrimaryActivity ?? "",
      describe_business_activity:
        createCompanyData?.primary_business_activity ?? "",
      company_type: defaultCompanyType ?? "",
      secondary_activity: defaultSecondaryActivity ?? "",
      cryptocurrency: createCompanyData?.crypto_currency ? "yes" : "no",
      countries: defaultCountries ?? "",
      company_name: companyName,
      interested_d_o: createCompanyData?.interested_d_o === 1 ? true : false,
      approved_licenses: "",
      platforms: createCompanyData?.platform_id ?? "",
      isVerify: createCompanyData?.name ? true : false,
      license_mas: createCompanyData?.license_mas ? "yes" : "no",
      related_crypto_blockchain: createCompanyData?.related_crypto_blockchain
        ? "yes"
        : "no",
      about_us_other: createCompanyData?.about_us_other ?? "",
    };
    return initial;
  };

  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(),
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values) => {
      const payload = {
        crypto_currency: values.cryptocurrency === "yes",
        crypto_currencies: cryptoCurrenciesSelectedArray.map((id) =>
          parseInt(id)
        ),
        license_mas: values.license_mas === "yes",
        related_crypto_blockchain: values.related_crypto_blockchain === "yes",
        company_crypto_activities:
          companyCryptoActivitiesSelectedArray?.companyCryptoActivitiesSelectedArray,
        company_crypto_activities_other:
          companyCryptoActivitiesSelectedArray?.company_crypto_activities_other,
      };
      setLoading(true);
      try {
        const res = await dispatch(
          updateCompany({ id: CompanyData?.id, data: payload })
        );
        if (res.type === "updateCompany/fulfilled") {
          dispatch(getCompanyById(CompanyData?.id));
          toast.success(res?.payload?.message);
          setCryptocurrencyDeclarationPopup(false);
        }
      } catch (error) {
        toast.error(error?.payload?.message);
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <div
      className="model"
      onClick={() => {
        setCryptocurrencyDeclarationPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col drop-shadow-lg rounded-lg w-full max-w-xl min-h-40 py-6 px-6 mx-auto relative scroll-auto h-4/5 overflow-y-auto overflow-x-hidden"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Cryptocurrency Declaration
        </h2>
        <div className="flex items-center justify-start w-full gap-3 mb-3">
          <p className="text-xl font-ebGaramond font-semibold">
            Company Pte Ltd
          </p>
          <Badge
            className="text-white border-success bg-success font-medium text-sm capitalize inline-block !py-1"
            value="Live"
          />
        </div>
        <div className="flex flex-col flex-grow">
          <form
            onSubmit={formik.handleSubmit}
            className="flex-grow flex flex-col"
          >
            <div className="space-y-3 flex-grow">
              {/* Cryptocurrency Declaration */}
              <p>Cryptocurrency Declaration</p>
              <p className="text-sm">
                Is your business involved in cryptocurrency?
              </p>
              <RadioButton
                label="No"
                idFrom="no"
                value="no"
                checked={formik.values.cryptocurrency === "no"}
                onChange={formik.handleChange}
                name="cryptocurrency"
              />
              <RadioButton
                label="Yes"
                idFrom="yes"
                value="yes"
                checked={formik.values.cryptocurrency === "yes"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="cryptocurrency"
              />
            </div>
            {formik.values.cryptocurrency === "yes" && (
              <Cryptocurrency
                cryptoCurrenciesData={CompanyInfoData.crypto_currencies}
                setCryptoCurrenciesSelectedArray={
                  setCryptoCurrenciesSelectedArray
                }
                formikOne={formik}
                companyCryptoActivitiesSelectedArray={
                  companyCryptoActivitiesSelectedArray
                }
                setCompanyCryptoActivitiesSelectedArray={
                  setCompanyCryptoActivitiesSelectedArray
                }
                currenciesError={currenciesError}
                setCurrenciesError={setCurrenciesError}
                businessManagesError={businessManagesError}
                setBusinessManagesError={setBusinessManagesError}
                cryptoCurrenciesValues={createCompanyData?.crypto_currencies}
                cryptoActivitiesValues={createCompanyData?.crypto_activities}
                company_crypto_activities_other={
                  createCompanyData?.company_crypto_activities_other
                }
              />
            )}
            <div className="flex gap-4 py-3 w-full">
              <button
                type="button"
                className="btn-outline flex items-center space-x-3 uppercase w-full justify-center"
                onClick={() => setCryptocurrencyDeclarationPopup(false)}
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="btn-theme flex items-center space-x-3 uppercase w-full justify-center"
                disabled={loading}
              >
                {loading ? <ButtonLoader /> : <span>Save</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CryptocurrencyDeclaration;
