import React, { useEffect, useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import DebounceSearch from "../../commons/DebounceSearch";
import ReactSelect from "react-select";
import {
  ArchiveIcon,
  DeleteIcon,
  EmailUnreadIcon,
  MarkEmailReadIcon,
  MarkEmailUnreadIcon,
  MarkUnreadMailbox,
  Plus,
} from "../../assets/svg/AllSvg";
import AllTickets from "./components/AllTickets";
import CreateQuickTicket from "./createQuickTicket/CreateQuickTicket";
import ReplyTicket from "./replyTicket/ReplyTicket";
import { useDispatch, useSelector } from "react-redux";
import { getTicketsList } from "../../redux/ticket/slice";
import { getStaffsList } from "../../redux/staffs/slice";
import RippleLoader from "../../commons/RippleLoader";
import DataNotFound from "../../commons/DataNotFound";
import Pagination from "../../commons/Pagination";
import TableShowLimit from "../../commons/TableShowLimit";
import { useLocation } from "react-router-dom";

const Tickets = () => {
  const [selectedPriority, setSelectedPriority] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [createQuickTicketModel, setCreateQuickTicketModel] = useState(false);
  const [replyTicketModel, setReplyTicketModel] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [ticketData, setTicketData] = useState({});
  const [activeTab, setActiveTab] = useState("All Tickets");
  const dispatch = useDispatch();
  const location = useLocation();
  const dashboardTicket = location?.state?.ticketDetails;

  // Show Dashboard Ticket
  useEffect(() => {
    if (dashboardTicket?.id) {
      setTicketData(dashboardTicket);
      setReplyTicketModel(true);
    }
  }, [dashboardTicket]);

  // selector
  const ticketList = useSelector(
    (store) => store?.ticketData?.getTicketsListStatus?.data?.data
  );
  const ticketListLoading = useSelector((store) => store?.ticketData?.loading);
  const staffsList = useSelector(
    (store) => store?.staffsData?.getStaffsListStatus?.data?.data
  );
  const staffsListLoading = useSelector((store) => store?.staffsData?.loading);
  const getProfileData = useSelector(
    (store) => store?.authData?.adminGetProfileDataStatus?.data
  );

  useEffect(() => {
    dispatch(
      getTicketsList({
        search: searchValue,
        priority: selectedPriority?.value ?? "",
        period: selectedPeriod?.value ?? "",
        pageCount: pageCount,
        pageLimit: pageLimit,
        status:
          activeTab === "New"
            ? "new"
            : activeTab === "On-Going"
            ? "on_going"
            : activeTab === "Resolved"
            ? "resolved"
            : activeTab === "Archived"
            ? "archived"
            : activeTab === "Deleted"
            ? "deleted"
            : "",
      })
    );
  }, [
    dispatch,
    searchValue,
    selectedPriority,
    selectedPeriod,
    pageCount,
    pageLimit,
    activeTab,
  ]);

  // Get Customer and Staff APIs
  useEffect(() => {
    if (
      (getProfileData?.role === "super_admin" ||
        getProfileData?.role === "admin") &&
      !staffsList
    ) {
      dispatch(
        getStaffsList({
          search: "",
          pageCount: "",
          pageLimit: "all",
          status: "",
          sort_by: "",
          sort_direction: "",
          role: "",
        })
      );
    }
  }, [dispatch, staffsList]);

  const tab = [
    {
      id: 0,
      name: "All Tickets",
      count:
        (ticketList?.counts?.new ? ticketList?.counts?.new : 0) +
        (ticketList?.counts?.on_going ? ticketList?.counts?.on_going : 0) +
        (ticketList?.counts?.resolved ? ticketList?.counts?.resolved : 0) +
        (ticketList?.counts?.archived ? ticketList?.counts?.archived : 0) +
        (ticketList?.counts?.de ? ticketList?.counts?.deleted : 0),
    },
    {
      id: 1,
      name: "New",
      count: ticketList?.counts?.new ?? 0,
    },
    {
      id: 2,
      name: "On-Going",
      count: ticketList?.counts?.on_going ?? 0,
    },
    {
      id: 3,
      name: "Resolved",
      count: ticketList?.counts?.resolved ?? 0,
    },
    {
      id: 4,
      name: "Archived",
      count: ticketList?.counts?.archived ?? 0,
    },
    {
      id: 5,
      name: "Deleted",
      count: ticketList?.counts?.deleted ?? 0,
    },
  ];

  return (
    <>
      <div className="space-y-5">
        <div>
          <h6 className="font-ebGaramond font-semibold sm:text-2xl text-xl sm:mb-3 mb-2">
            Tickets
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Tickets"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <div className="w-full bg-white border rounded sm:space-y-4 space-y-3 sm:py-5 py-3 sm:px-6 px-4">
          <div className="flex items-center justify-between">
            <p className="font-ebGaramond lg:text-2xl text-xl ">
              EI Biz Tickets
            </p>
            <button
              className="btn-theme flex items-center uppercase"
              onClick={() => {
                setCreateQuickTicketModel(true);
              }}
            >
              <Plus className="mr-2" /> New Ticket
            </button>
          </div>
          <div className="md:flex justify-between gap-2 lg:space-x-2 md:space-y-0 space-y-2">
            {/* Search */}
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
            {/* filters */}
            <div className="flex md:max-w-xs w-full gap-3">
              <ReactSelect
                className={
                  "w-full sm:max-w-xs text-black responsive_base_text p-0"
                }
                id="priorityDropdown"
                placeholder="Priority"
                options={[
                  {
                    label: "Low Priority",
                    value: "low",
                  },
                  {
                    label: "Mid Priority",
                    value: "mid",
                  },
                  {
                    label: "High Priority",
                    value: "high",
                  },
                  {
                    label: "All",
                    value: "",
                  },
                ]}
                isLoading={false}
                getOptionValue={(selectedPriority) => selectedPriority.value}
                value={selectedPriority}
                onChange={(e) => {
                  setSelectedPriority(e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #cdddeb`,
                    borderRadius: "0.3rem",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #cdddeb",
                    },
                  }),
                }}
              />
              <ReactSelect
                className={
                  "w-full sm:max-w-md text-black responsive_base_text p-0"
                }
                id="period"
                placeholder="Period"
                options={[
                  { label: "This Week", value: "this_week" },
                  { label: "This Month", value: "this_month" },
                  { label: "All", value: "all" },
                ]}
                isLoading={false}
                getOptionValue={(selectedPeriod) => selectedPeriod.value}
                value={selectedPeriod}
                onChange={(e) => {
                  setSelectedPeriod(e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #cdddeb`,
                    borderRadius: "0.3rem",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #cdddeb",
                    },
                  }),
                }}
              />
            </div>
          </div>
          {/* Tab */}
          <div className={`flex flex-wrap border-b`}>
            {tab.map((tab, index) => (
              <button
                key={index}
                className={`py-2 px-4 capitalize group flex items-center border-b-2 transition duration-300 responsive_base_text gap-2 ${
                  activeTab === tab.name
                    ? "border-theme text-theme"
                    : "border-transparent hover:text-theme text-light_grey hover:border-theme op"
                }`}
                onClick={() => setActiveTab(tab.name)}
              >
                {tab.name === "All Tickets" ? (
                  <MarkUnreadMailbox
                    className="lg:w-5 w-4"
                    fillOpacity={activeTab === "All Tickets" ? 1 : null}
                    fillClass={
                      activeTab === "All Tickets"
                        ? "fill-theme"
                        : "transition duration-300 group-hover:fill-theme group-hover:opacity-100"
                    }
                  />
                ) : tab.name === "New" ? (
                  <MarkEmailUnreadIcon
                    className="lg:w-5 w-4"
                    fillOpacity={activeTab === "New" ? 1 : null}
                    fillClass={
                      activeTab === "New"
                        ? "fill-theme"
                        : "transition duration-300 group-hover:fill-theme group-hover:opacity-100"
                    }
                  />
                ) : tab.name === "On-Going" ? (
                  <EmailUnreadIcon
                    className="lg:w-5 w-4"
                    fillOpacity={activeTab === "On-Going" ? 1 : null}
                    fillClass={
                      activeTab === "On-Going"
                        ? "fill-theme"
                        : "transition duration-300 group-hover:fill-theme group-hover:opacity-100"
                    }
                  />
                ) : tab.name === "Resolved" ? (
                  <MarkEmailReadIcon
                    className="lg:w-5 w-4"
                    fillOpacity={activeTab === "Resolved" ? 1 : null}
                    fillClass={
                      activeTab === "Resolved"
                        ? "fill-theme"
                        : "transition duration-300 group-hover:fill-theme group-hover:opacity-100"
                    }
                  />
                ) : tab.name === "Archived" ? (
                  <ArchiveIcon
                    className="lg:w-5 w-4"
                    fillOpacity={activeTab === "Archived" ? 1 : null}
                    fillClass={
                      activeTab === "Archived"
                        ? "fill-theme"
                        : "transition duration-300 group-hover:fill-theme group-hover:opacity-100"
                    }
                  />
                ) : (
                  <DeleteIcon
                    className={`lg:w-5 w-4 ${
                      activeTab === "Deleted"
                        ? "fill-theme opacityx-100"
                        : "transition duration-300 group-hover:fill-theme opacity-[0.58] group-hover:opacity-100"
                    }`}
                  />
                )}
                {tab.name}
                <p>{tab.count}</p>
              </button>
            ))}
          </div>
          {/* List Of Tickets */}
          {ticketListLoading ? (
            <RippleLoader />
          ) : (
            <>
              {ticketList?.tickets?.data?.length ? (
                <>
                  {ticketList?.tickets?.data?.map((element, index) => {
                    return (
                      <AllTickets
                        element={element}
                        staffsList={staffsList}
                        staffsListLoading={staffsListLoading}
                        setReplyTicketModel={setReplyTicketModel}
                        setTicketData={setTicketData}
                        searchValue={searchValue}
                        selectedPriority={selectedPriority}
                        selectedPeriod={selectedPeriod}
                        pageCount={pageCount}
                        pageLimit={pageLimit}
                        listStatus={
                          activeTab === "New"
                            ? "new"
                            : activeTab === "On-Going"
                            ? "on_going"
                            : activeTab === "Resolved"
                            ? "resolved"
                            : activeTab === "Archived"
                            ? "archived"
                            : activeTab === "deleted"
                            ? "deleted"
                            : ""
                        }
                      />
                    );
                  })}
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}
          {/* Pagination */}
          {ticketList?.tickets ? (
            <div className="flex flex-wrap items-center justify-end sm:my-2 my-1">
              <TableShowLimit
                paginationCurrentLimit={pageLimit}
                setPaginationCurrentLimit={setPageLimit}
              />
              <p className="text-xs">
                {(ticketList?.tickets?.current_page - 1) *
                  ticketList?.tickets?.per_page +
                  1}{" "}
                -{" "}
                {(ticketList?.tickets?.current_page - 1) *
                  ticketList?.tickets?.per_page +
                  ticketList?.tickets?.data?.length}{" "}
                of {ticketList?.tickets?.total}
              </p>
              <div className="px-4 py-2 rounded-lg">
                <Pagination
                  total={ticketList?.tickets?.total}
                  current={pageCount}
                  pagination={(crPage) => setPageCount(crPage)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Create Quick Ticket Popup */}
      {createQuickTicketModel && (
        <CreateQuickTicket
          setCreateQuickTicketModel={setCreateQuickTicketModel}
          searchValue={searchValue}
          selectedPriority={selectedPriority}
          selectedPeriod={selectedPeriod}
          pageCount={pageCount}
          pageLimit={pageLimit}
          status={
            activeTab === "New"
              ? "new"
              : activeTab === "On-Going"
              ? "on_going"
              : activeTab === "Resolved"
              ? "resolved"
              : activeTab === "Archived"
              ? "archived"
              : activeTab === "deleted"
              ? "deleted"
              : ""
          }
        />
      )}
      {/* Reply Ticket Popup */}
      {replyTicketModel && (
        <ReplyTicket
          setReplyTicketModel={setReplyTicketModel}
          ticketData={ticketData}
          searchValue={searchValue}
          selectedPriority={selectedPriority}
          selectedPeriod={selectedPeriod}
          pageCount={pageCount}
          pageLimit={pageLimit}
          status={
            activeTab === "New"
              ? "new"
              : activeTab === "On-Going"
              ? "on_going"
              : activeTab === "Resolved"
              ? "resolved"
              : activeTab === "Archived"
              ? "archived"
              : activeTab === "deleted"
              ? "Deleted"
              : ""
          }
        />
      )}
    </>
  );
};

export default Tickets;
