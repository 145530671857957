import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Plus } from "../assets/svg/AllSvg";

const CreateSalesButton = () => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  const toggling = () => setOpen(!open);
  useEffect(() => {
    function handler(event) {
      if (!cancelButtonRef.current?.contains(event.target)) {
        setOpen(false);
      }
    }
    window.addEventListener("click", handler);
    return () => window.removeEventListener("click", handler);
  }, []);
  return (
    <>
      <div
        className="relative cursor-pointer"
        ref={cancelButtonRef}
        onClick={() => toggling()}
      >
        <button className="btn-theme flex items-center uppercase">
          <Plus className="mr-2" /> Create Sales
        </button>
        {open && (
          <>
            <ul className="absolute top-[calc(100%+0.4rem)] min-w-[10rem] right-0 bg-white shadow-md rounded-lg overflow-auto z-10">
              <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/create-service-request");
                }}
              >
                Service Request
              </li>
              <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/create-subscription");
                }}
              >
                Subscription
              </li>
              <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() => {
                  navigate("/create-both");
                }}
              >
                Both
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default CreateSalesButton;
