import React, { useState, useRef } from "react";
import ReactSelect from "react-select";
import {
  Bold,
  Link,
  More,
  PDF,
  Redo,
  Underline,
  Undo,
  Upload,
} from "../assets/svg/AllSvg";

const TextEditor = ({ isOpen, replyTicketModel, setMessage }) => {
  const [image, setImage] = useState([]);
  const [pdf, setPdf] = useState([]);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const [menuOpenPdf, setMenuOpenPdf] = useState(null);
  const contentEditableRef = useRef(null);
  const modalRef = useRef(null);

  const execCommand = (command) => {
    document.execCommand(command, false, null);
  };

  const handleFormat = (command, value = null) => {
    document.execCommand(command, false, value);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage((prevImages) => [...prevImages, reader.result]);
    };

    if (file) reader.readAsDataURL(file);
  };

  const removeImage = (index) => {
    setImage((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const removePdf = (index) => {
    setPdf((prevPdf) => prevPdf.filter((_, i) => i !== index));
  };

  const editImage = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage((prevImages) =>
          prevImages.map((img, i) => (i === index ? reader.result : img))
        );
      };
      if (file) reader.readAsDataURL(file);
    };
    fileInput.click();
  };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPdf = {
        name: file.name,
        date: new Date().toLocaleString(),
      };
      setPdf((prevPdf) => [...prevPdf, newPdf]);
    }
  };

  const handleOpenImageMenu = (index) => {
    setMenuOpenIndex(index);
  };

  const handleOpenPdfMenu = (index) => {
    setMenuOpenPdf(index);
  };

  const handleSubmit = () => {
    if (contentEditableRef.current) {
      const messageContent = contentEditableRef.current.innerHTML;
      setMessage(messageContent);
    }
  };

  return (
    <div className="py-4">
      {replyTicketModel ? (
        <div className="flex flex-wrap items-center justify-between mb-2">
          <div className="flex items-center bg-blue-50 justify-between rounded-md">
            <div className="flex">
              <div>
                <button
                  onClick={() => execCommand("bold")}
                  className="p-2  rounded"
                >
                  <Bold />
                </button>
                <button
                  onClick={() => execCommand("underline")}
                  className="p-2  rounded"
                >
                  <Underline />
                </button>
              </div>

              <div className="flex">
                <button
                  onClick={() =>
                    handleFormat("createLink", prompt("Enter URL:"))
                  }
                  className="p-2  rounded"
                >
                  <Link />
                </button>
                <label className="p-2 cursor-pointer rounded">
                  <Upload />

                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="hidden"
                  />
                </label>
                <label className="p-2 cursor-pointer rounded">
                  <Upload />

                  <input
                    type="file"
                    accept="application/pdf"
                    onChange={handlePdfUpload}
                    className="hidden"
                  />
                </label>
              </div>
            </div>

            <div className="ml-16">
              <button
                onClick={() => handleFormat("undo")}
                className="p-2  rounded"
              >
                <Undo />
              </button>
              <button
                onClick={() => handleFormat("redo")}
                className="p-2  rounded"
              >
                <Redo />
              </button>
            </div>
          </div>
          <div className="items-center justify-center inline-flex gap-2">
            <ReactSelect
              className={"text-black p-0"}
              id="priorityDropdown"
              placeholder="Priority Status"
              options={[
                {
                  label: "Low Priority",
                  value: "low_priority",
                },
                {
                  label: "Mid Priority",
                  value: "mid_priority",
                },
                {
                  label: "High Priority",
                  value: "high_priority",
                },
              ]}
              isLoading={false}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={"text-black p-0"}
              id="ticket"
              placeholder="Ticket Status"
              options={[
                { label: "On-Going", value: "on_going" },
                { label: "Resolved", value: "resolved" },
              ]}
              isLoading={false}
              // getOptionValue={(selectedPeriod) => selectedPeriod.value}
              // value={selectedPeriod}
              // onChange={(e) => {
              //   setSelectedPeriod(e);
              // }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
        </div>
      ) : (
        <div className="mb-2 flex space-x-2 bg-blue-50 justify-between rounded-md">
          <div className="flex">
            <div>
              <button
                onClick={() => execCommand("bold")}
                className="p-2  rounded"
              >
                <Bold />
              </button>
              <button
                onClick={() => execCommand("underline")}
                className="p-2  rounded"
              >
                <Underline />
              </button>
            </div>

            <div className="flex">
              <button
                onClick={() => handleFormat("createLink", prompt("Enter URL:"))}
                className="p-2  rounded"
              >
                <Link />
              </button>
              <label className="p-2 cursor-pointer rounded">
                <Upload />

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="hidden"
                />
              </label>
              <label className="p-2 cursor-pointer rounded">
                <Upload />

                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handlePdfUpload}
                  className="hidden"
                />
              </label>
            </div>
          </div>

          <div>
            <button
              onClick={() => handleFormat("undo")}
              className="p-2  rounded"
            >
              <Undo />
            </button>
            <button
              onClick={() => handleFormat("redo")}
              className="p-2  rounded"
            >
              <Redo />
            </button>
          </div>
        </div>
      )}
      <div
        ref={contentEditableRef}
        onInput={() => setMessage(contentEditableRef.current.innerHTML)}
        contentEditable="true"
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          minHeight: "200px",
        }}
        placeholder="Type a message..."
      >
        {image.map((imgSrc, index) => (
          <div key={index} className="relative mb-2 max-w-sm" ref={modalRef}>
            <img
              src={imgSrc}
              alt={`Uploaded ${index}`}
              className="max-h-min max-w-xs"
            />
            <button
              className="absolute top-0 right-16"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenImageMenu(index);
              }}
              contentEditable={false}
            >
              <More />
              {menuOpenIndex === index && (
                <div className="absolute top-0 left-12 bg-white border rounded-md shadow-lg py-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      editImage(index);
                    }}
                    className="px-2 hover:bg-gray-300 text-black p-1 w-full justify-start text-start text-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      removeImage(index);
                    }}
                    className="px-2 hover:bg-gray-300 text-black p-1 w-full justify-start text-start text-sm"
                  >
                    Remove
                  </button>
                </div>
              )}
            </button>
          </div>
        ))}
        {pdf.map((pdf, index) => (
          <div
            key={index}
            className="relative mb-2 max-w-64 text-center p-4 rounded-xl shadow-md border"
          >
            <div className="flex text-center items-center justify-between">
              <PDF />
              <span className="text-black text-sm">{pdf.name}</span>
              <button
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenPdfMenu(index);
                }}
                contentEditable={false}
              >
                <More />
                {menuOpenPdf === index && (
                  <div className="absolute top-0 -right-20 bg-white border rounded-md shadow-lg py-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        removePdf(index);
                      }}
                      className="px-2 hover:bg-gray-300 text-black p-1 w-full justify-start text-start text-sm"
                    >
                      Remove
                    </button>
                  </div>
                )}
              </button>
            </div>
            <div className="flex flex-col">
              <span className="text-gray-500 text-xs">Uploaded on</span>
              <span className="text-gray-500 text-xs">{pdf.date}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TextEditor;
