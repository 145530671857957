import React, { useState } from "react";
import { CheckCircleOutline } from "../../../assets/svg/AllSvg";
import CryptocurrencyDeclaration from "./components/CryptocurrencyDeclaration";
import CompanyFund from "./components/companyFund";
import AboutCompany from "./components/aboutCompany";

const Others = ({ CompanyData, CompanyInfoData, companyByIdData }) => {
  const [cryptocurrencyDeclarationPopup, setCryptocurrencyDeclarationPopup] =
    useState(false);
  const [companyFundPopup, setCompanyFundPopup] = useState(false);
  const [companyAboutPopup, setCompanyAboutPopup] = useState(false);

  return (
    <>
      <div className="space-y-5">
        <div className="bg-light_secondary rounded-xl px-8 py-5">
          <div className="flex justify-between mb-5">
            <p className="text-theme text-xl font-ebGaramond">
              Cryptocurrency Declaration
            </p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCryptocurrencyDeclarationPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Is the business involved in cryptocurrency
              </p>
              <p>{companyByIdData?.crypto_currency === 1 ? "Yes" : "No"}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                How is the business involved in cryptocurrency
              </p>
              {companyByIdData?.crypto_currencies?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.title} </p>
                  </div>
                );
              })}
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Are they licensed by, or will they will be seeking a license
                from, the MAS for cryptocurrency services or digital payment
                taken services or any MAS approved licenses?
              </p>
              <p>{companyByIdData?.license_mas === 1 ? "Yes" : "No"}</p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Is the business related to any existing local and/or foreign
                entities in the cryptocurrency or blockchain industry
              </p>
              <p>
                <p>
                  {companyByIdData?.related_crypto_blockchain === 1
                    ? "Yes"
                    : "No"}
                </p>
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Which cryptocurrency activity is your business involved in?
              </p>
              {companyByIdData?.crypto_activities?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.activity}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="bg-light_secondary_shades rounded-xl px-8 py-5">
          <div className="flex justify-between mb-5">
            <p className="text-theme text-xl font-ebGaramond">
              Source of the company funds
            </p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCompanyFundPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                What attracts them to incorporate their business in Singapore?
              </p>
              {companyByIdData?.attractions?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.title}</p>
                  </div>
                );
              })}
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Sources for their company’s fund
              </p>
              {companyByIdData?.company_funds?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.title}</p>
                  </div>
                );
              })}
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                Countries their business will take place
              </p>
              {companyByIdData?.countries?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="bg-light_secondary rounded-xl px-8 py-5">
          <div className="flex justify-between mb-5">
            <p className="text-theme text-xl font-ebGaramond">
              More about the company
            </p>
            <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCompanyAboutPopup(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="grid grid-cols-2 gap-10">
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                What are their next steps or objectives?
              </p>
              {companyByIdData?.objectives?.map((element) => {
                return (
                  <div className="flex items-center gap-1.5">
                    <CheckCircleOutline
                      pathClass={"fill-light_grey"}
                      width={20}
                      height={20}
                    />
                    <p>{element?.title}</p>
                  </div>
                );
              })}
            </div>
            <div className="space-y-2">
              <p className="text-sm text-light_grey font-semibold">
                How did they find out about EiBIZ?
              </p>
              <p>{companyByIdData?.platform?.title}</p>
            </div>
          </div>
        </div>
      </div>
      {cryptocurrencyDeclarationPopup && (
        <CryptocurrencyDeclaration
          setCryptocurrencyDeclarationPopup={setCryptocurrencyDeclarationPopup}
          CompanyData={CompanyData}
          CompanyInfoData={CompanyInfoData}
        />
      )}
      {companyFundPopup && (
        <CompanyFund
          setCompanyFundPopup={setCompanyFundPopup}
          CompanyData={CompanyData}
          CompanyInfoData={CompanyInfoData}
        />
      )}
      {companyAboutPopup && (
        <AboutCompany
          setCompanyAboutPopup={setCompanyAboutPopup}
          CompanyData={CompanyData}
          CompanyInfoData={CompanyInfoData}
        />
      )}
    </>
  );
};

export default Others;
