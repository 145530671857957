import React, { useEffect, useState } from "react";
import { Badge } from "../../../components/micro";
import { CompaniesIcon, Location, Star } from "../../../assets/svg/AllSvg";
import CompanyKYCStatus from "./CompanyKYCStatus";
import EditDatesToNote from "./EditDatesToNote";
import EditAddressCompanyActivities from "./EditAddressCompanyActivities";
import EditCapital from "./EditCapital";
import EditCompanyActivities from "./components/EditCompanyActivities";
import { getCountryData } from "../../../redux/address/slice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import RippleLoader from "../../../commons/RippleLoader";

const CompanyInformation = ({ companyByIdData, onLoading }) => {
  const [companyKYCStatusPopup, setCompanyKYCStatusPopup] = useState(false);
  const [datesToNotePopup, setDatesToNotePopup] = useState(false);
  const [capitalPopup, setCapitalPopup] = useState(false);
  const [editCompanyActivitiesPopup, setEditCompanyActivitiesPopup] =
    useState(false);
  const [addressCompanyActivitiesPopup, setAddressCompanyActivitiesPopup] =
    useState(false);

  const relevantAddress = companyByIdData?.addresses?.find(
    (el) => el.type === "own"
  );

  const companyId = companyByIdData?.id;
  const dispatch = useDispatch();

  const { countryData } = useSelector((store) => ({
    // Country Data
    countryData: store?.addressData?.getCountryDataStatus?.data?.data,
  }));

  const getCountryName = (countryId) => {
    const country = countryData?.find((country) => country.id === countryId);
    return country ? country.name : "Unknown Country";
  };

  useEffect(() => {
    dispatch(getCountryData());
  }, []);

  return (
    <>
      {onLoading ? (
        <RippleLoader />
      ) : (
        <>
          <div className="grid grid-cols-3 gap-5 mb-5">
            <div className="col-span-2 card !rounded-xl !px-6 !py-5">
              <div className="flex items-center space-x-5">
                <CompaniesIcon className="fill-theme w-8 h-8" fillOpacity={1} />
                <div>
                  <div className="flex items-center space-x-3 mb-2">
                    <p className="text-xl font-ebGaramond">
                      {companyByIdData?.name}
                    </p>
                    <Badge
                      className={`${
                        companyByIdData?.status === "active"
                          ? "text-white border-success bg-success"
                          : "text-black bg-secondary_light_shades"
                      } font-medium text-sm capitalize !py-1`}
                      value={
                        companyByIdData?.status === "active"
                          ? "Live"
                          : companyByIdData?.status
                      }
                    />
                  </div>
                  <Badge
                    className={`capitalize font-medium inline-block text-sm !py-1 mb-3 ${
                      companyByIdData?.kyc_status === "low_risk"
                        ? "border-green-700 text-green-700"
                        : companyByIdData?.kyc_status === "mid_risk"
                        ? "border-orange-700 text-orange-700"
                        : companyByIdData?.kyc_status === "high_risk"
                        ? "border-red-700 text-red-700"
                        : companyByIdData?.kyc_status === "pending"
                        ? "text-black bg-secondary_light_shades"
                        : ""
                    }`}
                    value={
                      companyByIdData?.kyc_status === "low_risk"
                        ? "Low Risk"
                        : companyByIdData?.kyc_status === "mid_risk"
                        ? "Mid Risk"
                        : companyByIdData?.kyc_status === "high_risk"
                        ? "High Risk"
                        : companyByIdData?.kyc_status === "pending"
                        ? "Pending"
                        : ""
                    }
                  />
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Registration No.
                  </p>
                  <p className="text-xs mb-3">{companyByIdData?.company_id}</p>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Incorporation Date
                  </p>
                  <p className="text-xs">
                    {moment(companyByIdData?.created_at).format("MM/DD/YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div className="card !rounded-xl !px-6 !py-5">
              <div className="flex items-center space-x-5 h-full">
                <Star className="fill-theme w-8 h-8" />
                <div className="flex flex-col justify-between h-full">
                  <p className="text-xl font-ebGaramond">Subscription</p>
                  <div>
                    <p className="text-xs text-light_grey font-semibold mb-1">
                      Tier
                    </p>
                    <p className="mb-3">
                      {companyByIdData?.subsciption?.duration} year
                    </p>
                  </div>
                  <div>
                    <p className="text-xs text-light_grey font-semibold mb-1">
                      Expiry Date
                    </p>
                    <p>
                      {" "}
                      {moment(companyByIdData?.subsciption?.end_date).format(
                        "MM/DD/YYYY"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-light_secondary rounded-xl px-8">
            <div className="border-b border-secondary_grey py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Company KYC
                </p>
                <button
                  className="text-theme border-b border-theme"
                  onClick={() => {
                    setCompanyKYCStatusPopup(true);
                  }}
                >
                  Edit
                </button>
              </div>
              <p className="text-xs text-light_grey font-semibold mb-2">
                KYC Status
              </p>
              <Badge
                className={`capitalize font-medium inline-block text-sm !py-1 mb-3 ${
                  companyByIdData?.kyc_status === "low_risk"
                    ? "border-green-700 text-green-700"
                    : companyByIdData?.kyc_status === "mid_risk"
                    ? "border-orange-700 text-orange-700"
                    : companyByIdData?.kyc_status === "high_risk"
                    ? "border-red-700 text-red-700"
                    : companyByIdData?.kyc_status === "pending"
                    ? "text-black bg-secondary_light_shades"
                    : ""
                }`}
                value={
                  companyByIdData?.kyc_status === "low_risk"
                    ? "Low Risk"
                    : companyByIdData?.kyc_status === "mid_risk"
                    ? "Mid Risk"
                    : companyByIdData?.kyc_status === "high_risk"
                    ? "High Risk"
                    : companyByIdData?.kyc_status === "pending"
                    ? "Pending"
                    : ""
                }
              />
            </div>
            <div className="border-b border-secondary_grey py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">
                  Dates to note:
                </p>
                <button
                  className="text-theme border-b border-theme"
                  onClick={() => {
                    setDatesToNotePopup(true);
                  }}
                >
                  Edit
                </button>
              </div>
              <div className="grid grid-cols-4">
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Financial Year End
                  </p>
                  <p>
                    {moment(companyByIdData?.financial_year_end_date).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Annual General Meeting (AGM)
                  </p>
                  <p>
                    {" "}
                    {moment(
                      companyByIdData?.annual_general_meeting_date
                    ).format("MM/DD/YYYY")}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Annual Return
                  </p>
                  <p>
                    {" "}
                    {moment(companyByIdData?.annual_return_date).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Tax Return (Form C/C-S)
                  </p>
                  <p>
                    {" "}
                    {moment(companyByIdData?.tax_return_date).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="border-b border-secondary_grey py-5">
              <div className="flex justify-between mb-3">
                <p className="text-theme text-xl font-ebGaramond">Capital</p>
                {/* <button
              className="text-theme border-b border-theme"
              onClick={() => {
                setCapitalPopup(true);
              }}
            >
              Edit
            </button> */}
              </div>
              <div className="grid grid-cols-5">
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Share Name
                  </p>
                  <p>{companyByIdData?.shareholders[0]?.user?.full_name}</p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Total Paid-up Capital
                  </p>
                  <p>{companyByIdData?.shareholders[0]?.paid_up_capital}</p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Total Issued Share Capital
                  </p>
                  <p>
                    {companyByIdData?.shareholders[0]?.issued_share_capital}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Total Number of Shares
                  </p>
                  <p>{companyByIdData?.shareholders[0]?.number_of_shares}</p>
                </div>
                <div>
                  <p className="text-xs text-light_grey font-semibold mb-1">
                    Currency
                  </p>
                  <p>SGD</p>
                </div>
              </div>
            </div>
            <div>
              <div className="py-5 border-b border-secondary_grey">
                <div className="flex justify-between">
                  <div className="flex space-x-2 ">
                    <p className="text-theme text-xl font-ebGaramond">
                      Registered Office Address
                    </p>
                    <Badge
                      value={"EiBIZ Address"}
                      className={"bg-light_green text-white text-sm !py-1"}
                    />
                  </div>
                  <button
                    className="text-theme border-b border-theme"
                    onClick={() => {
                      setAddressCompanyActivitiesPopup(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div className="space-y-2 mt-2">
                  {companyByIdData?.addresses?.map((element) => {
                    return (
                      <div
                        key={element.address_id}
                        className="flex justify-between items-start space-x-4"
                      >
                        <div>
                          <div className="flex items-start space-x-3">
                            <Location />
                            <div className="text-sm space-y-1">
                              <p className="text-xs text-light_grey font-semibold mb-1">
                                {element.sub_type === "office_address"
                                  ? "Registered Address"
                                  : "Business Operational"}
                              </p>
                              <p>{element.address.address_line_1}</p>
                              <p>{element.address.address_line_2}</p>
                              <p>
                                {getCountryName(element.address.country_id)}{" "}
                                {element.address.postal_code}
                              </p>
                            </div>
                          </div>
                          {element.physical_store === 1 && (
                            <div className="mt-2 flex items-start space-x-3">
                              <Location />
                              <div className="text-sm space-y-1">
                                <p className="text-xs text-light_grey font-semibold mb-1">
                                  Physical Store
                                </p>
                                <p>
                                  {
                                    element?.physical_store_address
                                      ?.address_line_1
                                  }
                                </p>
                                <p>
                                  {
                                    element?.physical_store_address
                                      ?.address_line_2
                                  }
                                </p>
                                <p>
                                  {getCountryName(
                                    element?.physical_store_address?.country_id
                                  )}{" "}
                                  {element?.physical_store_address?.postal_code}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="space-y-4 py-5">
                <div className="flex justify-between">
                  <p className="text-theme text-xl font-ebGaramond">
                    Company Activities
                  </p>
                  <button
                    className="text-theme border-b border-theme"
                    onClick={() => {
                      setEditCompanyActivitiesPopup(true);
                    }}
                  >
                    Edit
                  </button>
                </div>
                <div className="max-w-xs space-y-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs text-light_grey font-semibold mb-1">
                        Primary Activity
                      </p>
                      <p>
                        [{companyByIdData?.primary_activity?.code}]{" "}
                        {companyByIdData?.primary_activity?.description}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-light_grey font-semibold mb-1">
                        Description
                      </p>
                      <p className="text-sm">
                        {companyByIdData?.primary_business_activity}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 pt-2">
                    <div>
                      <p className="text-xs text-light_grey font-semibold mb-1">
                        Secondary Activity
                      </p>
                      <p>
                        [{companyByIdData?.secondary_activity?.code}]{" "}
                        {companyByIdData?.secondary_activity?.description}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-light_grey font-semibold mb-1">
                        Description
                      </p>
                      <p className="text-sm">
                        {companyByIdData?.secondary_business_activity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {companyKYCStatusPopup && (
        <CompanyKYCStatus
          companyByIdData={companyByIdData}
          companyId={companyId}
          setCompanyKYCStatusPopup={setCompanyKYCStatusPopup}
        />
      )}
      {datesToNotePopup && (
        <EditDatesToNote
          companyId={companyId}
          setDatesToNotePopup={setDatesToNotePopup}
          companyByIdData={companyByIdData}
        />
      )}
      {addressCompanyActivitiesPopup && (
        <EditAddressCompanyActivities
          companyId={companyId}
          setAddressCompanyActivitiesPopup={setAddressCompanyActivitiesPopup}
          addressCompanyActivitiesPopup={addressCompanyActivitiesPopup}
          companyByIdData={companyByIdData}
          relevantAddress={relevantAddress}
        />
      )}
      {capitalPopup && (
        <EditCapital
          companyId={companyId}
          setCapitalPopup={setCapitalPopup}
          companyByIdData={companyByIdData}
        />
      )}
      {editCompanyActivitiesPopup && (
        <EditCompanyActivities
          companyId={companyId}
          setEditCompanyActivitiesPopup={setEditCompanyActivitiesPopup}
          companyByIdData={companyByIdData}
        />
      )}
    </>
  );
};

export default CompanyInformation;
