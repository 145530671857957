import React, { useEffect, useState } from "react";
import { BusinessBuilding } from "../../../../assets/svg/AllSvg";
import RegisteredOfficeAddress from "./RegisteredOfficeAddress";
import NewRegisteredOfficeAddress from "./NewRegisteredOfficeAddress";
import { Link } from "react-router-dom";

const CompanyAddress = ({
  companyId,
  addresses,
  business_registered_address_fee,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (addresses?.length) {
      setActiveTab(addresses?.length === 1 || !addresses?.length ? 0 : 1);
    }
  }, [addresses]);

  const tabs = [
    {
      text: "Use EiBiz's registered address & Digital Mailroom Service",
      id: 0,
      content: (
        <RegisteredOfficeAddress companyId={companyId} addresses={addresses} />
      ),
      price: business_registered_address_fee,
    },
    {
      text: "Use your own company address",
      link: "Eligibility Criteria",
      linkUrl:
        "https://www.acra.gov.sg/how-to-guides/setting-up-a-local-company/registered-office-address",
      id: 1,
      content: (
        <NewRegisteredOfficeAddress
          companyId={companyId}
          addresses={addresses}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="border-b  pb-2 mb-5">
        <p className="font-poppins sm:text-sm text-xs">Secondly,</p>
        <p className="font-ebGaramond font-normal lg:text-2xl sm:text-xl text-base mb-3">
          We will need to know your Company address
        </p>
      </div>
      {/* tab head */}
      <div className="grid lg:grid-cols-2 lg:gap-5 gap-3   mb-3">
        {tabs?.map((element) => {
          return (
            <div
              className={`p-4 cursor-pointer ${
                activeTab === element?.id ? "bg-secondaryGray" : "bg-white"
              } rounded-lg border-2 space-y-3`}
              onClick={() => {
                setActiveTab(element?.id);
              }}
            >
              <div className="w-full flex gap-3">
                <BusinessBuilding />
                <div className=" w-full">
                  <p className="font-poppins sm:text-sm lg:text-base text-xs font-normal text-start">
                    {element?.text}
                  </p>
                  {element?.price && (
                    <div className="flex justify-between bg-lightPrimaryShades p-2 mt-2">
                      <p className="font-poppins text-xs font-light">Price</p>
                      <div>
                        <p className="font-poppins text-xs font-light">
                          Annual Fees
                        </p>
                        <p className="font-normal">
                          ${business_registered_address_fee}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {element?.link && (
                <Link
                  target="_blank"
                  to={element?.linkUrl}
                  className="text-theme border-b border-theme inline-block text-xs font-poppins"
                >
                  {element?.link}
                </Link>
              )}
            </div>
          );
        })}
      </div>
      {/* tab content */}
      {tabs?.map((element) => activeTab === element?.id && element?.content)}
    </div>
  );
};

export default CompanyAddress;
