import React, { useEffect, useState } from "react";
import { Close } from "../../assets/svg/AllSvg";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createRole,
  getPermissionsList,
  getRoleList,
} from "../../redux/accounts/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../commons/RippleLoader";
import DropdownCheckbox from "../../commons/DropdownCheckbox";
import { toast } from "react-toastify";
import DataNotFound from "../../commons/DataNotFound";
import { CheckBox, InputWithLabel } from "../../components/micro";
import ConfirmationModel from "../../commons/ConfirmationModel";

const AddEditRole = ({
  viewPermissionData,
  setAddEditRolePopup,
  searchValue,
  addSortDirection,
  addSort,
  pageCount,
  pageLimit,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  // selector
  const permissionsList = useSelector(
    (store) => store?.accountsData?.getPermissionsListStatus?.data
  );
  const permissionListLoading = useSelector(
    (store) => store?.accountsData?.loading
  );

  // Permissions List API
  useEffect(() => {
    if (viewPermissionData?.id) {
      dispatch(getPermissionsList(viewPermissionData?.id));
    } else {
      dispatch(getPermissionsList());
    }
  }, [dispatch, viewPermissionData]);

  // Get Permissions List for values
  const getInitialValues = (arr) => {
    let initial = {
      role: viewPermissionData?.title ?? "",
    };
    arr?.map((element) => {
      if (element?.sub_menu?.length) {
        element?.sub_menu?.map((subElement) => {
          subElement?.child_permission?.map((field) => {
            initial[field.name] = field?.assigned;
          });
        });
      } else {
        element?.child_permission?.map((field) => {
          initial[field.name] = field?.assigned;
        });
      }
    });
    return initial;
  };

  // Checkbox validation and Submit Permissions On API
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(permissionsList?.data),
    validationSchema: Yup.object().shape({
      role: Yup.string().min(3).required("Please Enter Role"),
    }),
    onSubmit: (values) => {
      setValues(values);
      setConfirmEdit(true);
      setIsLoading(true);
    },
  });

  // Submit
  const handleSubmit = (values) => {
    // Get Checked Field permissions
    let trueFieldKeys = Object.keys(values).filter((key) => values[key]);
    // remove role form
    trueFieldKeys = trueFieldKeys.filter(function (item) {
      return item !== "role";
    });
    const payload = {
      name: values.role,
      permissions: trueFieldKeys,
    };
    if (viewPermissionData?.id) {
      // Edit Role
      dispatch(createRole({ roleId: viewPermissionData?.id, ...payload })).then(
        (res) => {
          setIsLoading(false);
          if (res?.type === "createRole/fulfilled") {
            toast.success(res?.payload?.message);
            dispatch(
              getRoleList({
                search: searchValue,
                pageCount: pageCount,
                pageLimit: pageLimit,
                sort_by: addSort,
                sort_direction: addSortDirection === "asc" ? "asc" : "desc",
              })
            );
            setAddEditRolePopup(false);
          }
        }
      );
    } else {
      // Add Role
      dispatch(createRole(payload)).then((res) => {
        setIsLoading(false);
        if (res?.type === "createRole/fulfilled") {
          toast.success(res?.payload?.message);
          dispatch(
            getRoleList({
              search: searchValue,
              pageCount: pageCount,
              pageLimit: pageLimit,
              sort_by: addSort,
              sort_direction: addSortDirection === "asc" ? "asc" : "desc",
            })
          );
          setAddEditRolePopup(false);
        }
      });
    }
  };

  // Parent With Sub List Checkbox
  const handleSubParentCheckboxChange = (parentName, subMenu) => {
    const isParentChecked = !formik.values[parentName];
    const updatedValues = { ...formik.values, [parentName]: isParentChecked };

    subMenu?.forEach((child) => {
      updatedValues[child.name] = true;
      child?.child_permission?.forEach((children) => {
        updatedValues[children.name] = true;
      });
    });
    formik.setValues(updatedValues);
  };

  // Parent Checkbox
  const handleParentCheckboxChange = (parentName, childPermissions) => {
    const isParentChecked = !formik.values[parentName];
    const updatedValues = { ...formik.values, [parentName]: isParentChecked };

    childPermissions?.forEach((child) => {
      updatedValues[child.name] = isParentChecked;
    });

    formik.setValues(updatedValues);
  };

  // Child Checkbox
  const handleChildCheckboxChange = (parentName, childPermissions) => {
    let allChecked = true;
    let anyChecked = false;

    childPermissions.forEach((child) => {
      if (!formik.values[child.name]) {
        allChecked = false;
      } else {
        anyChecked = true;
      }
    });

    formik.setFieldValue(parentName, allChecked || anyChecked);
  };

  useEffect(() => {
    permissionsList?.data?.forEach((element) => {
      if (element?.sub_menu?.length) {
        element?.sub_menu?.forEach((subElement) => {
          handleChildCheckboxChange(
            subElement.name,
            subElement.child_permission
          );
        });
      }
      handleChildCheckboxChange(element.name, element.child_permission);
    });
  }, [permissionsList]);

  return (
    <>
      <div
        className="model"
        onClick={() => {
          setAddEditRolePopup(false);
        }}
      >
        <div
          className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <p className="lg:text-xl sm:text-base text-sm font-ebGaramond font-semibold w-full border-b pb-3 mb-4">
            {viewPermissionData.id ? "Edit Role" : "Add Role"}
          </p>
          <Close
            className="cursor-pointer absolute top-5 right-5"
            onClick={() => {
              setAddEditRolePopup(false);
            }}
          />
          <div className="w-full">
            {permissionListLoading ? (
              <div className="h-96 flex justify-center items-center">
                <RippleLoader />
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2 gap-5 max-h-96 overflow-auto pr-3 mb-4 sm:pt-1 pt-2">
                  <InputWithLabel
                    className={
                      formik.errors.role && formik.touched.role
                        ? ` input-error `
                        : ` border `
                    }
                    idFromName="role"
                    label="Role"
                    type="role"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    errors={formik.errors.role && formik.touched.role}
                    errorsText={formik.errors.role}
                  />
                  {permissionsList?.data?.length > 0 ? (
                    <>
                      {permissionsList?.data?.map((element, index) => {
                        return (
                          <>
                            {!element?.sub_menu?.length ? (
                              <div
                                className={index === 0 && "col-start-1"}
                                key={index}
                              >
                                <DropdownCheckbox
                                  buttonClass={"flex items-center w-full"}
                                  form={element?.name}
                                  isOpen={element?.assignedAny}
                                  inputChecked={formik.values[element?.name]}
                                  indexOnChange={() =>
                                    handleParentCheckboxChange(
                                      element?.name,
                                      element?.child_permission
                                    )
                                  }
                                  inputNameId={element?.name}
                                  label={element?.title}
                                  children={
                                    <div className="px-5 space-y-3.5 mt-2">
                                      {element?.child_permission?.map(
                                        (permissionInput, index) => {
                                          return (
                                            <>
                                              <CheckBox
                                                idFrom={permissionInput?.name}
                                                label={permissionInput?.title}
                                                name={permissionInput?.name}
                                                value={
                                                  formik.values[
                                                    permissionInput?.name
                                                  ]
                                                }
                                                checked={
                                                  formik.values[
                                                    permissionInput?.name
                                                  ]
                                                }
                                                // onChange={formik.handleChange}
                                                onChange={(e) => {
                                                  formik.handleChange(e);
                                                  handleChildCheckboxChange(
                                                    element.name,
                                                    element.child_permission
                                                  );
                                                }}
                                                key={permissionInput?.id}
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                />
                              </div>
                            ) : (
                              <>
                                <DropdownCheckbox
                                  buttonClass={"flex items-center w-full"}
                                  form={element?.name}
                                  isOpen={element?.assignedAny}
                                  inputChecked={formik.values[element?.name]}
                                  indexOnChange={() =>
                                    handleSubParentCheckboxChange(
                                      element?.name,
                                      element?.sub_menu
                                    )
                                  }
                                  inputNameId={element?.name}
                                  label={element?.title}
                                  children={
                                    <div className="space-y-2 mt-2 px-2">
                                      {element?.sub_menu?.map((subElement) => {
                                        return (
                                          <div>
                                            <DropdownCheckbox
                                              buttonClass={"flex items-center"}
                                              form={subElement?.name}
                                              isOpen={subElement?.assignedAny}
                                              inputChecked={
                                                formik.values[subElement?.name]
                                              }
                                              indexOnChange={() =>
                                                handleParentCheckboxChange(
                                                  subElement?.name,
                                                  subElement?.child_permission
                                                )
                                              }
                                              inputNameId={subElement?.name}
                                              label={subElement?.title}
                                              children={
                                                <div className="px-5 space-y-3.5 mt-2">
                                                  {subElement?.child_permission?.map(
                                                    (
                                                      permissionInput,
                                                      index
                                                    ) => {
                                                      return (
                                                        <>
                                                          <CheckBox
                                                            key={
                                                              permissionInput?.id
                                                            }
                                                            idFrom={
                                                              permissionInput?.name
                                                            }
                                                            label={
                                                              permissionInput?.title
                                                            }
                                                            labelClass={
                                                              "whitespace-nowrap"
                                                            }
                                                            name={
                                                              permissionInput?.id
                                                            }
                                                            value={
                                                              formik.values[
                                                                permissionInput
                                                                  ?.name
                                                              ]
                                                            }
                                                            checked={
                                                              formik.values[
                                                                permissionInput
                                                                  ?.name
                                                              ]
                                                            }
                                                            onChange={(e) => {
                                                              formik.handleChange(
                                                                e
                                                              );
                                                              handleChildCheckboxChange(
                                                                subElement.name,
                                                                subElement.child_permission
                                                              );
                                                            }}
                                                          />
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              }
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  }
                                />
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="col-span-2">
                      <DataNotFound />
                    </div>
                  )}
                </div>
                <div className="flex justify-end gap-4 pt-3">
                  <button
                    type="submit"
                    className="btn-theme uppercase"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? "loading"
                      : viewPermissionData?.id
                      ? "Save"
                      : "Create"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      {/* Confirm Delete */}
      {confirmDelete && (
        <ConfirmationModel
          isLoading={false}
          confirmText={"Delete"}
          onClose={(e) => {
            setConfirmDelete(false);
          }}
          onConfirm={() => {
            setConfirmDelete(false);
          }}
        />
      )}

      {/* Confirm Edit? */}
      {confirmEdit && (
        <ConfirmationModel
          isLoading={false}
          confirmText={viewPermissionData?.id ? "Edit" : "Create"}
          onClose={(e) => {
            setConfirmEdit(false);
          }}
          onConfirm={() => {
            handleSubmit(values);
            setConfirmEdit(false);
          }}
        />
      )}
    </>
  );
};

export default AddEditRole;
