import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// Get File  List
export const getFileList = createAsyncThunk(
  "getFileList",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`folders?company_id=${id}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create File  List
export const createFile = createAsyncThunk(
  "createFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`folders`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFileDetail = createAsyncThunk(
  "getFileDetail",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`documents?folder_id=${id}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Document
export const createDocument = createAsyncThunk(
  "createDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`documents`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Document
export const updateDocument = createAsyncThunk(
  "updateDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`documents/${id}`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Doc
export const deleteDoc = createAsyncThunk(
  "deleteDoc",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`documents/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete flie
export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`folders/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const fileDataSlice = createSlice({
  name: "fileData",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get File List
      .addCase(getFileList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFileList.fulfilled, (state, action) => {
        state.loading = false;
        state.getFileListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getFileList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Upload File
      .addCase(createFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(createFile.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // get File detail
      .addCase(getFileDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFileDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.getFileDetailStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getFileDetail.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Upload Document
      .addCase(createDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.createDocumentStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createDocument.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Doc
      .addCase(deleteDoc.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteDocStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteDoc.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete File
      .addCase(deleteFile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteFileStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteFile.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { reducer } = fileDataSlice;
export default reducer;
