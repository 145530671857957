import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import CustomTable from "../../../commons/CustomTable";
import { useNavigate } from "react-router-dom";
import RippleLoader from "../../../commons/RippleLoader";
import { useDispatch, useSelector } from "react-redux";
import { getSalesByCompanyId } from "../../../redux/companies/slice";
import moment from "moment";
import ViewSales from "./components/ViewSales";

const SalesTransaction = ({ onLoading, companyByIdData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("sales");
  // const [selectedType, setSelectedType] = useState();
  const currentYear = new Date().getFullYear();
  const currentYearWithLabel = { label: currentYear, year: currentYear };
  const [selectedYear, setSelectedYear] = useState(currentYearWithLabel);
  // const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [viewSales, setViewSales] = useState(false);
  const [salesData, setSalesData] = useState({});
  const tabs = [{ label: "sales" }, { label: "Request Transaction" }];

  // Selector
  const { getSalesByCompanyIdData, loading } = useSelector((store) => ({
    getSalesByCompanyIdData:
      store?.companiesData?.getSalesByCompanyIdStatus?.data?.data,
    loading: store?.companiesData?.getSalesByCompanyIdStatus?.loading,
  }));

  const getLastTenYears = () => {
    const currentYear = new Date().getFullYear();
    const yearsWithLabels = [];

    for (let i = 0; i < 10; i++) {
      const year = currentYear - i;
      yearsWithLabels.push({ year, label: year });
    }

    return yearsWithLabels;
  };

  const yearDropdown = getLastTenYears();

  useEffect(() => {
    if (companyByIdData?.id) {
      dispatch(
        getSalesByCompanyId({
          id: companyByIdData?.id,
          // search: searchValue,
          pageCount: pageCount,
          pageLimit: pageLimit,
          sub_type: activeTab === "sales" ? "subscription" : "sales",
          year: selectedYear?.year,
        })
      );
    }
  }, [
    dispatch,
    pageCount,
    pageLimit,
    selectedYear,
    activeTab,
    companyByIdData,
  ]);

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          ID
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Points Type
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Date | Time
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Amount
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(getSalesByCompanyIdData?.data || [])?.map((element) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">{element?.order_id}</td>
            <td className="px-5 py-3 truncate">
              {activeTab === "sales" ? "Subscription" : ""}
            </td>
            <td className="px-5 py-3 truncate">
              {moment(element?.created_at).format("DD/MM/YYYY HH:MM")}
            </td>
            <td className="px-5 py-3 truncate">{element?.amount}</td>
            <td className="flex justify-start px-5 py-3 truncate">
              <button
                className="text-theme border-b border-theme"
                type="button"
                onClick={() => {
                  setSalesData(element);
                  setViewSales(true);
                }}
              >
                View
              </button>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      {onLoading ? (
        <div className="flex justify-center items-center h-full">
          <RippleLoader />
        </div>
      ) : (
        <div className="space-y-5">
          <div className="border p-3 rounded-lg">
            <div className="flex border border-theme rounded overflow-hidden">
              {tabs.map((tab, index) => (
                <button
                  key={tab.label}
                  className={`py-2 px-4 uppercase text-center text-theme font-semibold w-full transition duration-300 ${
                    activeTab === tab.label ? "bg-theme/30" : ""
                  } ${index === 0 && "border-r border-theme"}`}
                  onClick={() => setActiveTab(tab.label)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>
          <div className="flex justify-end">
            {/* <div className="flex items-center space-x-3">
              <DebounceSearch
                search={searchValue}
                handleChange={setSearchValue}
                placeholder={"Search..."}
              />
              <ReactSelect
                className={"w-full max-w-xs text-black p-0"}
                id="typesDropdown"
                placeholder="Select Type"
                options={typesDropdown}
                isLoading={false}
                getOptionValue={(selectedType) => selectedType.value}
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e);
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    border: `1px solid #cdddeb`,
                    borderRadius: "0.3rem",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    boxShadow: "none",
                    "&:hover": {
                      border: "1px solid #cdddeb",
                    },
                  }),
                }}
              />
            </div> */}
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="year"
              placeholder="Select Year"
              options={yearDropdown}
              isLoading={false}
              value={selectedYear}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.year}
              onChange={(e) => {
                setSelectedYear(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
          <div className="w-full bg-white rounded-xl border overflow-hidden">
            <CustomTable
              columnHeaders={<ColumnHeaders />}
              dataRows={<DataRows />}
              data={getSalesByCompanyIdData}
              overflowVisibleLg={true}
              // loader
              loading={loading}
              showPagination={true}
              // showLimit
              setPaginationCurrentLimit={setPageLimit}
              paginationCurrentLimit={pageLimit}
              // paginationData
              total={getSalesByCompanyIdData?.total}
              current={pageCount}
              paginationData={(crPage) => setPageCount(crPage)}
            />
          </div>
        </div>
      )}
      {viewSales && (
        <ViewSales
          salesData={salesData}
          companyName={companyByIdData?.name}
          setViewSales={setViewSales}
        />
      )}
    </>
  );
};

export default SalesTransaction;
