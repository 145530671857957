import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Staffs List
export const getStaffsList = createAsyncThunk(
  "getStaffsList",
  async (
    { search, status, pageLimit, pageCount, role, sort_by, sort_direction },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `staffs?search=${search}&role=${
          role ?? ""
        }&sort_direction=${sort_direction}&sort_by=${sort_by}&status=${status}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Staff
export const getStaff = createAsyncThunk(
  "getStaff",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`/staffs/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Edit Staffs
export const createStaffs = createAsyncThunk(
  "createStaffs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`staffs`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update Staff Status
export const updateStaffStatus = createAsyncThunk(
  "updateStaffStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`staffs/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const staffsSlice = createSlice({
  name: "staffs",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Staffs List
      .addCase(getStaffsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaffsList.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffsListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getStaffsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get Staff
      .addCase(getStaff.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStaff.fulfilled, (state, action) => {
        state.loading = false;
        state.getStaffStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getStaff.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Edit staffs
      .addCase(createStaffs.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStaffs.fulfilled, (state, action) => {
        state.loading = false;
        state.createStaffsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createStaffs.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // update Staff Status
      .addCase(updateStaffStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStaffStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateStaffStatusStatus.data = action.payload;
        state.error = false;
      })
      .addCase(updateStaffStatus.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = staffsSlice;
export default reducer;
