import React, { useContext, useEffect, useState } from "react";
import { InputWithLabel } from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import { Form, Formik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";
import { getCountryData } from "../../../redux/address/slice";
import { useDispatch, useSelector } from "react-redux";
import { createCustomer, getCustomer } from "../../../redux/customer/slice";
import RippleLoader from "../../../commons/RippleLoader";
import { useNavigate } from "react-router-dom";
import CustomerContext from "./CustomerContext";

const PersonalDetails = ({ getCustomerData, loading, setCreateCustomerId }) => {
  const [countryArray, setCountryArray] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { next } = useContext(CustomerContext);

  const countryData = useSelector(
    (store) => store?.addressData?.getCountryDataStatus?.data
  );
  const countryLoading = useSelector(
    (store) => store?.addressData?.getCountryDataStatus?.loading
  );

  useEffect(() => {
    if (!countryData) {
      dispatch(getCountryData());
    }
  }, [dispatch, countryData]);

  useEffect(() => {
    if (countryData?.data) {
      let data = countryData?.data?.map((x) => ({
        ...x,
        label: `+ ${x.phonecode}`,
      }));
      setCountryArray(data);
    }
  }, [countryData]);

  const individual_kyc_statusList = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];
  const citizenship = [
    { value: "permanent_resident", label: "Permanent Resident" },
    { value: "singaporean", label: "Singaporean" },
    { value: "foreigners", label: "Foreigners" },
  ];

  return (
    <div>
      {loading ? (
        <RippleLoader />
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={{
            personalId: getCustomerData?.user_id ?? "",
            fullName: getCustomerData?.full_name ?? "",
            email: getCustomerData?.email ?? "",
            phone: getCustomerData?.phone ?? "",
            individual_kyc_status: getCustomerData?.kyc_status
              ? individual_kyc_statusList.find(
                  (option) => option.value === getCustomerData.kyc_status
                )
              : null,
            citizenship: getCustomerData?.citizenship
              ? citizenship.find(
                  (option) => option.value === getCustomerData.citizenship
                )
              : null,
            country:
              countryArray?.find(
                (option) => option.id === getCustomerData?.country_id
              ) ?? null,
          }}
          validationSchema={Yup.object().shape({
            // personalId: Yup.string().required("Please Enter Personal Id"),
            fullName: Yup.string()
              .min(3, "Full Name must be at least 3 characters")
              .required("Please Enter Full Name"),
            email: Yup.string()
              .email("Invalid email format")
              .required("Please Enter E-mail"),
            country: Yup.object().nullable().required("Please Select Country"),
            phone: Yup.string()
              .required("Please Enter Mobile")
              .max(10, "Mobile is too long - should be 10 characters maximum."),
            individual_kyc_status: Yup.object()
              .nullable()
              .required("Please Select Individual KYC Status"),
            citizenship: Yup.object()
              .nullable()
              .required("Please Select citizenship Status"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const payload = {
              // id: values.personalId,
              email: values.email,
              full_name: values.fullName,
              phone: values.phone,
              country_id: values.country.id,
              kyc_status: values.individual_kyc_status.value,
              citizenship: values.citizenship.value,
            };
            if (getCustomerData?.id) {
              dispatch(
                createCustomer({ id: getCustomerData?.id, ...payload })
              ).then((res) => {
                if (res.type === "createCustomer/fulfilled") {
                  dispatch(getCustomer(getCustomerData?.id));
                  next();
                }
              });
            } else {
              dispatch(createCustomer(payload)).then((res) => {
                if (res.type === "createCustomer/fulfilled") {
                  setCreateCustomerId(res?.payload?.data?.id);
                  next();
                }
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 gap-4 mb-4">
                {/* personalId */}
                {getCustomerData?.id ? (
                  <InputWithLabel
                    className={
                      errors.personalId && touched.personalId
                        ? ` input-error `
                        : ` border border-dashed`
                    }
                    idFromName="personalId"
                    label="Personal ID (Auto-Generated)"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.personalId}
                    errors={errors.personalId && touched.personalId}
                    errorsText={errors.personalId}
                  />
                ) : null}
                {/* Name */}
                <InputWithLabel
                  className={
                    errors.fullName && touched.fullName
                      ? ` input-error `
                      : ` border `
                  }
                  idFromName="fullName"
                  label="Full Name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                  errors={errors.fullName && touched.fullName}
                  errorsText={errors.fullName}
                />
                <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-x-3 sm:gap-y-0 gap-y-4">
                  {/* Country */}
                  <div>
                    <div
                      className={`${
                        errors.country && touched.country ? ` input-error ` : ""
                      } relative bg-white transition duration-300 rounded plus-number-inputs`}
                    >
                      <ReactSelect
                        className={"text-black responsive_base_text p-0"}
                        id="country"
                        placeholder="Country"
                        options={countryArray}
                        isLoading={countryLoading}
                        getOptionValue={(countryArray) =>
                          countryArray.phonecode
                        }
                        value={
                          countryArray?.length > 0 &&
                          countryArray?.find((op) => {
                            return op.label === values?.country?.label;
                          })
                        }
                        onChange={(e) => {
                          setFieldValue("country", e);
                          setFieldValue("countryId", e?.id);
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            border: `1px solid ${
                              errors.country && touched.country
                                ? "red"
                                : "#0000003B"
                            }`,
                            borderRadius: "0.25rem",
                            padding: "6px 4px",
                            boxShadow: "none",
                            "&:hover": {
                              border: "1px solid #0000003B",
                            },
                          }),
                        }}
                      />
                      <label
                        htmlFor="country"
                        className="absolute text-light_grey pointer-events-none sm:text-base text-sm bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-0 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                      >
                        Country
                      </label>
                    </div>
                    {errors.country && touched.country ? (
                      <InputError errorTitle={errors.country} />
                    ) : null}
                  </div>
                  {/* Mobile */}
                  <div className="col-span-2">
                    <InputWithLabel
                      className={` ${
                        errors.phone && touched.phone ? "input-error" : "border"
                      }`}
                      idFromName="phone"
                      label="Mobile"
                      type={"number"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      errors={errors.phone && touched.phone}
                      errorsText={errors.phone}
                    />
                  </div>
                </div>
                {/* Email */}
                <InputWithLabel
                  className={
                    errors.email && touched.email ? ` input-error ` : ` border `
                  }
                  idFromName="email"
                  label="Email"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  errors={errors.email && touched.email}
                  errorsText={errors.email}
                />
                {/* citizenship */}
                <div>
                  <div
                    className={`${
                      errors.citizenship && touched.citizenship
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black responsive_base_text p-0"}
                      id="citizenship"
                      placeholder="Citizenship"
                      options={citizenship}
                      isLoading={false}
                      getOptionValue={(citizenship) => citizenship.value}
                      value={values.citizenship}
                      onChange={(e) => {
                        setFieldValue("citizenship", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.citizenship && touched.citizenship
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="citizenship"
                      className="absolute text-light_grey pointer-events-none z-0 sm:text-base text-sm bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Citizenship
                    </label>
                  </div>
                  {errors.citizenship && touched.citizenship ? (
                    <InputError errorTitle={errors.citizenship} />
                  ) : null}
                </div>
                {/* Individual KYC Status */}
                <div>
                  <div
                    className={`${
                      errors.individual_kyc_status &&
                      touched.individual_kyc_status
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded responsive_base_text plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black p-0"}
                      id="individual_kyc_status"
                      placeholder="Individual KYC Status"
                      options={individual_kyc_statusList}
                      isLoading={false}
                      getOptionValue={(individual_kyc_statusList) =>
                        individual_kyc_statusList.value
                      }
                      value={values.individual_kyc_status}
                      onChange={(e) => {
                        setFieldValue("individual_kyc_status", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.individual_kyc_status &&
                            touched.individual_kyc_status
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="individual_kyc_status"
                      className="absolute text-light_grey pointer-events-none sm:text-base text-sm bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-0 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Individual KYC Status
                    </label>
                  </div>
                  {errors.individual_kyc_status &&
                  touched.individual_kyc_status ? (
                    <InputError errorTitle={errors.individual_kyc_status} />
                  ) : null}
                </div>
              </div>
              <div className="flex justify-end gap-4 sm:pt-3">
                <button
                  type="button"
                  className="btn-outline uppercase"
                  onClick={() => navigate("/customers")}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme uppercase">
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PersonalDetails;
