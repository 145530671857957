import React, { useEffect, useState } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DebounceSearch from "../../commons/DebounceSearch";
import { ArrowDown, FileDownload } from "../../assets/svg/AllSvg";
import Breadcrumb from "../../commons/Breadcrumb";
import CustomTable from "../../commons/CustomTable";
import { Badge } from "../../components/micro";
import { useDispatch, useSelector } from "react-redux";
import { getActivityLogList } from "../../redux/activityLog/slice";
import moment from "moment";
import ViewActivityLog from "./ViewActivityLog";

const ActivityLog = () => {
  const [searchValue, setSearchValue] = useState("");
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [addSort, setAddSort] = useState("");
  const [showLog, setShowLog] = useState(false);
  const [logDetails, setLogDetails] = useState(undefined);
  const [addSortDirection, setAddSortDirection] = useState("desc");
  const dispatch = useDispatch();

  // Selector
  const activityLogData = useSelector(
    (store) => store?.activityLogData?.getActivityLogListStatus?.data?.data
  );
  const loading = useSelector((store) => store?.activityLogData?.loading);

  useEffect(() => {
    dispatch(
      getActivityLogList({
        search: searchValue,
        pageCount: pageCount,
        pageLimit: pageLimit,
        sort_by: addSort,
        sort_direction: addSortDirection,
      })
    );
  }, [dispatch, pageCount, pageLimit, addSort, addSortDirection, searchValue]);

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          ID.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> User </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "service_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("full_name");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> User Role </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "service_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("role");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Method </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "service_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("method");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          URI
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          IP
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Input
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          <div className="flex items-center">
            <p> Time Stamp </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "service_name"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("created_at");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
      </>
    );
  };

  // Table Row
  const DataRows = () => {
    return (
      <>
        {(activityLogData?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={index}
          >
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.id}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text capitalize">
              {element?.full_name ? element?.full_name?.replace("_", " ") : "-"}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text capitalize">
              {element?.role ? element?.role.replace("_", " ") : "-"}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`uppercase text-white ${
                      element?.method === "GET"
                        ? "border-theme bg-theme"
                        : "border-success bg-success"
                    }`}
                    value={element?.method}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.uri}
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {element?.ip}
            </td>
            <td className="px-5 py-3">
              <button
                type="button"
                className="truncate flex items-center table_responsive_text text-xs"
                onClick={() => {
                  setShowLog(true);
                  setLogDetails(element?.input);
                }}
              >
                <p className="max-w-[150px] truncate table_responsive_text">
                  {JSON.stringify(element?.input, null, 2)}
                </p>
                <p className="text-xs text-theme ml-1">Read More</p>
              </button>
            </td>
            <td className="px-5 py-3 truncate table_responsive_text">
              {moment(element?.created_at).format("DD/MM/YYYY | HH:MM A")}
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div className="space-y-5">
      <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
        Activities Log
      </h6>
      <Breadcrumb
        breadCrumbParent={"Home"}
        breadCrumbActive={"Activities Log"}
        breadCrumbTitleKey={"/"}
        classes=""
      />
      <div className="flex justify-between items-center space-x-3">
        <DebounceSearch
          search={searchValue}
          handleChange={setSearchValue}
          placeholder={"Search..."}
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn-outline flex items-center whitespace-nowrap flex-nowrap uppercase"
          table="table-to-xls"
          filename={`activity-log`}
          sheet={`activity-log`}
          buttonText={
            <>
              <FileDownload width="14" height="17" className="mr-3" />
              Export CSV
            </>
          }
        />
      </div>
      <div className="w-full bg-white border rounded">
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={activityLogData}
          overflowVisibleLg={false}
          // loader
          loading={loading}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={activityLogData?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
      {/* View Activity Log */}
      {showLog && (
        <ViewActivityLog
          details={logDetails}
          onclose={() => {
            setShowLog(false);
            setLogDetails(undefined);
          }}
        />
      )}
      {/* Download CSV */}
      <table id="table-to-xls" style={{ display: "none" }}>
        <thead>
          <tr>
            <th scope="col">ID.</th>
            <th scope="col">User</th>
            <th scope="col">User Role</th>
            <th scope="col">Method</th>
            <th scope="col">URI</th>
            <th scope="col">IP</th>
            <th scope="col">Input</th>
            <th scope="col">Time Stamp</th>
          </tr>
        </thead>
        <tbody>
          {activityLogData?.data?.map((element) => {
            return (
              <tr>
                <td>{element?.id ?? "-"}</td>
                <td>{element?.full_name ?? "-"}</td>
                <td>{element?.role ?? "-"}</td>
                <td>{element?.method ?? "-"}</td>
                <td>{element?.uri ?? "-"}</td>
                <td>{element?.ip}</td>
                <td>{JSON.stringify(element?.input, null, 2)}</td>
                <td>
                  {moment(element?.created_at).format("DD/MM/YYYY | HH:MM A")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityLog;
