// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getNotificationsListStatus: {
    data: null,
  },
  deleteNotificationsStatus: {
    data: null,
  },
  readNotificationsStatus: {
    data: null,
  },
};
