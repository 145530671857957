import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import { InputError } from "../../../commons/MicroComponents";
import { Badge } from "../../../components/micro";
import { useDispatch } from "react-redux";
import {
  employmentSupport,
  getCompanyById,
} from "../../../redux/companies/slice";
import { toast } from "react-toastify";

const CompanyKYCStatus = ({
  setCompanyKYCStatusPopup,
  companyId,
  companyByIdData,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  const kycValue = {
    value: companyByIdData.kyc_status,
    label:
      companyByIdData?.kyc_status === "low_risk"
        ? "Low Risk"
        : companyByIdData?.kyc_status === "mid_risk"
        ? "Mid Risk"
        : companyByIdData?.kyc_status === "high_risk"
        ? "High Risk"
        : companyByIdData?.kyc_status === "pending"
        ? "Pending"
        : "",
  };
  const stateValue = {
    value: companyByIdData.status,
    label:
      companyByIdData?.status === "active" ? "Live" : companyByIdData?.status,
  };

  return (
    <div
      className="model"
      onClick={() => {
        setCompanyKYCStatusPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Company KYC Status
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            company_kyc: kycValue ?? "",
            company_status: stateValue ?? "",
          }}
          validationSchema={Yup.object().shape({
            company_kyc: Yup.object().required("Please Select Company KYC"),
            company_status: Yup.object().required(
              "Please Select Company Status"
            ),
          })}
          onSubmit={(values) => {
            setIsLoading(true);
            const payload = {
              status: values?.company_status?.value,
              is_active: values?.company_status?.value === "active" ? 1 : 0,
              kyc_status: values?.company_kyc?.value,
            };
            dispatch(employmentSupport({ id: companyId, data: payload })).then(
              (res) => {
                setIsLoading(true);
                if (res.type === "employmentSupport/fulfilled") {
                  dispatch(getCompanyById(companyId));
                  setCompanyKYCStatusPopup(false);
                }
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">
                  {companyByIdData?.name}
                </p>
                <Badge
                  className={`${
                    companyByIdData?.status === "active"
                      ? "text-white border-success bg-success"
                      : "text-black bg-secondary_light_shades"
                  } font-medium text-sm capitalize !py-1`}
                  value={
                    companyByIdData?.status === "active"
                      ? "Live"
                      : companyByIdData?.status
                  }
                />
              </div>
              <div className="w-full space-y-5 pb-3">
                {/* Company Status */}
                <div>
                  <div
                    className={`${
                      errors.company_status && touched.company_status
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded`}
                  >
                    <ReactSelect
                      className={"text-black capitalize p-0"}
                      id="company_status"
                      placeholder="Select Company Status"
                      options={[
                        { value: "active", label: "Live" },
                        { value: "pending", label: "Pending" },
                      ]}
                      isLoading={false}
                      getOptionValue={(kycDropdown) => kycDropdown.value}
                      value={values.company_status}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("company_status", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.company_status && touched.company_status
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="company_status"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Company Status
                    </label>
                  </div>
                  {errors.company_status && touched.company_status ? (
                    <InputError errorTitle={errors.company_status} />
                  ) : null}
                </div>
                {/* Company KYC */}
                <div>
                  <div
                    className={`${
                      errors.company_kyc && touched.company_kyc
                        ? ` input-error `
                        : ""
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactSelect
                      className={"text-black capitalize p-0"}
                      id="company_kyc"
                      placeholder="Select Company KYC"
                      options={kycDropdown}
                      isLoading={false}
                      getOptionValue={(kycDropdown) => kycDropdown.value}
                      value={values.company_kyc}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("company_kyc", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.company_kyc && touched.company_kyc
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    <label
                      htmlFor="company_kyc"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Company KYC
                    </label>
                  </div>
                  {errors.company_kyc && touched.company_kyc ? (
                    <InputError errorTitle={errors.company_kyc} />
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4 w-full pt-3">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setCompanyKYCStatusPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${
                    isLoading ? "btn-outline-disabled" : "btn-theme"
                  } w-full uppercase`}
                >
                  {isLoading ? "Loading" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CompanyKYCStatus;
