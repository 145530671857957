import React from "react";
import { User } from "../assets/svg/AllSvg";
import Action from "./Action";

const SendMessage = ({ dateTime, text, type, imgUrl }) => {
  return (
    <>
      <div className="flex items-start text-end justify-end ml-auto gap-2 max-w-430">
        <div>
          {type === "text" ? (
            <p className="py-2 px-4 rounded-xl text-sm text-white bg-theme">
              {text}
            </p>
          ) : type === "img" ? (
            <div className="relative">
              <img
                src={imgUrl}
                alt="img"
                className="w-full h-full max-h-60 object-cover rounded-lg"
              />
              <div className="absolute top-3 right-3 bg-white rounded-lg">
                <Action
                  optionList={
                    <>
                      <li className="flex text-sm gap-2 items-center px-4 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Delete
                      </li>
                      <li className="flex text-sm gap-2 items-center px-4 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Download
                      </li>
                    </>
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <p className="text-xs text-light_grey mt-1">{dateTime}</p>
        </div>
        <div className="relative max-w-8 w-full h-8 bg-theme rounded-full flex justify-center items-center">
          <User
            width={14}
            height={14}
            pathClass={"fill-white"}
            fillOpacity={1}
          />
        </div>
      </div>
    </>
  );
};

export default SendMessage;
