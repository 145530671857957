import React from "react";
import Breadcrumb from "../../../commons/Breadcrumb";
import BackButton from "../../../commons/BackButton";
import { Badge } from "../../../components/micro";
import Action from "../../../commons/Action";
import ReceiveMessage from "../../../commons/ReceiveMessage";
import SendMessage from "../../../commons/SendMessage";
import TextEditor from "../../../commons/TextEditor";
const ServiceRequestDetails = () => {
  const chat = [
    {
      isMass: "receiver",
      msgText: "Company Pte Ltd had requested for “Service Request 1”",
      msgType: "text",
      msgDateTIme: "dd/mm/YYYY HH:MM",
    },
    {
      isMass: "sender",
      msgText:
        "We are currently looking through Allow us to prepare the document required for signing",
      msgType: "text",
      msgDateTIme: "dd/mm/YYYY HH:MM",
    },
    {
      isMass: "receiver",
      msgText: "",
      msgType: "img",
      imgUrl:
        "https://img.freepik.com/free-photo/beautiful-glowing-gray-full-moon_181624-59870.jpg?t=st=1729835473~exp=1729839073~hmac=bbd639ec78f3234f41e1178ed76ad201cf73c88feb89986aa2af0e67544d7743&w=740",
      msgDateTIme: "dd/mm/YYYY HH:MM",
    },
    {
      isMass: "receiver",
      msgText: "",
      msgType: "doc",
      docName: "Document Name",
      imgUrl:
        "https://img.freepik.com/free-photo/beautiful-glowing-gray-full-moon_181624-59870.jpg?t=st=1729835473~exp=1729839073~hmac=bbd639ec78f3234f41e1178ed76ad201cf73c88feb89986aa2af0e67544d7743&w=740",
      msgDateTIme: "dd/mm/YYYY HH:MM",
    },
  ];
  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <div>
          <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
            Service Request
          </h6>
          <Breadcrumb
            breadCrumbParent={"Home"}
            breadCrumbActive={"Service Request"}
            breadCrumbTitleKey={"/"}
            classes=""
          />
        </div>
        <BackButton
          text={"Back"}
          iconClass={"fill-theme"}
          className={"btn-outline"}
          fillOpacity={1}
        />
      </div>
      <div className="card w-full !rounded-2xl space-y-4 !py-5 !px-6">
        {/* Company And Service Request Details */}
        <div className="flex justify-between mb-2">
          <div className="flex items-center gap-5">
            <p className="font-ebGaramond text-2xl">Service Request Name</p>
            <Badge
              className={"border-theme text-light_green text-sm !py-1"}
              value={"Pending from Client"}
            />
          </div>
          <Action
            optionList={
              <>
                <li className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                  Cancelled
                </li>
              </>
            }
          />
        </div>
        <div className="flex gap-5">
          <div className="space-y-1">
            <p>Service Request ID: 0001</p>
            <p>To Be Completed By: 24/05/2024</p>
          </div>
          <div className="space-y-1">
            <p>Company Name: Company Pte Ltd</p>
            <p className="text-sm text-light_grey">Customer Name: Name 1</p>
          </div>
        </div>
        {/* Chat Start */}
        <div className="card bg-white !rounded-xl space-y-1">
          {chat?.map((message, index) => {
            return (
              <>
                {message?.isMass === "receiver" ? (
                  <ReceiveMessage
                    key={index}
                    text={message?.msgText}
                    dateTime={message?.msgDateTIme}
                    type={message?.msgType}
                    imgUrl={message?.imgUrl}
                  />
                ) : (
                  <SendMessage
                    key={index}
                    text={message?.msgText}
                    dateTime={message?.msgDateTIme}
                    type={message?.msgType}
                    imgUrl={message?.imgUrl}
                  />
                )}
              </>
            );
          })}
          <TextEditor />
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestDetails;
