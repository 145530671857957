import React, { useContext, useEffect, useState } from "react";
import FileUploadChoose from "../../../commons/FileUploadChoose";
import { getDocuments, uploadDocuments } from "../../../redux/customer/slice";
import { useDispatch, useSelector } from "react-redux";
import RippleLoader from "../../../commons/RippleLoader";
import moment from "moment";
import PdfDocumentWithConfirm from "../../../commons/PdfDocumentWithConfirm";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { useNavigate } from "react-router-dom";
import CustomerContext from "./CustomerContext";

const Documents = ({ getCustomerData, loading, createCustomerId }) => {
  const [docLoading, setDocLoading] = useState(false);
  // const [addPersonalDetails, setAddPersonalDetails] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB

  const getDocumentsData = useSelector(
    (store) => store?.customerData?.getDocumentsStatus?.data?.data
  );
  const isLoading = useSelector((store) => store?.customerData?.loading);
  const { prev } = useContext(CustomerContext);

  useEffect(() => {
    if (getCustomerData?.id || createCustomerId) {
      dispatch(
        getDocuments(
          getCustomerData?.id ? getCustomerData?.id : createCustomerId
        )
      );
    }
  }, [dispatch, getCustomerData, createCustomerId]);

  // Upload File
  const handleFileChange = (e) => {
    // if (!getCustomerData?.id || !createCustomerId) {
    //   setAddPersonalDetails(true);
    //   return;
    // }
    const fileData = e.target.files[0];

    if (!fileData) return;

    const formData = new FormData();
    formData.append(
      "customer_id",
      getCustomerData?.id ? getCustomerData?.id : createCustomerId
    );

    const chunks = [];
    const totalChunks = Math.ceil(fileData.size / CHUNK_SIZE);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileData.size);
      const fileChunk = fileData.slice(start, end);
      chunks.push(fileChunk);
    }
    chunks.forEach((chunk) => {
      formData.append("documents[]", chunk, fileData.name);
    });
    setDocLoading(true);
    dispatch(uploadDocuments(formData)).then((res) => {
      setDocLoading(false);
      if (res.type === "uploadDocuments/fulfilled") {
        dispatch(
          getDocuments(
            getCustomerData?.id ? getCustomerData?.id : createCustomerId
          )
        );
      }
    });
  };

  // get files
  const fetchDocuments = () => {
    if (getCustomerData?.id || createCustomerId) {
      dispatch(
        getDocuments(
          getCustomerData?.id ? getCustomerData?.id : createCustomerId
        )
      );
    }
  };

  return (
    <>
      {loading || isLoading || docLoading ? (
        <RippleLoader />
      ) : (
        <>
          {/* {addPersonalDetails && (
            <InputError errorTitle={"Please Add Personal Details"} />
          )} */}
          <div className="py-5">
            <FileUploadChoose handleFileChange={handleFileChange} />
          </div>
          <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 sm:gap-5 gap-3">
            {getDocumentsData?.data?.map((element, index) => {
              return (
                <PdfDocumentWithConfirm
                  documentName={element?.document?.name}
                  documentId={element?.id}
                  customerDocId={
                    getCustomerData?.id ? getCustomerData?.id : createCustomerId
                  }
                  url={element?.document?.url}
                  companyByIdData={element?.user?.full_name}
                  uploadTime={moment(element?.updated_at).format(
                    "DD/MM/YYYY HH:MM"
                  )}
                  fetchDocuments={fetchDocuments}
                  key={index}
                />
              );
            })}
          </div>
        </>
      )}
      <div className="flex justify-end gap-4 pt-3">
        <button
          type="button"
          className="btn-outline uppercase"
          onClick={() => prev()}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn-theme uppercase"
          onClick={() => setConfirm(true)}
        >
          Next
        </button>
      </div>

      {confirm && (
        <ConfirmationModel
          confirmText={"Edit Company"}
          isLoading={false}
          onClose={() => {
            setConfirm(false);
          }}
          onConfirm={() => {
            setConfirm(false);
            navigate("/customers");
          }}
        />
      )}
    </>
  );
};

export default Documents;
