// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getFileListStatus: { data: null },
  uploadStatus: {data: null},
  getFileDetailStatus: {data: null},
  createDocumentStatus: {data: null},
  deleteDocStatus: {data: null},
  deleteFileStatus: {data: null}
};