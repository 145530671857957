// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  getCustomerListStatus: { data: null },
  createCustomerStatus: { data: null },
  updateCustomerStatusStatus: { data: null },
  getCustomerStatus: { data: null },
  assignCompanyStatus: { data: null },
  getAllCompanyStatus: { data: null, loading: false },
  deleteUnassignCompanyStatus: { data: null, loading: false },
  uploadDocumentsStatus: { data: null, loading: false },
  getDocumentsStatus: { data: null },
  deleteDocumentsStatus: { data: null, loading: false },
};
