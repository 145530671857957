import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Get Role List
export const getRoleList = createAsyncThunk(
  "getRoleList",
  async (
    { search, pageLimit, pageCount, sort_by, sort_direction },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `roles?search=${search}&sort_direction=${sort_direction}&sort_by=${sort_by}&page=${pageCount}&limit=${pageLimit}`
      );
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get All Role List
export const getAllRoleList = createAsyncThunk(
  "getAllRoleList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get("get-roles");
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Permissions List
export const getPermissionsList = createAsyncThunk(
  "getPermissionsList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`permissions${id ? `/${id}` : ""}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Edit Role
export const createRole = createAsyncThunk(
  "createRole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`roles`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Role
export const deleteRole = createAsyncThunk(
  "deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`roles/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Role List
      .addCase(getRoleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.getRoleListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getRoleList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // get Permissions List
      .addCase(getPermissionsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPermissionsList.fulfilled, (state, action) => {
        state.loading = false;
        state.getPermissionsListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getPermissionsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Role
      .addCase(createRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.loading = false;
        state.createRoleStatus.data = action.payload;
        state.error = false;
      })
      .addCase(createRole.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Role
      .addCase(deleteRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteRoleStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteRole.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Get All Role List
      .addCase(getAllRoleList.pending, (state) => {
        state.getAllRoleListStatus.loading = true;
      })
      .addCase(getAllRoleList.fulfilled, (state, action) => {
        state.getAllRoleListStatus.loading = false;
        state.getAllRoleListStatus.data = action.payload;
        state.getAllRoleListStatus.error = false;
      })
      .addCase(getAllRoleList.rejected, (state) => {
        state.getAllRoleListStatus.loading = false;
        state.getAllRoleListStatus.error = true;
      });
  },
});
const { reducer } = accountsSlice;
export default reducer;
