import { Form, Formik } from "formik";
import React, { useState } from "react";
import { DefaultPhoneInput, Input } from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import * as Yup from "yup";
import ReactSelect from "react-select";
import Toggles from "../../../commons/Toggles";

const EditOrganisationPopup = ({
  organisationData,
  setShowEditOrganisationPopup,
  companyId,
}) => {
  const [identityVerification, setIdentityVerification] = useState(false);
  // Role List
  const roleList = [
    { value: "role", label: "Role" },
    { value: "role_one", label: "Role One" },
    { value: "role_two", label: "Role Two" },
  ];
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  const Role = {
    label: organisationData?.role,
    value: organisationData?.role,
  };
  const Kyc = {
    label: organisationData?.Kyc,
    value: organisationData?.Kyc,
  };
  return (
    <div
      className="model"
      onClick={(e) => {
        setShowEditOrganisationPopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Director
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: organisationData.name ?? "",
            mobile: organisationData.mobile ?? "",
            role: Role ?? "",
            email: organisationData?.email ?? "",
            kyc: Kyc ?? "",
            identity_verification: identityVerification,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().min(3).required("Please Enter Full Name"),
            mobile: Yup.string()
              .required("please Enter Mobile")
              .max(13, "Mobile is too long - should be 10 chars maximum."),
            email: Yup.string().min(3).required("Please Enter E-mail"),
            role: Yup.object().required("Please Select System Role"),
            kyc: Yup.object().required("Please Select KYC"),
          })}
          onSubmit={(values) => {
            console.log(values, "values");
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="w-full space-y-5">
              {/* Name */}
              <div>
                <div
                  className={`${
                    errors.name && touched.name ? ` input-error ` : ` border `
                  } relative bg-white border-secondary_grey transition duration-300 rounded`}
                >
                  <Input
                    type="test"
                    id="name"
                    name="name"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder={" "}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <label
                    htmlFor="name"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Name
                  </label>
                </div>
                {errors.name && touched.name ? (
                  <InputError errorTitle={errors.name} />
                ) : null}
              </div>
              {/* Role */}
              <div>
                <div
                  className={`${
                    errors.role && touched.role ? ` input-error ` : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="role"
                    placeholder="Select Role"
                    options={roleList}
                    isLoading={false}
                    getOptionValue={(roleList) => roleList.value}
                    value={values.role}
                    onChange={(e) => {
                      setFieldValue("role", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.role && touched.role ? "red" : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="role"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Role
                  </label>
                </div>
                {errors.role && touched.role ? (
                  <InputError errorTitle={errors.role} />
                ) : null}
              </div>
              {/* Email */}
              <div>
                <div
                  className={`${
                    errors.email && touched.email ? ` input-error ` : ` border `
                  } relative bg-white border-secondary_grey transition duration-300 rounded`}
                >
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder={" "}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <label
                    htmlFor="email"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Email
                  </label>
                </div>
                {errors.email && touched.email ? (
                  <InputError errorTitle={errors.email} />
                ) : null}
              </div>
              {/* Mobile */}
              <div>
                <div
                  className={`${
                    errors.mobile && touched.mobile
                      ? ` input-error `
                      : ` border `
                  } relative bg-white border-secondary_grey transition duration-300 rounded plus-number-inputs`}
                >
                  <DefaultPhoneInput
                    className={`${
                      errors.mobile && touched.mobile ? "error-forms" : ""
                    } rounded p-3`}
                    value={values.mobile}
                    onBlur={handleBlur("mobile")}
                    name={"mobile"}
                    max={10}
                    onChange={(value) => {
                      value !== undefined
                        ? setFieldValue("mobile", value)
                        : setFieldValue("mobile", "");
                    }}
                  />
                  <label
                    htmlFor="mobile"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Mobile
                  </label>
                </div>
                {errors.mobile && touched.mobile ? (
                  <InputError errorTitle={errors.mobile} />
                ) : null}
              </div>
              {/* Identity Verification Status */}
              <div className="flex items-center justify-between">
                <label
                  form="identityVerification"
                  className="text-light_grey font-semibold"
                >
                  Identity Verification Status
                </label>
                <Toggles
                  checked={identityVerification}
                  toggleFor={"identityVerification"}
                  onChange={() => {
                    setIdentityVerification(!identityVerification);
                  }}
                />
              </div>
              {/* KYC Status */}
              <div>
                <div
                  className={`${
                    errors.kyc && touched.kyc ? ` input-error ` : ""
                  } relative bg-white transition duration-300 rounded plus-number-inputs`}
                >
                  <ReactSelect
                    className={"text-black p-0"}
                    id="kyc"
                    placeholder="Select Company Role"
                    options={kycDropdown}
                    isLoading={false}
                    getOptionValue={(kycDropdown) => kycDropdown.value}
                    value={values.kyc}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("kyc", e);
                    }}
                    styles={{
                      control: (base) => ({
                        ...base,
                        border: `1px solid ${
                          errors.kyc && touched.kyc ? "red" : "#0000003B"
                        }`,
                        borderRadius: "0.25rem",
                        padding: "6px 4px",
                        boxShadow: "none",
                        "&:hover": {
                          border: "1px solid #0000003B",
                        },
                      }),
                    }}
                  />
                  <label
                    htmlFor="kyc"
                    className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    KYC Status
                  </label>
                </div>
                {errors.kyc && touched.kyc ? (
                  <InputError errorTitle={errors.kyc} />
                ) : null}
              </div>
              <div className="flex gap-4 w-full pt-3">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setShowEditOrganisationPopup(false);
                  }}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-theme w-full uppercase">
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditOrganisationPopup;
