import React, { useState } from "react";
import ConfirmationModel from "../../../commons/ConfirmationModel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateMasterSetting } from "../../../redux/masterSetting/slice";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputWithLabel, TextareaWithLabel } from "../../../components/micro";

const PassAndOfficers = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: value,
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/service-settings");
      }
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ei_biz_charges: masterSettingData?.detail?.ei_biz_charges ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          ei_biz_charges: Yup.string().required(
            "Please Enter EIBIZ Charges Price"
          ),
          remarks: Yup.string().required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-4 gap-5 responsive_base_text items-center">
              {/* 3 Months */}
              <label
                className={`sm:col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="ei_biz_charges"
              >
                EiBIZ Charges
              </label>
              <div className="sm:col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"ei_biz_charges"}
                  className={
                    errors.ei_biz_charges && touched.ei_biz_charges
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ei_biz_charges}
                  errors={errors.ei_biz_charges && touched.ei_biz_charges}
                  errorsText={errors.ei_biz_charges}
                />
              </div>

              {/* Remarks */}
              <div className="sm:col-span-4">
                <TextareaWithLabel
                  className={
                    errors.remarks && touched.remarks
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  idFromName="remarks"
                  label="Remarks"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.remarks}
                  errors={errors.remarks && touched.remarks}
                  errorsText={errors.remarks}
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn uppercase"
                onClick={() => {
                  navigate("/service-settings");
                }}
              >
                Cancel
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default PassAndOfficers;
