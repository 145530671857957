import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  getMasterSettingById,
  updateMasterSetting,
} from "../../../../redux/masterSetting/slice";
import {
  InputWithLabel,
  TextareaWithLabel,
} from "../../../../components/micro";
import ConfirmationModel from "../../../../commons/ConfirmationModel";

const BusinessRegisteredAddress = ({ masterSettingData }) => {
  const [confirmSave, setConfirmSave] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handelSubmit = (value) => {
    const payload = {
      id: masterSettingData?.id,
      details: {
        address_fee: value.address_fee,
        tiered_discount: {
          years_3: value.years_3,
          years_5: value.years_5,
        },
      },
      remarks: value?.remarks,
    };
    dispatch(updateMasterSetting(payload)).then((res) => {
      if (res.type === "updateMasterSetting/fulfilled") {
        navigate("/general-settings");
      }
    });
  };
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          address_fee: masterSettingData?.detail?.address_fee ?? "",
          years_3: masterSettingData?.detail?.tiered_discount?.years_3 ?? "",
          years_5: masterSettingData?.detail?.tiered_discount?.years_5 ?? "",
          remarks: masterSettingData?.remarks ?? "",
        }}
        validationSchema={Yup.object().shape({
          address_fee: Yup.string().required(
            "Please Enter Address Fee Pricing"
          ),
          years_3: Yup.number()
            .required("Please Enter 3 years Discount (%)")
            .max(100, "Discount must be less than or equal to 100%")
            .min(0, "Discount must be greater than or equal to 100%"),
          years_5: Yup.number()
            .required("Please Enter 5 years Discount (%)")
            .max(100, "Discount must be less than or equal to 100%")
            .min(0, "Discount must be greater than or equal to 100%"),
          remarks: Yup.string().required("Please Enter Remarks"),
        })}
        onSubmit={(values) => {
          setValues(values);
          setConfirmSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="space-y-5">
            {/* Yearly Cost */}
            <p
              className={`font-ebGaramond text-2xl mb-5 ${
                !isEdit && "text-light_grey"
              }`}
            >
              Yearly Cost
            </p>
            {/* Address Fee */}
            <div className="grid grid-cols-4 items-center gap-5 border-b border-secondary_grey pb-5">
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="address_fee"
              >
                Address Fee
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Pricing"}
                  type={"number"}
                  idFromName={"address_fee"}
                  className={
                    errors.address_fee && touched.address_fee
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address_fee}
                  errors={errors.address_fee && touched.address_fee}
                  errorsText={errors.address_fee}
                />
              </div>
            </div>
            {/* Tiered discount */}
            <p className="font-ebGaramond text-2xl mb-5">Tiered discount</p>
            <div className="grid grid-cols-4 items-center gap-5 border-b border-secondary_grey pb-5">
              {/* 3 Years */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="years_3"
              >
                3 Years
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Discount (%)"}
                  type={"number"}
                  idFromName={"years_3"}
                  className={
                    errors.years_3 && touched.years_3
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.years_3}
                  errors={errors.years_3 && touched.years_3}
                  errorsText={errors.years_3}
                />
              </div>
              {/* 5 Years */}
              <label
                className={`col-start-1 ${!isEdit && "text-light_grey"}`}
                htmlFor="years_5"
              >
                5 Years
              </label>
              <div className="col-span-3">
                <InputWithLabel
                  label={"Discount (%)"}
                  type={"number"}
                  idFromName={"years_5"}
                  className={
                    errors.years_5 && touched.years_5
                      ? ` input-error `
                      : ` border `
                  }
                  disabled={!isEdit}
                  inputClass={!isEdit ? "text-gray-400" : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.years_5}
                  errors={errors.years_5 && touched.years_5}
                  errorsText={errors.years_5}
                />
              </div>
            </div>
            {/* Remarks */}
            <TextareaWithLabel
              className={
                errors.remarks && touched.remarks ? ` input-error ` : ` border `
              }
              idFromName="remarks"
              label="Remarks"
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEdit}
              inputClass={!isEdit ? "text-gray-400" : ""}
              value={values.remarks}
              errors={errors.remarks && touched.remarks}
              errorsText={errors.remarks}
            />
            <div className="flex justify-end gap-4 pt-3">
              <button
                type="button"
                className="btn-outline uppercase"
                onClick={() => {
                  navigate("/general-settings");
                }}
              >
                Back
              </button>
              {isEdit ? (
                <button type="submit" className="btn-theme uppercase">
                  Save
                </button>
              ) : (
                <div
                  className="btn-theme uppercase cursor-pointer"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {/* Confirmation Save */}
      {confirmSave && (
        <ConfirmationModel
          confirmText={"Save"}
          isLoading={false}
          onClose={() => {
            setConfirmSave(false);
          }}
          onConfirm={() => {
            handelSubmit(values);
            setConfirmSave(false);
          }}
        />
      )}
    </div>
  );
};

export default BusinessRegisteredAddress;
