import React, { useState } from "react";
import { ArrowDown, FileDownload } from "../../../assets/svg/AllSvg";
import { Badge, SelectBox } from "../../../components/micro";
import CustomTable from "../../../commons/CustomTable";

const SalesBreakdownList = () => {
  // Table Pagination
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  // Table Sorting
  const [addSort, setAddSort] = useState("");
  const [addSortDirection, setAddSortDirection] = useState("desc");
  // Table Filter
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();

  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "tagged", label: "Tagged" },
    { value: "untagged", label: "Untagged" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  const salesBreakdownList = {
    data: [
      {
        company: "Coy 1",
        sales_id: "R-S0001",
        sales_type: "Subscription 1 Yr",
        price: "$5000",
        status: "paid",
        date: "02/03/2024",
        head: "Cell",
      },
    ],
    total: 5,
    current_page: 1,
    per_page: 5,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b table_responsive_text font-semibold text-left bg-primary_shades_light whitespace-nowrap "
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p>Company</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "company"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("company");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Sales ID </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "sales_id"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("sales_id");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p> Sales Type </p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "sales_type"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("sales_type");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p>Price</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "price"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("price");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          <div className="flex items-center">
            <p>Status</p>
            <button
              className={`cursor-pointer p-3 ${
                addSortDirection === "asc" && addSort === "status"
                  ? "rotate-180"
                  : ""
              }`}
              onClick={() => {
                setAddSort("status");
                setAddSortDirection(
                  addSortDirection === "desc" ? "asc" : "desc"
                );
              }}
            >
              <ArrowDown />
            </button>
          </div>
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light"
        >
          Date
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b table_responsive_text font-semibold text-left bg-primary_shades_light "
        >
          Head
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(salesBreakdownList?.data || [])?.map((element, index) => (
          <tr
            className={
              "border-b table_responsive_text hover:bg-secondary_light_shades"
            }
            key={element?.id}
          >
            <td className="px-5 py-3 truncate">
              {(salesBreakdownList?.data?.current_page - 1) *
                salesBreakdownList?.data?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3 truncate capitalize">
              {element?.company ?? "-"}
            </td>
            <td className="px-5 py-3 truncate">{element?.sales_id ?? "-"}</td>
            <td className="px-5 py-3 truncate">{element?.sales_type ?? "-"}</td>
            <td className="px-5 py-3 truncate">{element?.price ?? "-"}</td>
            <td className="px-5 py-3 truncate">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize ${
                      element?.status === "paid"
                        ? "border-green-700 bg-green-700"
                        : "border-red-700 bg-red-700"
                    }`}
                    value={element?.status === "paid" ? "Paid" : "Refunded"}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3 truncate">{element?.date ?? "-"}</td>
            <td className="px-5 py-3 truncate">{element?.head ?? "-"}</td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="card space-y-3">
        {/* Table Heading and Export CSV */}
        <div className="flex items-center justify-between">
          <p className="font-ebGaramond text-lg">Sales Breakdown</p>
          <button className="btn-outline flex items-center uppercase">
            <FileDownload width="14" height="17" className="mr-3" />
            Export CSV
          </button>
        </div>
        {/* Table Dropdown and Filter */}
        <div className="flex justify-between">
          <div className="flex items-center space-x-3">
            <SelectBox
              className={"whitespace-nowrap"}
              id="tagStatusDropdown"
              placeholder="Select Tag Status"
              paddingY={"2px"}
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
            />
            <SelectBox
              className={"whitespace-nowrap"}
              id="kyc"
              placeholder="Select KYC"
              options={kycDropdown}
              paddingY={"2px"}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
            />
          </div>
          <SelectBox
            className={"whitespace-nowrap"}
            id="kyc"
            placeholder="Select KYC"
            options={kycDropdown}
            paddingY={"2px"}
            isLoading={false}
            getOptionValue={(selectedKyc) => selectedKyc.value}
            value={selectedKyc}
            onChange={(e) => {
              setSelectedKyc(e);
            }}
          />
        </div>
        {/* Table */}
        <CustomTable
          columnHeaders={<ColumnHeaders />}
          dataRows={<DataRows />}
          data={salesBreakdownList}
          overflowVisibleLg={true}
          // loader
          loading={false}
          showPagination={true}
          // showLimit
          setPaginationCurrentLimit={setPageLimit}
          paginationCurrentLimit={pageLimit}
          // paginationData
          total={salesBreakdownList?.total}
          current={pageCount}
          paginationData={(crPage) => setPageCount(crPage)}
        />
      </div>
    </>
  );
};

export default SalesBreakdownList;
