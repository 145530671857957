import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// get Notifications List
export const getNotificationsList = createAsyncThunk(
  "getNotificationsList",
  async (
    { pageLimit, pageCount, sort_by, sort_direction },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `notifications?sort_by=${sort_by}&sort_direction=${sort_direction}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Notifications
export const deleteNotifications = createAsyncThunk(
  "deleteNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`delete-notifications`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Read Notifications
export const readNotifications = createAsyncThunk(
  "readNotifications",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`read-notifications`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // get Tickets List
      .addCase(getNotificationsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotificationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.getNotificationsListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(getNotificationsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Ticket
      .addCase(deleteNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteNotificationsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(deleteNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Read Ticket
      .addCase(readNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(readNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.readNotificationsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(readNotifications.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

const { reducer } = notificationsSlice;
export default reducer;
