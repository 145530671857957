import React from "react";
import { Logo } from "../../../../assets/image";
import moment from "moment";

const PdfComponent = ({ getSalesByCompanyIdData, companyName }) => {
  return (
    <div>
      <div
        id="pdfContent"
        style={{
          padding: "20px",
          background: "#fff",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            src={Logo}
            alt="Company Logo"
            style={{ width: "80px", height: "80px", objectFit: "cover" }}
          />
          <div style={{ maxWidth: "30rem", textAlign: "right" }}>
            <h2 style={{ margin: 0, marginBottom: "10px" }}>
              EiBiz Management Pte Ltd
            </h2>
            <p style={{ margin: 0 }}>One-Stop Corporate Solutions Provider</p>
            <p>Co. Reg. No: 00000OK</p>
            <p>HP: 0000000 Email: admin@ei-biz.com.sg</p>
            <p>wedsite: www.ei-biz.com.sg</p>
            <p>
              Address: 140 Paya Lebar Rode #06-05 AZ@Paya Lebar Singapore 409015
            </p>
          </div>
        </div>

        <h1
          style={{
            fontSize: "2.25rem",
            lineHeight: "2.5rem",
            marginBottom: "20px",
          }}
        >
          Receipt
        </h1>

        <div>
          <p>Receipt ID: {getSalesByCompanyIdData?.reference_number}</p>
          <p>Customer Name: {getSalesByCompanyIdData?.user?.full_name}</p>
          <p>Company Name: {companyName}</p>
          <p>
            {moment(getSalesByCompanyIdData?.created_at).format("DD/MM/YYYY")}
          </p>
        </div>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  fontWeight: 600,
                  padding: "8px",
                  backgroundColor: "0656990A",
                  border: "1px solid #ddd",
                }}
              >
                No.
              </th>
              <th
                style={{
                  fontWeight: 600,
                  padding: "8px",
                  backgroundColor: "0656990A",
                  border: "1px solid #ddd",
                }}
              >
                Items
              </th>
              <th
                style={{
                  fontWeight: 600,
                  padding: "8px",
                  backgroundColor: "0656990A",
                  border: "1px solid #ddd",
                }}
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                  // padding: "8px",
                  textAlign: "center",
                }}
              >
                01
              </td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                {moment(getSalesByCompanyIdData?.item?.start_date).format(
                  "YYYY"
                )}{" "}
                Yearly Subscription{" "}
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                  // padding: "8px",
                  textAlign: "right",
                }}
              >
                ${getSalesByCompanyIdData?.detail?.sub_total}
              </td>
            </tr>
          </tbody>
        </table>

        <div style={{ marginTop: "20px", textAlign: "right" }}>
          <p>
            <p>Subtotal: ${getSalesByCompanyIdData?.detail?.sub_total}</p>
          </p>
          <p>
            GST {getSalesByCompanyIdData?.detail?.gst}%: $
            {getSalesByCompanyIdData?.detail?.gst_amount}
          </p>
          <p>Total: ${getSalesByCompanyIdData?.detail?.total}</p>
        </div>
      </div>
    </div>
  );
};

export default PdfComponent;
