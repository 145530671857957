import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputError } from "../../../commons/MicroComponents";
import ReactDatePicker from "react-datepicker";
import { Badge } from "../../../components/micro";
import {
  employmentSupport,
  getCompanyById,
} from "../../../redux/companies/slice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const EditDatesToNote = ({
  setDatesToNotePopup,
  companyId,
  companyByIdData,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className="model"
      onClick={() => {
        setDatesToNotePopup(false);
      }}
    >
      <div
        className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Dates to note
        </h2>
        <Formik
          enableReinitialize={true}
          initialValues={{
            financial_year_end_date:
              companyByIdData?.financial_year_end_date || "",
            annual_general_meeting:
              companyByIdData?.annual_general_meeting_date || "",
            annual_return: companyByIdData?.annual_return_date || "",
            tax_return: companyByIdData?.tax_return_date || "",
          }}
          validationSchema={Yup.object().shape({
            financial_year_end_date: Yup.string()
              .nullable()
              .required("Please Select Financial Year End"),
            annual_general_meeting: Yup.string()
              .nullable()
              .required("Please Select Annual General Meeting"),
            annual_return: Yup.string()
              .nullable()
              .required("Please Select Annual Return"),
            tax_return: Yup.string()
              .nullable()
              .required("Please Select Tax Return"),
          })}
          onSubmit={(values) => {
            setIsLoading(true);
            const payload = {
              financial_year_end_date: values?.financial_year_end_date,
              annual_general_meeting_date: values?.annual_general_meeting,
              annual_return_date: values?.annual_return,
              tax_return_date: values?.tax_return,
            };
            dispatch(employmentSupport({ id: companyId, data: payload })).then(
              (res) => {
                setIsLoading(true);
                if (res.type === "employmentSupport/fulfilled") {
                  toast.success(res.payload.message);
                  dispatch(getCompanyById(companyId));
                  setDatesToNotePopup(false);
                }
              }
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form className="w-full" onSubmit={handleSubmit}>
              <div className="flex items-center space-x-3 mb-5">
                <p className="text-xl font-ebGaramond">
                  {companyByIdData?.name}
                </p>
                <Badge
                  className={`${
                    companyByIdData?.status === "active"
                      ? "text-white border-success bg-success"
                      : "text-black bg-secondary_light_shades"
                  } font-medium text-sm capitalize !py-1`}
                  value={
                    companyByIdData?.status === "active"
                      ? "Live"
                      : companyByIdData?.status
                  }
                />
              </div>
              {/* Financial Year End Date */}
              <div className="space-y-5">
                <div>
                  <div
                    className={`${
                      errors.financial_year_end_date &&
                      touched.financial_year_end_date
                        ? ` input-error `
                        : ` `
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactDatePicker
                      selected={values.financial_year_end_date}
                      onChange={(date) =>
                        setFieldValue("financial_year_end_date", new Date(date))
                      }
                      selectsStart
                      maxDate={new Date()}
                      id="financial_year_end_date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={"Select Financial Year End"}
                      className={`border border-secondary_grey ${
                        errors.financial_year_end_date &&
                        touched.financial_year_end_date
                          ? "error-forms"
                          : ""
                      } rounded w-full px-3 py-2.5`}
                    />
                    <label
                      htmlFor="financial_year_end_date"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Financial Year End
                    </label>
                  </div>
                  {errors.financial_year_end_date &&
                  touched.financial_year_end_date ? (
                    <InputError errorTitle={errors.financial_year_end_date} />
                  ) : null}
                </div>
                {/* Annual General Meeting */}
                <div>
                  <div
                    className={`${
                      errors.annual_general_meeting &&
                      touched.annual_general_meeting
                        ? ` input-error `
                        : ` `
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactDatePicker
                      selected={values.annual_general_meeting}
                      onChange={(date) =>
                        setFieldValue("annual_general_meeting", new Date(date))
                      }
                      selectsStart
                      maxDate={new Date()}
                      id="annual_general_meeting"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={"Select Annual General Meeting"}
                      className={`border border-secondary_grey ${
                        errors.annual_general_meeting &&
                        touched.annual_general_meeting
                          ? "error-forms"
                          : ""
                      } rounded w-full px-3 py-2.5`}
                    />
                    <label
                      htmlFor="annual_general_meeting"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Annual General Meeting
                    </label>
                  </div>
                  {errors.annual_general_meeting &&
                  touched.annual_general_meeting ? (
                    <InputError errorTitle={errors.annual_general_meeting} />
                  ) : null}
                </div>
                {/* Annual Return */}
                <div>
                  <div
                    className={`${
                      errors.annual_return && touched.annual_return
                        ? ` input-error `
                        : ` `
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactDatePicker
                      selected={values.annual_return}
                      onChange={(date) =>
                        setFieldValue("annual_return", new Date(date))
                      }
                      selectsStart
                      maxDate={new Date()}
                      id="annual_return"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={"Select Annual Return"}
                      className={`border border-secondary_grey ${
                        errors.annual_return && touched.annual_return
                          ? "error-forms"
                          : ""
                      } rounded w-full px-3 py-2.5`}
                    />
                    <label
                      htmlFor="annual_return"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Annual Return
                    </label>
                  </div>
                  {errors.annual_return && touched.annual_return ? (
                    <InputError errorTitle={errors.annual_return} />
                  ) : null}
                </div>
                {/* Tax Return */}
                <div>
                  <div
                    className={`${
                      errors.tax_return && touched.tax_return
                        ? ` input-error `
                        : ` `
                    } relative bg-white transition duration-300 rounded plus-number-inputs`}
                  >
                    <ReactDatePicker
                      selected={values.tax_return}
                      onChange={(date) =>
                        setFieldValue("tax_return", new Date(date))
                      }
                      selectsStart
                      maxDate={new Date()}
                      id="tax_return"
                      dateFormat="dd/MM/yyyy"
                      placeholderText={"Select Tax Return"}
                      className={`border border-secondary_grey ${
                        errors.tax_return && touched.tax_return
                          ? "error-forms"
                          : ""
                      } rounded w-full px-3 py-2.5`}
                    />
                    <label
                      htmlFor="tax_return"
                      className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Tax Return
                    </label>
                  </div>
                  {errors.tax_return && touched.tax_return ? (
                    <InputError errorTitle={errors.tax_return} />
                  ) : null}
                </div>
              </div>
              <div className="flex gap-4 w-full pt-5">
                <button
                  type="button"
                  className="btn-outline w-full uppercase"
                  onClick={() => {
                    setDatesToNotePopup(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${
                    isLoading ? "btn-outline-disabled" : "btn-theme"
                  } w-full uppercase`}
                >
                  {isLoading ? "Loading" : "Save"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditDatesToNote;
