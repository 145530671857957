import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Broadcast List
export const BroadcastsList = createAsyncThunk(
  "BroadcastsList",
  async (
    { search, status, pageLimit, pageCount, sort_by, sort_direction, type },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `broadcasts?search=${search}&sort_by=${sort_by}&sort_direction=${sort_direction}&status=${status}&page=${pageCount}&limit=${pageLimit}&subscription_type=${type}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Broadcasts
export const CreateBroadcasts = createAsyncThunk(
  "CreateBroadcasts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`broadcasts`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

//delete
export const DeleteBroadcast = createAsyncThunk(
  "DeleteBroadcast",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`/broadcasts/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const broadcastsSlice = createSlice({
  name: "broadcasts",
  initialState: initialStates,
  extraReducers: (builder) => {
    builder
      // broadcasts list
      .addCase(BroadcastsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(BroadcastsList.fulfilled, (state, action) => {
        state.loading = false;
        state.broadcastsListStatus.data = action.payload;
        state.error = false;
      })
      .addCase(BroadcastsList.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Create Broadcast
      .addCase(CreateBroadcasts.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateBroadcasts.fulfilled, (state, action) => {
        state.loading = false;
        state.createBroadcastsStatus.data = action.payload;
        state.error = false;
      })
      .addCase(CreateBroadcasts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      // Delete Broadcast
      .addCase(DeleteBroadcast.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteBroadcast.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteBroadcastStatus.data = action.payload;
        state.error = false;
      })
      .addCase(DeleteBroadcast.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
const { reducer } = broadcastsSlice;
export default reducer;
