import React, { useState } from "react";
import { PDFDoc } from "../assets/svg/AllSvg";
import Action from "./Action";
import EditDocument from "../pages/companies/viewCompany/editDocuments";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ConfirmationModel from "./ConfirmationModel";
import { DeleteDocuments, getDocuments } from "../redux/customer/slice";

const PdfDocumentWithConfirm = ({
  documentName,
  uploadTime,
  cardClasses,
  companyByIdData,
  documentId,
  customerDocId,
  fetchDocuments,
  url,
}) => {
  const dispatch = useDispatch();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    dispatch(DeleteDocuments(documentId)).then((res) => {
      setIsLoading(false);
      if (res.type === "DeleteDocuments/fulfilled") {
        dispatch(getDocuments(customerDocId));
        setConfirmModalOpen(false);
      }
    });
  };

  const handleEditSuccess = async () => {
    setIsLoading(true);
    try {
      await fetchDocuments();
      toast.success("Document edited successfully!");
    } catch (error) {
      toast.error("Failed to refresh documents.");
    } finally {
      setIsLoading(false);
      setEditModalOpen(false);
    }
  };

  return (
    <div
      className={`card bg-light_secondary space-y-2 relative ${cardClasses}`}
    >
      <div className={"flex justify-between items-center"}>
        <div className="flex items-center w-full">
          <PDFDoc className="sm:w-7 sm:h-9 w-5 h-7 mr-3" />
          <p className="text-black font-normal max-w-full sm:text-sm text-xs truncate">
            {documentName?.name || documentName}
          </p>
        </div>
        <button className="absolute top-0 -right-2 p-2">
          <Action
            optionList={
              <>
                <a href={url} target="_block">
                  <li className="flex gap-2 items-center responsive_base_text lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                    View
                  </li>
                </a>
                <li
                  className="flex gap-2 items-center responsive_base_text lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={() => setEditModalOpen(true)}
                >
                  Rename File
                </li>
                <a href={url} target="_block">
                  <li className="flex gap-2 items-center responsive_base_text lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                    Download
                  </li>
                </a>
                <li
                  className="flex gap-2 items-center responsive_base_text lg:px-6 sm:px-5 px-4 lg:py-2 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={() => setConfirmModalOpen(true)}
                >
                  Delete
                </li>
              </>
            }
          />
        </button>
      </div>
      {uploadTime && (
        <p className="text-center text-light_grey font-semibold text-xs leading-normal">
          Uploaded on <span className="sm:block">“{uploadTime}”</span>
        </p>
      )}
      <EditDocument
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        documentName={documentName?.name}
        documentId={documentId}
        companyByIdData={companyByIdData}
        onSuccess={handleEditSuccess}
      />
      {isConfirmModalOpen && (
        <ConfirmationModel
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirmDelete}
          confirmText="Delete Document"
          isLoading={isLoading}
        />
      )}
      {isLoading && <div className="loader">Loading...</div>}
    </div>
  );
};

export default PdfDocumentWithConfirm;
