// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  createChatStatus: {
    data: null,
  },
  updateChatStatus: {
    data: null,
  },
  deleteChatStatus: {
    data: null,
  },
  getChatUserStatus: {
    data: null,
  },
};
