import React from "react";
import { PDFDoc, User } from "../assets/svg/AllSvg";
import Action from "./Action";

const ReceiveMessage = ({ dateTime, text, type, imgUrl }) => {
  return (
    <>
      <div className="flex items-start gap-2 max-w-430">
        <div className="relative max-w-8 w-full h-8 bg-grey rounded-full flex justify-center items-center">
          <User
            width={14}
            height={14}
            pathClass={"fill-white"}
            fillOpacity={1}
          />
        </div>
        <div>
          {type === "text" ? (
            <p className="py-2 px-4 rounded-xl text-sm bg-light_secondary_shades">
              {text}
            </p>
          ) : type === "img" ? (
            <div className="relative">
              <img
                src={imgUrl}
                alt="img"
                className="w-full h-full max-h-60 object-cover rounded-lg"
              />
              <div className="absolute top-3 right-3 bg-white rounded-lg">
                <Action
                  optionList={
                    <>
                      <li className="flex text-sm gap-2 items-center px-4 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Delete
                      </li>
                      <li className="flex text-sm gap-2 items-center px-4 py-1 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Download
                      </li>
                    </>
                  }
                />
              </div>
            </div>
          ) : type === "doc" ? (
            <div className="card bg-light_primary_shades space-y-2 min-w-72">
              <div className="space-y-1">
                <p className="text-sm">Document Name</p>
                <p className="text-xs">Prepared By: Name 1 [EiBIZ]</p>
              </div>

              <div className="card bg-white">
                <div className="flex items-center w-full">
                  <PDFDoc className="mr-3" />
                  <p className="text-black font-normal max-w-full text-sm truncate">
                    {"document"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <p className="text-xs text-light_grey mt-1">{dateTime}</p>
        </div>
      </div>
    </>
  );
};

export default ReceiveMessage;
